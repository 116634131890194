var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from './BaseModel';
// export const MessageVariables = [
//   '{{고객명}}',
//   '{{상점명}}',
//   '{{예약일시}}',
//   '{{디자이너명}}',
//   '{{인스타url}}',
//   '{{계좌번호}}',
//   '{{잔여 선불충전액}}',
//   '{{잔여포인트}}'
// ];
export var MESSAGE_VARIABLES;
(function (MESSAGE_VARIABLES) {
    // customer(required)
    MESSAGE_VARIABLES["CUSTOMER_NAME"] = "#{\uACE0\uAC1D\uBA85}";
    MESSAGE_VARIABLES["REMAIN_PREPAID"] = "#{\uC794\uC5EC\uC120\uBD88\uCDA9\uC804\uC561}";
    MESSAGE_VARIABLES["REMAIN_CUSTOMER_POINIT"] = "#{\uC794\uC5EC\uD3EC\uC778\uD2B8}";
    // store(required)
    MESSAGE_VARIABLES["STORE_NAME"] = "#{\uC0C1\uC810\uBA85}";
    MESSAGE_VARIABLES["INSTA_URL"] = "#{\uC778\uC2A4\uD0C0url}";
    MESSAGE_VARIABLES["ACCOUNT_NUMBER"] = "#{\uACC4\uC88C\uBC88\uD638}";
    // manager(optional)
    MESSAGE_VARIABLES["MANAGER_NAME"] = "#{\uB2F4\uB2F9\uC790\uBA85}";
    // schedule(optional)
    MESSAGE_VARIABLES["RESERVATION_DATE"] = "#{\uC608\uC57D\uC77C\uC2DC}";
    MESSAGE_VARIABLES["RESERVATION_FEE_TIME_LIMIT"] = "#{\uC608\uC57D\uAE08\uC785\uAE08\uAE30\uD55C}";
    MESSAGE_VARIABLES["RESERVATION_FEE"] = "#{\uC608\uC57D\uAE08}";
    MESSAGE_VARIABLES["RESERVATION_TIME_SPEND"] = "#{\uC18C\uC694\uC2DC\uAC04}";
    MESSAGE_VARIABLES["RESERVATION_PRODUCTS"] = "#{\uC0C1\uD488}";
    // sales
    MESSAGE_VARIABLES["SALES_ITEMS"] = "#{\uD310\uB9E4\uB0B4\uC5ED}";
    MESSAGE_VARIABLES["SALES_METHODS"] = "#{\uACB0\uC81C\uB0B4\uC5ED}";
    MESSAGE_VARIABLES["SALES_TICKETS"] = "#{\uC815\uAE30\uAD8C\uC774\uC6A9\uC815\uBCF4}";
    MESSAGE_VARIABLES["SALES_PREPAID"] = "#{\uC120\uBD88\uC561\uC774\uC6A9\uC815\uBCF4}";
    MESSAGE_VARIABLES["SALES_PREPAID_DETAIL"] = "#{\uCC28\uAC10\uC120\uBD88\uC561\uC0C1\uC138\uC815\uBCF4}";
    MESSAGE_VARIABLES["SALES_USED_PREPAID"] = "#{\uC99D\uAC10\uC120\uBD88\uC561}";
    MESSAGE_VARIABLES["SALES_USED_TICKET_NAME"] = "#{\uC99D\uAC10\uC815\uAE30\uAD8C\uC774\uB984}";
    MESSAGE_VARIABLES["SALES_USED_TICKET"] = "#{\uC99D\uAC10\uC815\uAE30\uAD8C}";
    MESSAGE_VARIABLES["SALES_REMAIN_TICKET"] = "#{\uC794\uC5EC\uC815\uAE30\uAD8C}";
    MESSAGE_VARIABLES["SALES_POINT"] = "#{\uD3EC\uC778\uD2B8\uC774\uC6A9\uC815\uBCF4}";
    MESSAGE_VARIABLES["SALES_USING_PRODUCTS"] = "#{\uC2DC\uC220\uC0C1\uD488}";
    MESSAGE_VARIABLES["WILL_EXPIRED_CUSTOMER_POINT"] = "#{\uB9CC\uB8CC\uC608\uC815\uD3EC\uC778\uD2B8\uC815\uBCF4}";
    MESSAGE_VARIABLES["WILL_EXPIRED_OTHER_TICKET"] = "#{\uB9CC\uB8CC\uC608\uC815\uC815\uAE30\uAD8C\uC815\uBCF4}";
    MESSAGE_VARIABLES["WILL_EXPIRED_PREPAID_TICKET"] = "#{\uB9CC\uB8CC\uC608\uC815\uC120\uBD88\uC561\uC815\uBCF4}";
    MESSAGE_VARIABLES["TICKET_SHARED_BY"] = "#{\uACF5\uC720\uD55C\uACE0\uAC1D\uBA85}";
    MESSAGE_VARIABLES["TICKET_RECEIVED_BY"] = "#{\uACF5\uC720\uBC1B\uC740\uACE0\uAC1D\uBA85}";
    MESSAGE_VARIABLES["TICKET_SHARED_DESCRIPTION"] = "#{\uACF5\uC720\uC0AC\uC6A9\uC815\uBCF4}";
})(MESSAGE_VARIABLES || (MESSAGE_VARIABLES = {}));
export var DEFAULT_VARIABLES = [
    // customer
    MESSAGE_VARIABLES.CUSTOMER_NAME,
    MESSAGE_VARIABLES.REMAIN_PREPAID,
    MESSAGE_VARIABLES.REMAIN_CUSTOMER_POINIT,
    // store
    MESSAGE_VARIABLES.STORE_NAME,
    MESSAGE_VARIABLES.INSTA_URL,
    MESSAGE_VARIABLES.ACCOUNT_NUMBER
];
var MessageTemplateModel = /** @class */ (function (_super) {
    __extends(MessageTemplateModel, _super);
    function MessageTemplateModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(MessageTemplateModel.prototype, "isATSTemplate", {
        get: function () {
            var _a, _b, _c;
            return ((_a = this.content) === null || _a === void 0 ? void 0 : _a.templateCode) && (((_b = this.content) === null || _b === void 0 ? void 0 : _b.plusFriendID) || ((_c = this.content) === null || _c === void 0 ? void 0 : _c.profileKey));
        },
        enumerable: false,
        configurable: true
    });
    MessageTemplateModel.MESSAGE_VARIABLES = MESSAGE_VARIABLES;
    return MessageTemplateModel;
}(BaseModel));
export { MessageTemplateModel };
