var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from './BaseModel';
import { StoreModel } from './StoreModel';
import { SalesHistoryModel } from './SalesHistoryModel';
import { CustomerModel } from './CustomerModel';
var STATUS;
(function (STATUS) {
    // 리뷰 요청됨
    STATUS["REQUESTED"] = "REQUESTED";
    // 심사 대기중
    STATUS["PENDING"] = "PENDING";
    // 승인완료
    STATUS["APPROVED"] = "APPROVED";
    // 반려
    STATUS["REJECTED"] = "REJECTED";
    // 승인후 과금완료
    STATUS["PAID"] = "PAID";
    // 과금실패
    STATUS["PAID_FAIL"] = "PAID_FAIL";
})(STATUS || (STATUS = {}));
var StoreReviewModel = /** @class */ (function (_super) {
    __extends(StoreReviewModel, _super);
    function StoreReviewModel(data) {
        var _this = _super.call(this, data) || this;
        _this.store = data.store ? new StoreModel(data.store) : undefined;
        _this.customer = data.customer ? new CustomerModel(data.customer) : undefined;
        _this.salesHistory = data.salesHistory ? new SalesHistoryModel(data.salesHistory) : undefined;
        return _this;
    }
    Object.defineProperty(StoreReviewModel.prototype, "isUploadable", {
        get: function () {
            return [STATUS.REQUESTED, STATUS.REJECTED].includes(this.status);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreReviewModel.prototype, "statusString", {
        get: function () {
            switch (this.status) {
                case STATUS.REQUESTED:
                    return '요청';
                case STATUS.PENDING:
                    return '검수중';
                case STATUS.APPROVED:
                    return '승인';
                case STATUS.REJECTED:
                    return '반려';
                case STATUS.PAID:
                    return '완료';
                case STATUS.PAID_FAIL:
                    return '과금실패';
            }
        },
        enumerable: false,
        configurable: true
    });
    StoreReviewModel.REVIEW_STATUS = STATUS;
    return StoreReviewModel;
}(BaseModel));
export { StoreReviewModel };
