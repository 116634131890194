var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { StoreModel } from './StoreModel';
import { BaseModel } from './BaseModel';
import { AutoMessageSettingModel } from './AutoMessageSettingModel';
var AUTO_MESSAGE_KEYS;
(function (AUTO_MESSAGE_KEYS) {
    AUTO_MESSAGE_KEYS["PENDING_RESERVATION_NOTIFICATION"] = "pending_reservation_notification";
    AUTO_MESSAGE_KEYS["RESERVATION_NOTIFICATION"] = "reservation_notification";
    AUTO_MESSAGE_KEYS["RESERVATION_CONFIRM"] = "reservation_confirm";
    AUTO_MESSAGE_KEYS["RESERVATION_CANCEL"] = "reservation_cancel";
    AUTO_MESSAGE_KEYS["RESERVATION_NOSHOW"] = "reservation_noshow";
    AUTO_MESSAGE_KEYS["SALES_COMPLETE_AFTER"] = "sales_complete_after";
    AUTO_MESSAGE_KEYS["SALES_COMPLETE"] = "sales_complete";
    AUTO_MESSAGE_KEYS["SALES_CANCEL"] = "sales_cancel";
    AUTO_MESSAGE_KEYS["SALES_PREPAID_BUY"] = "sales_prepaid_buy";
    AUTO_MESSAGE_KEYS["SALES_PREPAID_USED"] = "sales_prepaid_used";
    AUTO_MESSAGE_KEYS["SALES_PREPAID_BUY_CANCEL"] = "sales_prepaid_buy_cancel";
    AUTO_MESSAGE_KEYS["SALES_PREPAID_USED_CANCEL"] = "sales_prepaid_used_cancel";
    AUTO_MESSAGE_KEYS["SALES_TICKET_BUY"] = "sales_ticket_buy";
    AUTO_MESSAGE_KEYS["SALES_TICKET_USED"] = "sales_ticket_used";
    AUTO_MESSAGE_KEYS["SALES_TICKET_BUY_CANCEL"] = "sales_ticket_buy_cancel";
    AUTO_MESSAGE_KEYS["SALES_TICKET_USED_CANCEL"] = "sales_ticket_used_cancel";
    AUTO_MESSAGE_KEYS["SALES_CUSTOMER_POINT_BUY"] = "sales_customer_point_buy";
    AUTO_MESSAGE_KEYS["SALES_CUSTOMER_POINT_USED"] = "sales_customer_point_used";
    AUTO_MESSAGE_KEYS["SALES_CUSTOMER_POINT_CANCEL"] = "sales_customer_point_cancel";
    AUTO_MESSAGE_KEYS["SALES_CUSTOMER_POINT_USED_CANCEL"] = "sales_customer_point_used_cancel";
    AUTO_MESSAGE_KEYS["TICKET_PREPAID_MODIFIED"] = "ticket_prepaid_modified";
    AUTO_MESSAGE_KEYS["TICKET_OTHERS_MODIFIED"] = "ticket_others_modified";
    AUTO_MESSAGE_KEYS["CUSTOMER_POINT_MODIFIED"] = "customer_point_modified";
    AUTO_MESSAGE_KEYS["CUSTOMER_BIRTH"] = "customer_birth";
    AUTO_MESSAGE_KEYS["CUSTOMER_INACTIVE"] = "customer_inactive";
    AUTO_MESSAGE_KEYS["TICKET_OTHERS_EXPIRED"] = "ticket_others_expired";
    AUTO_MESSAGE_KEYS["TICKET_PREPAID_EXPIRED"] = "ticket_prepaid_expired";
    AUTO_MESSAGE_KEYS["CUSTOMER_POINT_EXPIRED"] = "customer_point_expired";
})(AUTO_MESSAGE_KEYS || (AUTO_MESSAGE_KEYS = {}));
var AlimtalkTemplateModel = /** @class */ (function (_super) {
    __extends(AlimtalkTemplateModel, _super);
    function AlimtalkTemplateModel(props) {
        var _this = _super.call(this, props) || this;
        _this.store = props.store && new StoreModel(props.store);
        return _this;
    }
    AlimtalkTemplateModel.ALIMTALK_TEMPLATE_TYPE = [
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.RESERVATION_NOTIFICATION,
            keyIndex: 1,
            keyName: '예약 알림',
            category: '003004',
            categoryName: '예약 > 예약완료/예약내역'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.RESERVATION_CONFIRM,
            keyIndex: 2,
            keyName: '예약 확정',
            category: '003004',
            categoryName: '예약 > 예약완료/예약내역'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.RESERVATION_CANCEL,
            keyIndex: 3,
            keyName: '예약 취소',
            category: '003003',
            categoryName: '예약 > 예약취소'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.RESERVATION_NOSHOW,
            keyIndex: 4,
            keyName: '노쇼 알림',
            category: '003003',
            categoryName: '예약 > 예약취소'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_COMPLETE,
            keyIndex: 5,
            keyName: '결제',
            category: '002001',
            categoryName: '구매 > 구매완료'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_CANCEL,
            keyIndex: 6,
            keyName: '결제 취소',
            category: '002004',
            categoryName: '구매 > 구매취소'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_PREPAID_BUY,
            keyIndex: 7,
            keyName: '선불액 판매',
            category: '002001',
            categoryName: '구매 > 구매완료'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_PREPAID_USED,
            keyIndex: 8,
            keyName: '선불액 차감결제',
            category: '002001',
            categoryName: '구매 > 구매완료'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_PREPAID_BUY_CANCEL,
            keyIndex: 9,
            keyName: '선불액 판매취소',
            category: '002004',
            categoryName: '구매 > 구매취소'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_PREPAID_USED_CANCEL,
            keyIndex: 10,
            keyName: '선불액 차감결제 취소',
            category: '002004',
            categoryName: '구매 > 구매취소'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_TICKET_BUY,
            keyIndex: 11,
            keyName: '정기권 판매',
            category: '002001',
            categoryName: '구매 > 구매완료'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_TICKET_USED,
            keyIndex: 12,
            keyName: '정기권 차감결제',
            category: '002001',
            categoryName: '구매 > 구매완료'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_TICKET_BUY_CANCEL,
            keyIndex: 13,
            keyName: '정기권 판매취소',
            category: '002004',
            categoryName: '구매 > 구매취소'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_TICKET_USED_CANCEL,
            keyIndex: 14,
            keyName: '정기권 차감결제 취소',
            category: '002004',
            categoryName: '구매 > 구매취소'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_CUSTOMER_POINT_BUY,
            keyIndex: 15,
            keyName: '포인트 매출 적립',
            category: '009003',
            categoryName: '쿠폰/포인트 > 포인트적립'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_CUSTOMER_POINT_USED,
            keyIndex: 16,
            keyName: '포인트 차감결제',
            category: '009004',
            categoryName: '쿠폰/포인트 > 포인트사용'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_CUSTOMER_POINT_CANCEL,
            keyIndex: 17,
            keyName: '포인트 매출적립취소',
            category: '009005',
            categoryName: '쿠폰/포인트 > 쿠폰/포인트 안내'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_CUSTOMER_POINT_USED_CANCEL,
            keyIndex: 18,
            keyName: '포인트 차감결제 취소',
            category: '009005',
            categoryName: '쿠폰/포인트 > 쿠폰/포인트 안내'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.TICKET_PREPAID_MODIFIED,
            keyIndex: 19,
            keyName: '선불액 임의조정',
            category: '002003',
            categoryName: '구매 > 진행상태'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.TICKET_OTHERS_MODIFIED,
            keyIndex: 20,
            keyName: '정기권 임의조정',
            category: '002003',
            categoryName: '구매 > 진행상태'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.CUSTOMER_POINT_MODIFIED,
            keyIndex: 21,
            keyName: '포인트 임의조정',
            category: '002003',
            categoryName: '구매 > 진행상태'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.TICKET_OTHERS_EXPIRED,
            keyIndex: 22,
            keyName: '정기권 기간만료',
            category: '009005',
            categoryName: '기타 > 기타'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.TICKET_PREPAID_EXPIRED,
            keyIndex: 23,
            keyName: '선불액 기간만료',
            category: '009005',
            categoryName: '기타 > 기타'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_COMPLETE_AFTER,
            keyIndex: 24,
            keyName: '시술 후 안내',
            category: '003004',
            categoryName: '예약 > 예약완료/예약내역'
        },
        {
            autoMessageKey: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.PENDING_RESERVATION_NOTIFICATION,
            keyIndex: 25,
            keyName: '예약 대기',
            category: '003004',
            categoryName: '예약 > 예약완료/예약내역'
        }
    ];
    return AlimtalkTemplateModel;
}(BaseModel));
export { AlimtalkTemplateModel };
export var TEMPLATE_KEY_CODE = [
    { code: 'AT1', key: AUTO_MESSAGE_KEYS.RESERVATION_NOTIFICATION },
    { code: 'AT2', key: AUTO_MESSAGE_KEYS.RESERVATION_CONFIRM },
    { code: 'AT3', key: AUTO_MESSAGE_KEYS.RESERVATION_CANCEL },
    { code: 'AT4', key: AUTO_MESSAGE_KEYS.RESERVATION_NOSHOW },
    { code: 'AT5', key: AUTO_MESSAGE_KEYS.SALES_COMPLETE },
    { code: 'AT6', key: AUTO_MESSAGE_KEYS.SALES_CANCEL },
    { code: 'AT7', key: AUTO_MESSAGE_KEYS.SALES_PREPAID_BUY },
    { code: 'AT8', key: AUTO_MESSAGE_KEYS.SALES_PREPAID_USED },
    { code: 'AT9', key: AUTO_MESSAGE_KEYS.SALES_PREPAID_BUY_CANCEL },
    { code: 'AT10', key: AUTO_MESSAGE_KEYS.SALES_PREPAID_USED_CANCEL },
    { code: 'AT11', key: AUTO_MESSAGE_KEYS.SALES_TICKET_BUY },
    { code: 'AT12', key: AUTO_MESSAGE_KEYS.SALES_TICKET_USED },
    { code: 'AT13', key: AUTO_MESSAGE_KEYS.SALES_TICKET_BUY_CANCEL },
    { code: 'AT14', key: AUTO_MESSAGE_KEYS.SALES_TICKET_USED_CANCEL },
    { code: 'AT15', key: AUTO_MESSAGE_KEYS.SALES_CUSTOMER_POINT_BUY },
    { code: 'AT16', key: AUTO_MESSAGE_KEYS.SALES_CUSTOMER_POINT_USED },
    { code: 'AT17', key: AUTO_MESSAGE_KEYS.SALES_CUSTOMER_POINT_CANCEL },
    { code: 'AT18', key: AUTO_MESSAGE_KEYS.SALES_CUSTOMER_POINT_USED_CANCEL },
    { code: 'AT19', key: AUTO_MESSAGE_KEYS.TICKET_PREPAID_MODIFIED },
    { code: 'AT20', key: AUTO_MESSAGE_KEYS.TICKET_OTHERS_MODIFIED },
    { code: 'AT21', key: AUTO_MESSAGE_KEYS.CUSTOMER_POINT_MODIFIED },
    { code: 'AT22', key: AUTO_MESSAGE_KEYS.TICKET_OTHERS_EXPIRED },
    { code: 'AT23', key: AUTO_MESSAGE_KEYS.TICKET_PREPAID_EXPIRED },
    { code: 'AT24', key: AUTO_MESSAGE_KEYS.SALES_COMPLETE_AFTER },
    { code: 'AT25', key: AUTO_MESSAGE_KEYS.PENDING_RESERVATION_NOTIFICATION }
];
