var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMutation, useQuery } from 'react-query';
import { RestClient } from 'renderer/utils';
import { QUERY_CACHE, ManagerQuery } from 'renderer/queries';
import notification from 'antd/lib/notification';
import { SalesHistoryModel, TicketHistoryModel, PrepaidPointModel, SalesGoalModel, MANAGER_PERMISSION_KEYS, MANAGER_PERMISSION_PRIORITY, UsingHistoryModel, CustomerPointModel, TicketModel, MANAGER_PERMISSION_ACTIONS } from 'renderer/models';
import { usePager } from 'renderer/utils';
export var getSalesById = function (params) {
    var _a = params.queryKey, _key = _a[0], _b = _a[1], storeId = _b.storeId, salesHistoryId = _b.salesHistoryId;
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/sales/").concat(salesHistoryId))
        .then(function (result) { return new SalesHistoryModel(result.data); });
};
var getPaymentHistoryPager = function (options) {
    var _a = options.queryKey, _ = _a[0], pagerOptions = _a[1];
    var storeId = pagerOptions.storeId, rest = __rest(pagerOptions, ["storeId"]);
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/sales"), { params: rest })
        .then(function (result) { return result.data; });
};
var getTicketHistoryPager = function (options) {
    var _a = options.queryKey, _ = _a[0], pagerOptions = _a[1];
    var storeId = pagerOptions.storeId, rest = __rest(pagerOptions, ["storeId"]);
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/history/tickets"), { params: rest })
        .then(function (result) { return result.data; });
};
var getPrepaidPager = function (options) {
    var _a = options.queryKey, _ = _a[0], pagerOptions = _a[1];
    var storeId = pagerOptions.storeId, rest = __rest(pagerOptions, ["storeId"]);
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/history/prepaid"), { params: rest })
        .then(function (result) { return result.data; });
};
var getCustomerPointPager = function (options) {
    var _a = options.queryKey, _ = _a[0], pagerOptions = _a[1];
    var storeId = pagerOptions.storeId, rest = __rest(pagerOptions, ["storeId"]);
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/history/point"), { params: rest })
        .then(function (result) { return result.data; });
};
var createPaymentHistory = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/sales"), payload)
        .then(function (result) { return new SalesHistoryModel(result.data); });
};
var createSales = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/sales/products"), payload)
        .then(function (result) { return new SalesHistoryModel(result.data); });
};
var createRefund = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/sales/refund"), payload)
        .then(function (result) { return new SalesHistoryModel(result.data); });
};
var updateRefund = function (payload) {
    return RestClient.getInstance()
        .put("/stores/".concat(payload.storeId, "/sales/refund"), payload)
        .then(function (result) { return new SalesHistoryModel(result.data); });
};
var deleteRefund = function (payload) {
    return RestClient.getInstance()
        .remove("/stores/".concat(payload.storeId, "/sales/refund?id=").concat(payload.id))
        .then(function (result) { return new SalesHistoryModel(result.data); });
};
var updateSales = function (payload) {
    return RestClient.getInstance()
        .put("/stores/".concat(payload.storeId, "/sales/products/").concat(payload.id), payload)
        .then(function (result) { return new SalesHistoryModel(result.data); });
};
var deletePaymentHistory = function (params) {
    var manager = ManagerQuery.getLoginManagerInCache();
    var hasPermission = manager.hasPermission({
        key: MANAGER_PERMISSION_KEYS.SALES_LIST,
        action: MANAGER_PERMISSION_ACTIONS.DELETE,
        priority: MANAGER_PERMISSION_PRIORITY.ALL
    });
    if (hasPermission) {
        return RestClient.getInstance()
            .remove("/stores/".concat(params.storeId, "/sales/").concat(params.id))
            .then(function (result) { return new SalesHistoryModel(result.data); });
    }
    else {
        notification.warning({
            message: '매출 삭제 권한이없습니다. 관리자계정을 통해 권한을 조정하세요.'
        });
        return Promise.reject();
    }
};
export var sendTicketQR = function (params) {
    return RestClient.getInstance()
        .post("/stores/".concat(params.storeId, "/sales/tickets/").concat(params.ticketId, "/qr"))
        .then(function (result) { return new SalesHistoryModel(result.data); });
};
var mutationConfig = {
    onSuccess: function (result) {
        // TODO: 필터에 맞춰서 optimise
        QUERY_CACHE.invalidateQueries(['paymentHistory']);
        QUERY_CACHE.invalidateQueries(['schedules']);
        QUERY_CACHE.invalidateQueries(['customers']);
        QUERY_CACHE.invalidateQueries(['customerDetail']);
        QUERY_CACHE.invalidateQueries(['prepaidHistory']);
        QUERY_CACHE.invalidateQueries(['customerPointHistory']);
        QUERY_CACHE.invalidateQueries(['salesStatistics']);
        QUERY_CACHE.invalidateQueries(['salesStat']);
        QUERY_CACHE.invalidateQueries(['tickets']);
        QUERY_CACHE.invalidateQueries(['ticketHistory']);
        QUERY_CACHE.invalidateQueries(['totalPrepaidAndTicketAmountAndCustomerPoint']);
        QUERY_CACHE.invalidateQueries(['storePoint']);
        QUERY_CACHE.invalidateQueries('storeStockHistory');
        QUERY_CACHE.invalidateQueries(['usingStatistics']);
        QUERY_CACHE.invalidateQueries(['ticketsStatistics']);
    },
    onError: function (error) {
        // TODO: 필터에 맞춰서 optimise
        notification.error({ message: error.message });
    }
};
export var useGetSalesDetailById = function (query, config) {
    return useQuery(['salesDetail', query], getSalesById, config);
};
export var usePaymentHistoryPager = function (query, config) {
    return usePager('paymentHistory', query, getPaymentHistoryPager, SalesHistoryModel, config);
};
export var useCreatePaymentHistory = function (config) {
    return useMutation(createPaymentHistory, __assign(__assign({}, mutationConfig), config));
};
export var useCreateSales = function (config) {
    return useMutation(createSales, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateSales = function (config) {
    return useMutation(updateSales, __assign(__assign({}, mutationConfig), config));
};
export var useDeletePaymentHistory = function (config) {
    return useMutation(deletePaymentHistory, __assign(__assign({}, mutationConfig), config));
};
export var useTicketHistoryPager = function (query, config) {
    return usePager('ticketHistory', query, getTicketHistoryPager, TicketHistoryModel, config);
};
export var usePrepaidHistoryPager = function (query, config) {
    return usePager('prepaidHistory', query, getPrepaidPager, PrepaidPointModel, config);
};
export var useCustomerPointHistoryPager = function (query, config) {
    return usePager('customerPointHistory', query, getCustomerPointPager, CustomerPointModel, config);
};
export var useRefund = function (config) {
    return useMutation(createRefund, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateRefund = function (config) {
    return useMutation(updateRefund, __assign(__assign({}, mutationConfig), config));
};
export var useCancelRefund = function (config) {
    return useMutation(deleteRefund, __assign(__assign({}, mutationConfig), config));
};
/***
 * Sales Goal API
 */
var getSalesGoalByDate = function (options) {
    var _a = options.queryKey, _ = _a[0], query = _a[1];
    var storeId = query.storeId, rest = __rest(query, ["storeId"]);
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/sales/goals"), { params: rest })
        .then(function (result) { return (result.data ? new SalesGoalModel(result.data) : null); });
};
var getSalesStatisticsGroupByManager = function (options) {
    var _a = options.queryKey, _ = _a[0], query = _a[1];
    var storeId = query.storeId, rest = __rest(query, ["storeId"]);
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/sales/statistics/manager"), { params: rest })
        .then(function (result) { return result.data; });
};
var getSalesStatisticsDetailGroupByManager = function (options) {
    var _a = options.queryKey, _ = _a[0], query = _a[1];
    var storeId = query.storeId, rest = __rest(query, ["storeId"]);
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/sales/statistics/manager/details"), { params: rest })
        .then(function (result) { return result.data; });
};
var copySalesGoalByMonth = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/sales/goals/copy"), payload)
        .then(function (result) { return new SalesGoalModel(result.data); });
};
var createSalesGoal = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/sales/goals"), payload)
        .then(function (result) { return new SalesGoalModel(result.data); });
};
var updateSalesGoal = function (payload) {
    return RestClient.getInstance()
        .put("/stores/".concat(payload.storeId, "/sales/goals/").concat(payload.id), payload)
        .then(function (result) { return new SalesGoalModel(result.data); });
};
var salesGoalMutationConfig = {
    onSuccess: function () {
        // TODO: 필터에 맞춰서 optimise
        QUERY_CACHE.invalidateQueries(['salesGoal']);
    },
    onError: function (error) {
        // TODO: 필터에 맞춰서 optimise
        notification.error({ message: error.message });
    }
};
export var useSalesGoal = function (query, config) {
    return useQuery(['salesGoal', query], getSalesGoalByDate, config);
};
export var useSalesStatisticsGroupByManager = function (query, config) {
    return useQuery(['salesStatisticsGroupByManager', query], getSalesStatisticsGroupByManager, config);
};
export var useSalesStatisticsDetailGroupByManager = function (query, config) {
    return useQuery(['salesStatisticsDetailGroupByManager', query], getSalesStatisticsDetailGroupByManager, config);
};
export var useCreateSalesGoal = function (config) {
    return useMutation(createSalesGoal, __assign(__assign({}, salesGoalMutationConfig), config));
};
export var useCopySalesGoal = function (config) {
    return useMutation(copySalesGoalByMonth, __assign(__assign({}, salesGoalMutationConfig), config));
};
export var useUpdateSalesGoal = function (config) {
    return useMutation(updateSalesGoal, __assign(__assign({}, salesGoalMutationConfig), config));
};
var getSalesStatistics = function (options) {
    var _a = options.queryKey[1], storeId = _a.storeId, rest = __rest(_a, ["storeId"]);
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/sales/statistics"), { params: rest })
        .then(function (result) { return result.data.map(function (item) { return new SalesHistoryModel(item); }); });
};
var getUsingStatistics = function (options) {
    var _a = options.queryKey[1], storeId = _a.storeId, rest = __rest(_a, ["storeId"]);
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/sales/statistics/using"), { params: rest })
        .then(function (result) { return result.data.map(function (item) { return new UsingHistoryModel(item); }); });
};
var getTicketSalesStatistics = function (options) {
    var storeId = options.queryKey[1].storeId;
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/sales/statistics/tickets"))
        .then(function (result) {
        var data = {};
        if (result.data.tickets) {
            data.tickets = result.data.tickets.map(function (ticket) { return new TicketModel(ticket); });
        }
        if (result.data.prepaid) {
            data.prepaid = result.data.prepaid;
        }
        return data;
    });
};
export var useSalesStatistics = function (query, config) {
    return useQuery(['salesStatistics', query], getSalesStatistics, __assign(__assign({}, config), { staleTime: 60 * 1000 * 5 }));
};
export var useUsingStatistics = function (query, config) {
    return useQuery(['usingStatistics', query], getUsingStatistics, __assign(__assign({}, config), { staleTime: 60 * 1000 * 5 }));
};
export var useTicketStatistics = function (query, config) {
    return useQuery(['ticketsStatistics', query], getTicketSalesStatistics, __assign(__assign({}, config), { staleTime: 60 * 1000 * 5 }));
};
