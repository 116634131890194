var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { StoreModel } from 'renderer/models';
import { FranchiseInvoiceModel } from 'renderer/models/FranchiseInvoiceModel';
import { RestClient, usePager } from 'renderer/utils';
export var useStores = function (options, config) {
    return usePager('storesForFranchise', options, function (params) {
        var _a = params.queryKey, _ = _a[0], pagerOptions = _a[1];
        var franchiseId = pagerOptions.franchiseId, rest = __rest(pagerOptions, ["franchiseId"]);
        return RestClient.getInstance()
            .get("/franchises/".concat(franchiseId, "/stores"), { params: rest })
            .then(function (response) {
            return __assign(__assign({}, response.data), { items: response.data.items.map(function (item) { return new StoreModel(item); }) });
        });
    }, StoreModel, config);
};
export var useInvoices = function (options, config) {
    return usePager('invoicesForFranchise', options, function (params) {
        var _a = params.queryKey, _ = _a[0], pagerOptions = _a[1];
        var franchiseId = pagerOptions.franchiseId, rest = __rest(pagerOptions, ["franchiseId"]);
        return RestClient.getInstance()
            .get("/franchises/".concat(franchiseId, "/invoices"), { params: rest })
            .then(function (response) {
            return __assign(__assign({}, response.data), { items: response.data.items.map(function (item) { return new FranchiseInvoiceModel(item); }) });
        });
    }, FranchiseInvoiceModel, config);
};
