import { BugsnagService } from './bugsnag';
import { ChannelService } from './channelIO';
import { MixpanelService } from './mixpanel';
import { LocalStorage } from 'renderer/utils';
import { SESSION_STORAGE_KEY_IS_SUPERADMIN_LOGIN } from 'renderer/constants';
export var EventTracker = {
    setProfile: function (profile, store) {
        var _a, _b, _c;
        var is = LocalStorage.get(SESSION_STORAGE_KEY_IS_SUPERADMIN_LOGIN);
        if (is) {
            return;
        }
        ChannelService.getInstance().boot({}, profile);
        if (profile.storeId) {
            BugsnagService.getInstance().addMetadata(profile);
            // if ((window as any)?.clarity) {
            //   (window as any)?.clarity('identify', `${profile.managerId}`);
            // }
            var mixpanel = MixpanelService.getInstance().getMixPanel();
            mixpanel.identify("".concat(profile.managerId));
            mixpanel.people.set({
                $name: "".concat(store.name, "-").concat(profile.managerName),
                상점명: store.name,
                역할: profile.role,
                플랜: ((_a = store.subscription) === null || _a === void 0 ? void 0 : _a.name) || '무료플랜',
                시작일: (_b = store.subscription) === null || _b === void 0 ? void 0 : _b.startedAt,
                만료일: (_c = store.subscription) === null || _c === void 0 ? void 0 : _c.expiredAt,
                부가서비스: store.subplanSubscriptions.map(function (sub) { return sub.name; })
            });
        }
    },
    send: function (name, properties) {
        MixpanelService.getInstance().track(name, properties);
    }
};
