var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMutation, useQuery } from 'react-query';
import { TicketModel, TicketHistoryModel } from 'renderer/models';
import { RestClient, usePager } from 'renderer/utils';
import { QUERY_CACHE } from 'renderer/queries';
import { CustomerPointModel } from '../models/CustomerPointModel';
// for QR
export var createTicketHistoryForQR = function (payload) {
    return RestClient.getInstance()
        .post("/ticket/history", payload)
        .then(function (response) {
        return new TicketModel(response.data);
    });
};
export var getStoreTicketByIdForQR = function (id) {
    return RestClient.getInstance()
        .get("ticket/".concat(id, "/details"))
        .then(function (response) {
        return new TicketModel(response.data);
    });
};
export var getTicketByTokenForQR = function (token) {
    return RestClient.getInstance()
        .get("external/ticket/".concat(token))
        .then(function (response) {
        return new TicketModel(response.data);
    });
};
export var checkCustomerPasswordForQR = function (customerId, password) {
    return RestClient.getInstance()
        .post("external/customer/".concat(customerId, "/login"), { password: password })
        .then(function (response) {
        return response.data;
    });
};
export var updateCustomerPasswordForQR = function (customerId, password) {
    return RestClient.getInstance()
        .put("external/customer/".concat(customerId), { password: password })
        .then(function (response) {
        return response.data;
    });
};
// for QR
var getAllTicketsByStoreId = function (options) {
    var _a = options.queryKey, _ = _a[0], params = _a[1];
    var storeId = params.storeId, rest = __rest(params, ["storeId"]);
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/tickets"), {
        params: rest
    })
        .then(function (result) { return result.data; });
};
var getTicketHistoryByTicketId = function (options) {
    var _a = options.queryKey, _ = _a[0], params = _a[1];
    var storeId = params.storeId, rest = __rest(params, ["storeId"]);
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/tickets/history"), {
        params: rest
    })
        .then(function (result) { return result.data; });
};
var createPointHistory = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/tickets/customer-point"), payload)
        .then(function (result) { return new CustomerPointModel(result.data); });
};
var updatePointHistory = function (payload) {
    return RestClient.getInstance()
        .put("/stores/".concat(payload.storeId, "/tickets/customer-point"), payload)
        .then(function (result) { return new CustomerPointModel(result.data); });
};
var createTicket = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/tickets"), payload)
        .then(function (result) { return new TicketModel(result.data); });
};
var updateTicket = function (payload) {
    return RestClient.getInstance()
        .put("/stores/".concat(payload.storeId, "/tickets"), payload)
        .then(function (result) { return new TicketModel(result.data); });
};
var deleteTicket = function (params) {
    return RestClient.getInstance()
        .remove("/stores/".concat(params.storeId, "/tickets").concat(params.id))
        .then(function (result) { return new TicketModel(result.data); });
};
var mutationConfig = {
    onSuccess: function (data, _) {
        // TODO: 필터에 맞춰서 optimise
        QUERY_CACHE.invalidateQueries(['tickets']);
        QUERY_CACHE.invalidateQueries(['totalPrepaidAndTicketAmountAndCustomerPoint']);
        QUERY_CACHE.invalidateQueries(['customers.detail']);
        // data가 다다를 건데??
        QUERY_CACHE.invalidateQueries(['customerPointHistory']);
        QUERY_CACHE.invalidateQueries(['ticketHistory']);
        if (data.customerId && data.storeId) {
            QUERY_CACHE.invalidateQueries(['customers', { id: data.customerId, storeId: data.storeId }]);
            QUERY_CACHE.invalidateQueries(['customerDetail', { id: data.customerId, storeId: data.storeId }]);
        }
        QUERY_CACHE.invalidateQueries(['storePoint']);
        QUERY_CACHE.invalidateQueries(['ticketsStatistics']);
    },
    onError: function (error) {
        // TODO: 필터에 맞춰서 optimise
    }
};
export var useTicketsByStoreId = function (params, config) {
    return usePager('tickets', params, getAllTicketsByStoreId, TicketModel, config);
};
export var useTicketHistory = function (params, config) {
    return usePager('ticketHistory', params, getTicketHistoryByTicketId, TicketHistoryModel, config);
};
export var useCreateTicket = function (config) {
    return useMutation(createTicket, __assign(__assign({}, mutationConfig), config));
};
export var useCreateCustomerPointHistory = function (config) {
    return useMutation(createPointHistory, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateTicket = function (config) {
    return useMutation(updateTicket, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateCustomerPointHistory = function (config) {
    return useMutation(updatePointHistory, __assign(__assign({}, mutationConfig), config));
};
export var useDeleteTemplate = function (config) {
    return useMutation(deleteTicket, __assign(__assign({}, mutationConfig), config));
};
var getTotalPrepaidAndTicketAmountAndCustomerPoint = function (options) {
    var storeId = options.queryKey[1];
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/tickets/total"))
        .then(function (result) { return result.data; });
};
export var useTotalPrepaidAndTicketAmountAndCustomerPoint = function (storeId, config) {
    return useQuery(['totalPrepaidAndTicketAmountAndCustomerPoint', storeId], getTotalPrepaidAndTicketAmountAndCustomerPoint, config);
};
