var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from './BaseModel';
import * as dayjs from 'dayjs';
import { DATE_FORMAT } from '../constants/index';
var SUBSCRIPTION_STATUS;
(function (SUBSCRIPTION_STATUS) {
    /** 구독 활성화, 체험 기간 */
    SUBSCRIPTION_STATUS["TRIAL"] = "TRIAL";
    /** 구독 활성화, 결제가 이루어진 정상 구독 */
    SUBSCRIPTION_STATUS["ACTIVE"] = "ACTIVE";
    /** 구독 활성화, 결제 예정일이 지났으나 결제가 이루어지지 않은 상태 */
    SUBSCRIPTION_STATUS["IN_HOLD"] = "IN_HOLD";
    /** 구독 만료, 사용자가 취소함 */
    SUBSCRIPTION_STATUS["CANCELED"] = "CANCELED";
    /** 구독 만료 */
    SUBSCRIPTION_STATUS["EXPIRED"] = "EXPIRED";
})(SUBSCRIPTION_STATUS || (SUBSCRIPTION_STATUS = {}));
var StoreSubscriptionModel = /** @class */ (function (_super) {
    __extends(StoreSubscriptionModel, _super);
    function StoreSubscriptionModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(StoreSubscriptionModel.prototype, "remainDay", {
        get: function () {
            return dayjs(this.expiredAt).add(1, 'day').diff(dayjs(), 'day');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreSubscriptionModel.prototype, "startedAtString", {
        get: function () {
            return dayjs(this.startedAt).format(DATE_FORMAT);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreSubscriptionModel.prototype, "expiredAtString", {
        get: function () {
            return dayjs(this.expiredAt).format(DATE_FORMAT);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreSubscriptionModel.prototype, "startedAtAndexpiredAtString", {
        get: function () {
            return "".concat(this.startedAtString, " ~ ").concat(this.expiredAtString);
        },
        enumerable: false,
        configurable: true
    });
    StoreSubscriptionModel.SUBSCRIPTION_STATUS = SUBSCRIPTION_STATUS;
    return StoreSubscriptionModel;
}(BaseModel));
export { StoreSubscriptionModel };
