var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from './BaseModel';
import { ManagerModel } from './ManagerModel';
var FranchiseModel = /** @class */ (function (_super) {
    __extends(FranchiseModel, _super);
    function FranchiseModel(props) {
        var _this = this;
        var _a;
        _this = _super.call(this, props) || this;
        _this.managers = (_a = props.managers) === null || _a === void 0 ? void 0 : _a.map(function (manager) { return new ManagerModel(manager); });
        return _this;
    }
    Object.defineProperty(FranchiseModel.prototype, "manager", {
        get: function () {
            return this.managers[0];
        },
        enumerable: false,
        configurable: true
    });
    return FranchiseModel;
}(BaseModel));
export { FranchiseModel };
