var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery, useMutation } from 'react-query';
import { QUERY_CACHE } from 'renderer/queries';
import { StoreTransferModel } from 'renderer/models';
import { usePager } from 'renderer/utils/Pager';
import { RestClient } from 'renderer/utils';
import { StoreAccountModel } from '../models/StoreAccountModel';
import { isEmpty } from 'lodash';
var mutationConfig = {
    onSuccess: function () {
        // TODO: 필터에 맞춰서 optimise
        QUERY_CACHE.invalidateQueries(['storeAccounts']);
        QUERY_CACHE.invalidateQueries(['schedules']);
        QUERY_CACHE.invalidateQueries(['storeTransfers']);
    }
};
export var useStoreTransfers = function (options, config) {
    return usePager('storeTransfers', options, function (params) {
        var _a = params.queryKey, _ = _a[0], pagerOptions = _a[1];
        var storeId = pagerOptions.storeId, rest = __rest(pagerOptions, ["storeId"]);
        return RestClient.getInstance()
            .get("/stores/".concat(storeId, "/accounts/transfers"), { params: rest })
            .then(function (response) {
            return __assign(__assign({}, response.data), { items: response.data.items.map(function (item) { return new StoreTransferModel(item); }) });
        });
    }, StoreTransferModel, config);
};
export var useStoreAccount = function (_a, config) {
    var storeId = _a.storeId;
    var queryKey = ['storeAccounts', storeId];
    return useQuery(queryKey, function (options) {
        var storeId = options.queryKey[1];
        console.log('options', options);
        return RestClient.getInstance()
            .get("/stores/".concat(storeId, "/accounts"))
            .then(function (result) {
            if (!isEmpty(result.data)) {
                return new StoreAccountModel(result.data[0]);
            }
            else {
                return null;
            }
        });
    }, __assign(__assign({}, config), { staleTime: 60000 }));
};
export var getRegisterAccountFields = function (_a, config) {
    var storeId = _a.storeId;
    var queryKey = ['getRegisterAccountFields', storeId];
    return useQuery(queryKey, function (options) {
        var storeId = options.queryKey[1];
        return RestClient.getInstance()
            .get("/stores/".concat(storeId, "/accounts/add/fields"))
            .then(function (result) {
            return result.data;
        });
    }, __assign(__assign({}, config), { staleTime: 60000 }));
};
export var useAddAccount = function (config) {
    return useMutation(function (payload) {
        return RestClient.getInstance()
            .post("/stores/".concat(payload.storeId, "/accounts"), payload)
            .then(function (result) { return new StoreAccountModel(result.data); });
    }, __assign(__assign({}, mutationConfig), config));
};
export var useConnectTransferWithSchedule = function (config) {
    return useMutation(function (payload) {
        return RestClient.getInstance()
            .post("/stores/".concat(payload.storeId, "/accounts/transfers/schedule"), payload)
            .then(function (result) { return new StoreTransferModel(result.data); });
    }, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateStoreTransfer = function (config) {
    return useMutation(function (payload) {
        return RestClient.getInstance()
            .put("/stores/".concat(payload.storeId, "/accounts/transfers/").concat(payload.id), payload)
            .then(function (result) { return new StoreTransferModel(result.data); });
    }, __assign(__assign({}, mutationConfig), config));
};
