var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { isNumber, isBoolean, isEmpty, isObject, isString, isDate, toPath } from 'renderer/utils';
/** Omit empty values(null, undefined, '', {}) in a collection */
export function omitEmptyValues(value) {
    var isValid = function (val) { return isNumber(val) || isBoolean(val) || !isEmpty(val); };
    if (Array.isArray(value)) {
        var array = value.map(omitEmptyValues).filter(isValid);
        return !isEmpty(array) ? array : undefined;
    }
    else if (isObject(value)) {
        var object = Object.keys(value).reduce(function (res, key) {
            if (isDate(value[key])) {
                res[key] = value[key];
            }
            else {
                var newValue = omitEmptyValues(value[key]);
                if (isValid(newValue)) {
                    res[key] = newValue;
                }
            }
            return res;
        }, {});
        return !isEmpty(object) ? object : undefined;
    }
    else if (isString(value)) {
        return !isEmpty(value) ? value : undefined;
    }
    else if (isValid(value)) {
        return value;
    }
}
/** Shallow copy and set a value by path */
export function setShallow(object, path, value) {
    var clone = Array.isArray(object) ? __spreadArray([], object, true) : __assign({}, object);
    var keys = toPath(path);
    var lastKey = keys[keys.length - 1];
    var lastObject = keys.slice(0, -1).reduce(function (result, key, index) {
        var target = result[key];
        if (Array.isArray(target)) {
            result[key] = __spreadArray([], target, true);
        }
        else if (isObject(target)) {
            result[key] = __assign({}, target);
        }
        else {
            result[key] = /^\d+$/.test(keys[index + 1]) ? [] : {};
        }
        return result[key];
    }, clone);
    lastObject[lastKey] = value;
    return clone;
}
export function unsetShallow(object, path) {
    if (typeof object !== 'object') {
        return object;
    }
    var clone = Array.isArray(object) ? __spreadArray([], object, true) : __assign({}, object);
    var keys = toPath(path);
    var lastKey = keys.pop();
    var lastObject = keys.reduce(function (result, key, index) {
        if (typeof result !== 'object') {
            return false;
        }
        var target = result[key];
        if (Array.isArray(target)) {
            result[key] = __spreadArray([], target, true);
        }
        else if (isObject(target)) {
            result[key] = __assign({}, target);
        }
        else {
            return false;
        }
        return result[key];
    }, clone);
    if (lastObject) {
        delete lastObject[lastKey];
    }
    return clone;
}
export function convertFormmatedToNumber(value) {
    if (!value) {
        return 0;
    }
    if (typeof value === 'number') {
        return value;
    }
    var result = Number(value.replace(/[^\d^-]/g, ''));
    return isNaN(result) ? 0 : result;
}
export function parseBoolean(value) {
    return /^true$/i.test(value);
}
export function getParameterByName(name, url) {
    if (!url)
        url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
    if (!results)
        return null;
    if (!results[2])
        return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
// https://programmingsummaries.tistory.com/239 댓글 - 노예
// https://mytory.net/archives/11557
// 한글 2byte로 계산
export function getByteLength(str) {
    if (str === void 0) { str = ''; }
    var len = 0;
    for (var i = 0; i < str.length; i++) {
        if (escape(str.charAt(i)).length == 6) {
            len++;
        }
        len++;
    }
    return len;
}
// 한글 2byte로 계산
// export function getByteLength(data: string) {
//   return (function (s, b, i, c) {
//     for (b = i = 0; (c = s.charCodeAt(i++)); b += c == 10 ? 2 : c >> 7 ? 2 : 1);
//     return b;
//   })(data);
// }
export function insertStringAt(index, target, original) {
    return original.substr(0, index) + target + original.substr(index);
}
export function getBase64FromImageFile(img, callback) {
    var reader = new FileReader();
    reader.addEventListener('load', function () { return callback(reader.result); });
    reader.readAsDataURL(img);
}
export function convertKeysToCamelCase(obj) {
    return Object.keys(obj).reduce(function (acc, key) {
        var camelCaseKey = key.replace(/_([a-z])/g, function (match, p1) { return p1.toUpperCase(); });
        acc[camelCaseKey] = obj[key];
        return acc;
    }, {});
}
function recursiveCamelCase(obj) {
    if (obj === null || obj === undefined)
        return obj;
    if (Array.isArray(obj)) {
        return obj.map(function (item) { return recursiveCamelCase(item); });
    }
    else if (typeof obj === 'object') {
        var newObj_1 = {};
        Object.keys(obj).forEach(function (key) {
            var camelKey = key.replace(/_([a-z])/g, function (match, p1) { return p1.toUpperCase(); });
            newObj_1[camelKey] = recursiveCamelCase(obj[key]);
        });
        return newObj_1;
    }
    else {
        return obj;
    }
}
export function deepConvertKeysToCamelCase(obj) {
    return recursiveCamelCase(obj);
}
