var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation, useQuery } from 'react-query';
import { TicketTemplateModel } from 'renderer/models';
import { RestClient, sortBy } from 'renderer/utils';
import { QUERY_CACHE } from 'renderer/queries';
import notification from 'antd/lib/notification';
var getAllTicketTemplate = function (options) {
    var storeId = options.queryKey[1];
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/tickets/templates"))
        .then(function (result) {
        return sortBy(sortBy(result.data.map(function (item) { return new TicketTemplateModel(item); }), 'meta.priority'), function (item) {
            if (item.type === TicketTemplateModel.TICKET_TYPE.CHARGING_MONEY) {
                return 1;
            }
            else if (item.type === TicketTemplateModel.TICKET_TYPE.COUNT) {
                return 2;
            }
            else if (item.type === TicketTemplateModel.TICKET_TYPE.TERM) {
                return 3;
            }
        });
    });
};
var createTicketTemplate = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/tickets/templates"), payload)
        .then(function (result) { return new TicketTemplateModel(result.data); });
};
var updateTicketTemplate = function (payload) {
    return RestClient.getInstance()
        .put("/stores/".concat(payload.storeId, "/tickets/templates"), payload)
        .then(function (result) { return new TicketTemplateModel(result.data); });
};
var deleteTicketTemplate = function (params) {
    return RestClient.getInstance()
        .remove("/stores/".concat(params.storeId, "/tickets/templates/").concat(params.id))
        .then(function (result) { return new TicketTemplateModel(result.data); });
};
var updateTicketTemplateOrder = function (params) {
    return RestClient.getInstance()
        .put("/stores/".concat(params.storeId, "/tickets/templates/order"), params.items)
        .then(function (result) { return result.data.map(function (item) { return new TicketTemplateModel(result.data); }); });
};
var mutationConfig = {
    onSuccess: function () {
        // TODO: 필터에 맞춰서 optimise
        QUERY_CACHE.invalidateQueries(['ticketTemplates']);
    },
    onError: function (error) {
        // TODO: 필터에 맞춰서 optimise
        notification.error({ message: error.message });
    }
};
export var useTicketTemplates = function (storeId, config) {
    return useQuery(['ticketTemplates', storeId], getAllTicketTemplate, __assign(__assign({}, config), { staleTime: 60 * 1000 * 2 }));
};
export var useCreateTemplate = function (config) {
    return useMutation(createTicketTemplate, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateTemplate = function (config) {
    return useMutation(updateTicketTemplate, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateTicketTemplateOrder = function (config) {
    return useMutation(updateTicketTemplateOrder, __assign(__assign({}, mutationConfig), config));
};
export var useDeleteTemplate = function (config) {
    return useMutation(deleteTicketTemplate, __assign(__assign({}, mutationConfig), config));
};
