import { MIXPANEL_TRACK_ID, NODE_ENV } from 'renderer/constants';
import mixpanel from 'mixpanel-browser';
var SYMBOL_INSTANCE = Symbol('singleton');
var MixpanelService = /** @class */ (function () {
    function MixpanelService() {
        mixpanel.init(MIXPANEL_TRACK_ID);
    }
    MixpanelService.prototype.getMixPanel = function () {
        return mixpanel;
    };
    MixpanelService.prototype.getPeople = function () {
        return mixpanel.people;
    };
    MixpanelService.prototype.register = function (profile) {
        mixpanel.register(profile);
    };
    MixpanelService.prototype.setPage = function (pathname) {
        var _a;
        if (!pathname || NODE_ENV !== 'production') {
            return;
        }
        var origin = (_a = window.location) === null || _a === void 0 ? void 0 : _a.origin;
        window.ChannelIO('setPage', "".concat(origin).concat(pathname));
    };
    MixpanelService.prototype.track = function (eventName, eventProperty) {
        if (!eventName || NODE_ENV !== 'production') {
            return;
        }
        mixpanel.people.set;
        mixpanel.track(eventName, eventProperty);
    };
    MixpanelService.getInstance = function () {
        var instanceDescriptor = Object.getOwnPropertyDescriptor(this, SYMBOL_INSTANCE);
        var instance = instanceDescriptor ? instanceDescriptor.value : new this();
        if (!instanceDescriptor) {
            Object.defineProperty(this, SYMBOL_INSTANCE, { value: instance });
        }
        return instance;
    };
    return MixpanelService;
}());
export { MixpanelService };
