var BaseModel = /** @class */ (function () {
    function BaseModel(data) {
        var self = this;
        if (typeof data === 'object') {
            // map data values
            Object.keys(data).forEach(function (key) {
                if (/((?:[a-z]+)At|timestamp)/.test(key) && data[key]) {
                    self[key] = new Date(data[key]);
                }
                else {
                    self[key] = data[key];
                }
            });
        }
        else {
            // set data as id
            var identifier = this.getIdentifier();
            if (typeof identifier !== 'function') {
                self[identifier] = data;
            }
        }
        // set id getter
        if (!Object.getOwnPropertyDescriptor(this, 'id')) {
            Object.defineProperty(this, 'id', {
                configurable: false,
                get: function () {
                    var identifier = this.getIdentifier();
                    if (typeof identifier === 'function') {
                        return identifier.call(this, this);
                    }
                    else {
                        if (typeof Object.getOwnPropertyDescriptor(this, identifier).get !== 'function') {
                            return this[identifier];
                        }
                    }
                    throw new Error('BaseModel should provide an identifier!');
                }
            });
        }
    }
    BaseModel.prototype.getIdentifier = function () {
        return this.constructor.identifier;
    };
    BaseModel.build = function (data) {
        return new this(data);
    };
    BaseModel.clone = function (target, additionalPayload) {
        return Object.assign(Object.create(Object.getPrototypeOf(target)), target, additionalPayload);
    };
    Object.defineProperty(BaseModel.prototype, "isDeleted", {
        get: function () {
            return !!this.deletedAt;
        },
        enumerable: false,
        configurable: true
    });
    BaseModel.identifier = 'id';
    return BaseModel;
}());
export { BaseModel };
