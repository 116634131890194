var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createUUID } from './uuid';
import memoize from 'lodash-es/memoize';
import { loadScript } from 'renderer/utils';
import { IAMPORT_STORE_ID } from 'renderer/constants';
export var IMP_PAYLOAD_DEFAULT = {
    pg: 'danal',
    pay_method: 'card' // "card"만 지원됩니다
    // merchant_uid: createUUID() // 빌링키 발급용 주문번호
    // 정기결제 용 customer_uid
    // customer_uid: createUUID() // 카드(빌링키)와 1:1로 대응하는 값
    // name: `결제용카드등록(${props.store.name})`,
    // amount: 0, // 0 으로 설정하여 빌링키 발급만 진행합니다.
    // // TODO: 회원가입 시 이메일 받도록 변경
    // buyer_email: '',
    // buyer_name: props.store.ceoName,
    // buyer_tel: props.store.tel,
    // buyer_addr: props.store.address.address,
    // buyer_postcode: props.store.address.postcode
};
export var getImportDataFromStore = function (store, options) {
    var _a, _b, _c;
    return __assign(__assign({}, IMP_PAYLOAD_DEFAULT), { merchant_uid: createUUID(), name: options.name ? options.name : "".concat(options.amount, "\uC6D0 \uACB0\uC81C"), amount: options.amount || 0, 
        // TODO: 회원가입 시 이메일 받도록 변경
        buyer_email: '', buyer_name: store.ceoName, buyer_tel: store.tel, buyer_addr: typeof ((_a = store.address) === null || _a === void 0 ? void 0 : _a.address) === 'string' ? (_b = store.address) === null || _b === void 0 ? void 0 : _b.address : '-', buyer_postcode: ((_c = store.address) === null || _c === void 0 ? void 0 : _c.postcode) || '-' });
};
export var importRequestPayFromStore = function (store, options) {
    var _a, _b, _c;
    var data = getImportDataFromStore(store, options);
    if (store.id === 1) {
        data = {
            pg: 'jtnet',
            pay_method: 'card',
            merchant_uid: createUUID(),
            name: options.name ? options.name : "".concat(options.amount, "\uC6D0 \uACB0\uC81C"),
            amount: options.amount || 0,
            buyer_email: '',
            buyer_name: store.ceoName,
            buyer_tel: store.tel,
            buyer_addr: typeof ((_a = store.address) === null || _a === void 0 ? void 0 : _a.address) === 'string' ? (_b = store.address) === null || _b === void 0 ? void 0 : _b.address : '-',
            buyer_postcode: ((_c = store.address) === null || _c === void 0 ? void 0 : _c.postcode) || '-'
        };
    }
    return new Promise(function (resolve, reject) {
        IMP.request_pay(data, function (result) {
            if (result.success) {
                resolve(result);
            }
            else {
                console.log('result', result);
                reject(false);
            }
        });
    });
};
export var loadIamportScripts = memoize(function () { return __awaiter(void 0, void 0, void 0, function () {
    var error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                // await loadScript('https://code.jquery.com/jquery-3.5.1.min.js');
                return [4 /*yield*/, loadScript('https://cdn.iamport.kr/v1/iamport.js')];
            case 1:
                // await loadScript('https://code.jquery.com/jquery-3.5.1.min.js');
                _a.sent();
                IMP.init(IAMPORT_STORE_ID);
                return [2 /*return*/, true];
            case 2:
                error_1 = _a.sent();
                return [2 /*return*/, false];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var loadXLSXCDN = memoize(function () { return __awaiter(void 0, void 0, void 0, function () {
    var error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, loadScript('https://unpkg.com/xlsx/dist/xlsx.full.min.js')];
            case 1:
                _a.sent();
                return [2 /*return*/, true];
            case 2:
                error_2 = _a.sent();
                return [2 /*return*/, false];
            case 3: return [2 /*return*/];
        }
    });
}); });
