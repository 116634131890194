var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from './BaseModel';
var OUR_PRODUCT_NAME;
(function (OUR_PRODUCT_NAME) {
    OUR_PRODUCT_NAME["FREE"] = "free";
    OUR_PRODUCT_NAME["SMALL"] = "small";
    OUR_PRODUCT_NAME["POINT"] = "point";
})(OUR_PRODUCT_NAME || (OUR_PRODUCT_NAME = {}));
var OUR_PRODUCT_TYPE;
(function (OUR_PRODUCT_TYPE) {
    OUR_PRODUCT_TYPE["PLAN"] = "plan";
    OUR_PRODUCT_TYPE["SUB_PLAN"] = "sub_plan";
    OUR_PRODUCT_TYPE["CONSUMABLE"] = "consumable";
})(OUR_PRODUCT_TYPE || (OUR_PRODUCT_TYPE = {}));
var OurProductModel = /** @class */ (function (_super) {
    __extends(OurProductModel, _super);
    function OurProductModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(OurProductModel.prototype, "availableTrial", {
        get: function () {
            var _a;
            return ((_a = this.options[0]) === null || _a === void 0 ? void 0 : _a.price) === 0;
        },
        enumerable: false,
        configurable: true
    });
    OurProductModel.OUR_PRODUCT_NAME = OUR_PRODUCT_NAME;
    OurProductModel.OUR_PRODUCT_TYPE = OUR_PRODUCT_TYPE;
    return OurProductModel;
}(BaseModel));
export { OurProductModel };
