import dayjs from 'dayjs';
import { floor, Lodash } from 'renderer/utils';
import localeData from 'dayjs/plugin/localeData';
// @ts-ignore
import * as dates from 'react-big-calendar/lib/utils/dates';
import { DateLocalizer } from 'react-big-calendar';
import weekday from 'dayjs/plugin/weekday';
dayjs.extend(weekday);
export var isValidDateString = function (value) {
    return dayjs(value, 'YYYY-MM-DDThh:mm:ss.000Z').isValid();
};
export var convertMinutesToYYYYMMDDHHmm = function (minutes) {
    var today = dayjs();
    var hours = floor(minutes / 60, 0);
    var restMinutes = "".concat(minutes % 60);
    return "".concat(today.format('YYYY-MM-DD'), " ").concat(hours, ":").concat(restMinutes);
};
export var convertMinutesToDayjs = function (minutes) {
    var today = dayjs().startOf('day');
    return today.add(minutes, 'minute');
};
export var convertDayjsToMinutes = function (day) {
    var today = dayjs().startOf('day');
    return day === null || day === void 0 ? void 0 : day.diff(today, 'minute');
};
export var convertMinutesToHHMM = function (minutes) {
    var hours = floor(minutes / 60, 0);
    var hourString = hours === 0 ? '00' : hours >= 10 ? hours : "0".concat(hours);
    var restMinutes = minutes % 60;
    var minutesString = restMinutes === 0 ? '00' : restMinutes >= 10 ? restMinutes : "0".concat(restMinutes);
    return "".concat(hourString, ":").concat(minutesString);
};
export var convertHHmmStringToMinutes = function (hhmm) {
    if (!hhmm) {
        return 0;
    }
    var _a = hhmm.split(':'), hours = _a[0], minutes = _a[1];
    if (hours && minutes) {
        return Number(hours) * 60 + Number(minutes);
    }
    else {
        return 0;
    }
};
export var dateToHHmm = function (date) {
    return dayjs(date).format('HH:mm');
};
export var convertHHmmToDate = function (HHmm) {
    return dayjs(convertMinutesToYYYYMMDDHHmm(convertHHmmStringToMinutes(HHmm))).toDate();
};
export var getRanges = function (today) {
    today = dayjs(today);
    return [
        {
            label: '어제',
            value: [today.subtract(1, 'day').startOf('day'), today.subtract(1, 'day').endOf('day')]
        },
        {
            label: '오늘',
            value: [today.startOf('day'), today.endOf('day')]
        },
        {
            label: '이번주',
            value: [today.startOf('week'), today.endOf('week')]
        },
        {
            label: '지난주',
            value: [today.subtract(1, 'week').startOf('week'), today.subtract(1, 'week').endOf('week')]
        },
        {
            label: '이번달',
            value: [today.startOf('month'), today.endOf('month')]
        },
        {
            label: '지난달',
            value: [today.subtract(1, 'month').startOf('month'), today.subtract(1, 'month').endOf('month')]
        }
    ];
};
export var WEEK_DAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
var dateRangeFormat = function (_a, culture, local) {
    var start = _a.start, end = _a.end;
    return local.format(start, 'L', culture) + ' – ' + local.format(end, 'L', culture);
};
var timeRangeFormat = function (_a, culture, local) {
    var start = _a.start, end = _a.end;
    return local.format(start, 'LT', culture) + ' – ' + local.format(end, 'LT', culture);
};
var timeRangeStartFormat = function (_a, culture, local) {
    var start = _a.start;
    return local.format(start, 'LT', culture) + ' – ';
};
var timeRangeEndFormat = function (_a, culture, local) {
    var end = _a.end;
    return ' – ' + local.format(end, 'LT', culture);
};
var weekRangeFormat = function (_a, culture, local) {
    var start = _a.start, end = _a.end;
    return local.format(start, 'MMMM DD', culture) +
        ' – ' +
        local.format(end, dates.eq(start, end, 'month') ? 'DD' : 'MMMM DD', culture);
};
export var getMinAndMaxHour = function (store, schedules, isMobile) {
    if (schedules === void 0) { schedules = []; }
    if (isMobile === void 0) { isMobile = false; }
    var minHour = Lodash.min(schedules
        .filter(function (item) { return item.type !== 'off'; })
        .map(function (schedule) {
        return (schedule === null || schedule === void 0 ? void 0 : schedule.dateOption.startedAt) ? dayjs(schedule === null || schedule === void 0 ? void 0 : schedule.dateOption.startedAt).format('HH:mm') : '23:59';
    })
        .concat(Object.keys(store.operatingHour).map(function (key) {
        return store.operatingHour[key].isActive ? store.operatingHour[key].start || '23:59' : '23:59';
    })));
    var maxHour = Lodash.max(schedules
        .filter(function (item) { return item.type !== 'off'; })
        .map(function (schedule) {
        if (dayjs(schedule === null || schedule === void 0 ? void 0 : schedule.dateOption.endedAt).isSame(dayjs(schedule === null || schedule === void 0 ? void 0 : schedule.dateOption.startedAt), 'day')) {
            return (schedule === null || schedule === void 0 ? void 0 : schedule.dateOption.endedAt) ? dayjs(schedule === null || schedule === void 0 ? void 0 : schedule.dateOption.endedAt).format('HH:mm') : '00:00';
        }
        else {
            return '23:59';
        }
    })
        .concat(Object.keys(store.operatingHour).map(function (key) {
        return store.operatingHour[key].isActive
            ? store.operatingHour[key].end === '00:00'
                ? '23:59'
                : store.operatingHour[key].end || '00:00'
            : '00:00';
    })));
    var min = dayjs(convertHHmmToDate(minHour));
    var max = dayjs(convertHHmmToDate(maxHour));
    return isMobile
        ? {
            min: min.startOf('hour').toDate(),
            max: max.endOf('hour').toDate()
        }
        : {
            min: min.minute() >= 30
                ? min.startOf('hour').add(30, 'minute').startOf('minute').toDate()
                : min.startOf('hour').toDate(),
            max: max.minute() === 0
                ? max.startOf('hour').toDate()
                : max.minute() > 30
                    ? max.endOf('hour').toDate()
                    : max.startOf('hour').add(30, 'minute').startOf('minute').toDate()
        };
};
export function getDaysBetweenDates(start, end) {
    if (!start || !end)
        return [];
    var days = [];
    var currentDate = dayjs(start).toDate();
    end = dayjs(end).toDate();
    while (currentDate <= end && days.length < 7) {
        var dayOfWeek = currentDate.getDay();
        if (!days.includes(dayOfWeek)) {
            days.push(dayOfWeek);
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return days;
}
export function getDayNamessBetweenDates(start, end) {
    return getDaysBetweenDates(start, end).map(function (day) { var _a; return (_a = WEEK_DAYS[day]) === null || _a === void 0 ? void 0 : _a.substring(0, 3); });
}
export var formats = {
    dateFormat: 'DD',
    dayFormat: 'DD ddd',
    weekdayFormat: 'ddd',
    selectRangeFormat: timeRangeFormat,
    eventTimeRangeFormat: timeRangeFormat,
    eventTimeRangeStartFormat: timeRangeStartFormat,
    eventTimeRangeEndFormat: timeRangeEndFormat,
    timeGutterFormat: 'LT',
    monthHeaderFormat: 'MMMM YYYY',
    dayHeaderFormat: 'dddd MMM DD',
    dayRangeHeaderFormat: weekRangeFormat,
    agendaHeaderFormat: dateRangeFormat,
    agendaDateFormat: 'ddd MMM DD',
    agendaTimeFormat: 'LT',
    agendaTimeRangeFormat: timeRangeFormat
};
var isJustDate = function (date) {
    return (dayjs(date).hour() === 0 &&
        dayjs(date).minute() === 0 &&
        dayjs(date).second() === 0 &&
        dayjs(date).millisecond() === 0);
};
var isSameDate = function (date1, date2) {
    return dayjs(date1).isSame(dayjs(date2), 'date');
};
var dayjsLocalizer = function () {
    var locale = function (m, c) { return (c ? m.locale(c) : m); };
    return new DateLocalizer({
        formats: formats,
        format: function (value, format, culture) {
            return locale(dayjs(value), culture).format(format);
        },
        firstOfWeek: function (culture) {
            var data = dayjs.localeData();
            return data ? data.firstDayOfWeek() : 0;
        },
        merge: function (date, time) {
            if (!date && !time)
                return null;
            var tm = dayjs(time);
            var dt = dayjs(date).startOf('day');
            return dt
                .set('hour', tm.hour())
                .set('minute', tm.minute())
                .set('second', tm.second())
                .set('millisecond', tm.millisecond())
                .toDate();
        },
        isSameDate: isSameDate,
        isJustDate: isJustDate,
        startAndEndAreDateOnly: function (start, end) {
            return isJustDate(start) && isJustDate(end);
        }
        // isJustDate:
    });
};
dayjs.extend(localeData);
export var bigCalenderFormats = {
    timeGutterFormat: 'HH:mm',
    selectRangeFormat: function (data) {
        return "".concat(dayjs(data.start).format('a HH:mm'), " - ").concat(dayjs(data.end).format('a HH:mm'));
    }
};
export var bigCalendarLocalizer = dayjsLocalizer();
