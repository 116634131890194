var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery, useMutation } from 'react-query';
import { OurProductModel, StorePaymentMethod, StorePointModel, StoreSubscriptionModel, OurInvoiceModel } from 'renderer/models';
import { RestClient } from 'renderer/utils';
import { QUERY_CACHE } from 'renderer/queries';
import notification from 'antd/lib/notification';
var getStorePaymentMethodsByStoreId = function (options) {
    var storeId = options.queryKey[1];
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/payments/methods"))
        .then(function (result) { return result.data.map(function (item) { return new StorePaymentMethod(item); }); });
};
var createStorePaymentMethod = function (payload) {
    var storeId = payload.storeId, rest = __rest(payload, ["storeId"]);
    return RestClient.getInstance()
        .post("/stores/".concat(storeId, "/payments/methods"), rest)
        .then(function (result) { return new StorePaymentMethod(result.data); });
};
var updateStorePaymentMethod = function (payload) {
    return RestClient.getInstance()
        .put("/stores/".concat(payload.storeId, "/payments/methods/").concat(payload.id), payload)
        .then(function (result) { return result.data; });
};
var updatePrimary = function (payload) {
    return RestClient.getInstance()
        .put("/stores/".concat(payload.storeId, "/payments/methods/primary"), payload)
        .then(function (result) { return result.data; });
};
var deleteStorePaymentMethod = function (data) {
    return RestClient.getInstance()
        .remove("/stores/".concat(data.storeId, "/payments/methods/").concat(data.id))
        .then(function (result) { return result.data; });
};
var getOurProductsByStoreId = function (options) {
    var storeId = options.queryKey[1];
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/payments/products"))
        .then(function (result) {
        return result.data.map(function (item) {
            // if ([1].includes(storeId) && item.type === 'plan') {
            //   return new OurProductModel({
            //     ...item,
            //     options: item.options.filter((item) => item.id < 3)
            //   });
            // } else {
            //   return new OurProductModel(item);
            // }
            return new OurProductModel(item);
        });
    });
};
var chargeStorePoint = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/payments/point"), payload)
        .then(function (result) { return result.data; });
};
var updateStorePoint = function (payload) {
    return RestClient.getInstance()
        .put("/stores/".concat(payload.storeId, "/payments/point"), payload)
        .then(function (result) { return result.data; });
};
var getStorePoint = function (options) {
    var storeId = options.queryKey[1];
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/payments/point"))
        .then(function (result) { return new StorePointModel(result.data); });
};
var mutationConfig = {
    onSuccess: function () {
        // TODO: 필터에 맞춰서 optimise
        QUERY_CACHE.invalidateQueries(['paymentMetods']);
    }
};
export var usePaymentMethods = function (storeId, config) {
    return useQuery(['paymentMetods', storeId], getStorePaymentMethodsByStoreId, config);
};
export var useCreatePaymehtMethod = function (config) {
    return useMutation(createStorePaymentMethod, __assign(__assign({}, mutationConfig), config));
};
export var useUpdatPaymentMethod = function (config) {
    return useMutation(updateStorePaymentMethod, __assign(__assign({}, mutationConfig), config));
};
export var useUpdatePrimary = function (config) {
    return useMutation(updatePrimary, __assign(__assign({}, mutationConfig), config));
};
export var useDeletePaymentMethod = function (config) {
    return useMutation(deleteStorePaymentMethod, __assign(__assign({}, mutationConfig), config));
};
export var useOurProducts = function (storeId, config) {
    return useQuery(['ourProducts', storeId], getOurProductsByStoreId, config);
};
var storePointMutationConfig = {
    onSuccess: function () {
        // TODO: 필터에 맞춰서 optimise
        QUERY_CACHE.invalidateQueries(['storePoint']);
    },
    onError: function (error) {
        notification.error({ message: error.message });
    }
};
export var useStorePoint = function (storeId, config) {
    return useQuery(['storePoint', storeId], getStorePoint, config);
};
export var useChargeStorePoints = function (config) {
    return useMutation(chargeStorePoint, __assign(__assign({}, storePointMutationConfig), config));
};
export var useUpdateStorePoints = function (config) {
    return useMutation(updateStorePoint, __assign(__assign({}, storePointMutationConfig), config));
};
var applyProductTrial = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/payments/subscriptions/trial"), payload)
        .then(function (result) { return new StoreSubscriptionModel(result.data); });
};
var createSubscription = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/payments/subscriptions/new"), payload)
        .then(function (result) {
        return {
            subscriptions: (result.data.subscriptions || []).map(function (item) { return new StoreSubscriptionModel(item); })
        };
    });
};
var getAvailableTrialByStoreId = function (options) {
    var _a = options.queryKey[1], storeId = _a.storeId, rest = __rest(_a, ["storeId"]);
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/payments/subscriptions/trial/check"), { params: rest })
        .then(function (result) { return result.data; });
};
var estimateSubscriptionByStoreId = function (options) {
    var _a = options.queryKey, _ = _a[0], payload = _a[1];
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/payments/subscriptions/will"), payload)
        .then(function (result) {
        return {
            invoice: result.data.invoice ? new OurInvoiceModel(result.data.invoice) : null,
            add: result.data.add.map(function (item) { return new StoreSubscriptionModel(item); }),
            same: result.data.same.map(function (item) { return new StoreSubscriptionModel(item); }),
            remove: result.data.remove.map(function (item) { return new StoreSubscriptionModel(item); })
        };
    });
};
var subscriptionMutationConfig = {
    onSuccess: function () {
        // TODO: 필터에 맞춰서 optimise
        QUERY_CACHE.invalidateQueries(['store']);
        QUERY_CACHE.invalidateQueries(['availableTrial']);
        QUERY_CACHE.invalidateQueries(['estimateSubscriptionByStoreId']);
    },
    onError: function (error) {
        notification.error({ message: error.message });
    }
};
export var useAvailableTrial = function (params, config) {
    return useQuery(['availableTrial', params], getAvailableTrialByStoreId, config);
};
export var getAvailableTrialByProductId = function (storeId, productId) {
    var _a, _b, _c, _d;
    var products = (((_b = (_a = QUERY_CACHE.getQueryCache().find(['ourProducts', storeId])) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.data) || []);
    var product = products.find(function (product) { return product.id === productId; });
    if (product.availableTrial) {
        var result = (((_d = (_c = QUERY_CACHE.getQueryCache().find(['availableTrial', { storeId: storeId }])) === null || _c === void 0 ? void 0 : _c.state) === null || _d === void 0 ? void 0 : _d.data) ||
            []);
        return !(result === null || result === void 0 ? void 0 : result.find(function (item) {
            return item.ourProductId === productId && product.availableTrial;
        }));
    }
    else {
        return false;
    }
};
export var useApplyTrialSubscription = function (config) {
    return useMutation(applyProductTrial, __assign(__assign({}, subscriptionMutationConfig), config));
};
export var useEstimateSubscriptionByStoreId = function (payload, config) {
    return useQuery(['estimateSubscriptionByStoreId', payload], estimateSubscriptionByStoreId, config);
};
export var useCreateSubscription = function (config) {
    return useMutation(createSubscription, __assign(__assign({}, subscriptionMutationConfig), config));
};
