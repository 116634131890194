var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import S3 from 'aws-s3';
import { comporessImage } from 'common-utils';
import { NODE_ENV, AWS_SCRET_ACCESS_KEY, AWS_ACCESS_KEY_ID } from 'renderer/constants';
import { BugsnagService, Lodash, createUUID, mapSeries, sleep } from 'renderer/utils';
var COMMON_CONFIG = {
    bucketName: 'blockdeal-static-resources',
    region: 'ap-northeast-2',
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SCRET_ACCESS_KEY
};
var config = __assign(__assign({}, COMMON_CONFIG), { dirName: "store-images-".concat(NODE_ENV) /* optional */ });
var SYMBOL_INSTANCE = Symbol('singleton');
var S3Client = /** @class */ (function () {
    // const S3Client = new S3(config);
    function S3Client() {
        this.client = new S3(config);
    }
    S3Client.getInstance = function () {
        var instanceDescriptor = Object.getOwnPropertyDescriptor(this, SYMBOL_INSTANCE);
        var instance = instanceDescriptor ? instanceDescriptor.value : new this();
        if (!instanceDescriptor) {
            Object.defineProperty(this, SYMBOL_INSTANCE, { value: instance });
        }
        return instance;
    };
    S3Client.prototype.uploadFile = function (file, fileName, config) {
        return __awaiter(this, void 0, void 0, function () {
            var client, retries, attempt, data, error_1, delay;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        client = this.client;
                        if (config) {
                            client = new S3(__assign(__assign({}, COMMON_CONFIG), config));
                        }
                        retries = 3;
                        attempt = 0;
                        _a.label = 1;
                    case 1:
                        if (!(attempt <= retries)) return [3 /*break*/, 9];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 8]);
                        return [4 /*yield*/, client.uploadFile(file, fileName)];
                    case 3:
                        data = _a.sent();
                        return [2 /*return*/, data];
                    case 4:
                        error_1 = _a.sent();
                        console.error("Attempt ".concat(attempt + 1, " failed at ").concat(new Date().toISOString(), ": ").concat(error_1.message));
                        if (!(attempt < retries)) return [3 /*break*/, 6];
                        delay = Math.pow(2, attempt) * 1000;
                        console.log("Retrying in ".concat(delay / 1000, " seconds..."));
                        return [4 /*yield*/, sleep(delay)];
                    case 5:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        console.error('All retries failed. Throwing error.');
                        throw error_1;
                    case 7: return [3 /*break*/, 8];
                    case 8:
                        attempt++;
                        return [3 /*break*/, 1];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    S3Client.prototype.deleteFile = function (fileName, config) {
        return __awaiter(this, void 0, void 0, function () {
            var client;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        client = this.client;
                        if (config) {
                            client = new S3(__assign(__assign({}, COMMON_CONFIG), config));
                        }
                        return [4 /*yield*/, client.deleteFile(fileName)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    S3Client.uploadImagesToS3 = function (defaultItems, currentItems, config) {
        if (defaultItems === void 0) { defaultItems = []; }
        return __awaiter(this, void 0, void 0, function () {
            var results;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!currentItems || (currentItems === null || currentItems === void 0 ? void 0 : currentItems.length) === 0) {
                            BugsnagService.getInstance().notify(new Error(JSON.stringify({
                                message: "\uC0AC\uC9C4\uC7740\uC7A5"
                            })));
                        }
                        if (!Lodash.isEqual(defaultItems, currentItems)) return [3 /*break*/, 1];
                        return [2 /*return*/, defaultItems];
                    case 1: return [4 /*yield*/, Promise.all(defaultItems.map(function (item) { return __awaiter(_this, void 0, void 0, function () {
                            var fileName, response, error_2;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (currentItems.includes(item)) {
                                            return [2 /*return*/];
                                        }
                                        _a.label = 1;
                                    case 1:
                                        _a.trys.push([1, 3, , 4]);
                                        fileName = item.split('/').pop();
                                        return [4 /*yield*/, S3Client.getInstance().deleteFile(fileName, config)];
                                    case 2:
                                        response = _a.sent();
                                        return [2 /*return*/, encodeURI(response.location)];
                                    case 3:
                                        error_2 = _a.sent();
                                        BugsnagService.getInstance().notify(new Error(JSON.stringify({
                                            message: "".concat(error_2.message, "_\uC0AC\uC9C4\uC0AD\uC81C\uC911\uC5D0\uB7EC")
                                        })));
                                        console.log('error', error_2);
                                        return [3 /*break*/, 4];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); }))];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, mapSeries(currentItems, function (item) { return __awaiter(_this, void 0, void 0, function () {
                                var comporessed, isSuccess, limitSize, _a, error_3, response, error_4;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            if (!(typeof item === 'string')) return [3 /*break*/, 1];
                                            return [2 /*return*/, item];
                                        case 1:
                                            comporessed = void 0;
                                            isSuccess = true;
                                            _b.label = 2;
                                        case 2:
                                            _b.trys.push([2, 6, , 7]);
                                            limitSize = 500 * 1024;
                                            if (!(item.size > limitSize)) return [3 /*break*/, 4];
                                            return [4 /*yield*/, comporessImage(item)];
                                        case 3:
                                            _a = _b.sent();
                                            return [3 /*break*/, 5];
                                        case 4:
                                            _a = item;
                                            _b.label = 5;
                                        case 5:
                                            comporessed = _a;
                                            return [3 /*break*/, 7];
                                        case 6:
                                            error_3 = _b.sent();
                                            isSuccess = false;
                                            BugsnagService.getInstance().notify(new Error(JSON.stringify({
                                                message: "".concat(error_3.message, "_\uC0AC\uC9C4\uC555\uCD95\uC911\uC5D0\uB7EC")
                                            })));
                                            return [3 /*break*/, 7];
                                        case 7:
                                            _b.trys.push([7, 10, , 11]);
                                            if (!(comporessed && isSuccess)) return [3 /*break*/, 9];
                                            return [4 /*yield*/, S3Client.getInstance().uploadFile(comporessed, createUUID(), config)];
                                        case 8:
                                            response = _b.sent();
                                            return [2 /*return*/, encodeURI(response.location)];
                                        case 9: return [3 /*break*/, 11];
                                        case 10:
                                            error_4 = _b.sent();
                                            BugsnagService.getInstance().notify(new Error(JSON.stringify({
                                                message: "".concat(error_4.message, "_\uC0AC\uC9C4\uC804\uC1A1\uC911\uC5D0\uB7EC")
                                            })));
                                            return [3 /*break*/, 11];
                                        case 11: return [2 /*return*/];
                                    }
                                });
                            }); })];
                    case 3:
                        results = _a.sent();
                        return [2 /*return*/, results.filter(Boolean)];
                }
            });
        });
    };
    return S3Client;
}());
export { S3Client };
var messageDirectoryConfig = __assign(__assign({}, COMMON_CONFIG), { dirName: "message-images-".concat(NODE_ENV) /* optional */ });
var MessageDirectoryClient = new S3(messageDirectoryConfig);
export var uploadFileToMessageImageDirectory = function (file, fileName) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, MessageDirectoryClient.uploadFile(file, fileName)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var deleteFileToMessageImageDirectory = function (fileName) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, MessageDirectoryClient.deleteFile(fileName)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
/*  Notice that if you don't provide a dirName, the file will be automatically uploaded to the root of your bucket */
/* This is optional */
/**
 * {
 *   Response: {
 *     bucket: "your-bucket-name",
 *     key: "photos/image.jpg",
 *     location: "https://your-bucket.s3.amazonaws.com/photos/image.jpg"
 *   }
 * }
 */
export var base64ToFile = function (dataurl, name) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], name, { type: mime });
};
