var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from 'renderer/models';
var SHARE_OPTIONS;
(function (SHARE_OPTIONS) {
    SHARE_OPTIONS["ALL"] = "all";
    SHARE_OPTIONS["MAIN"] = "main";
    SHARE_OPTIONS["SUB"] = "sub";
    SHARE_OPTIONS["NONE"] = "none";
})(SHARE_OPTIONS || (SHARE_OPTIONS = {}));
var CustomerRelationModel = /** @class */ (function (_super) {
    __extends(CustomerRelationModel, _super);
    function CustomerRelationModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomerRelationModel.prototype.isMain = function (customerId) {
        return this.mainCustomerId === customerId;
    };
    CustomerRelationModel.prototype.getOtherCustomer = function (mainCustomerId) {
        return this.isMain(mainCustomerId) ? this.otherCustomer : this.mainCustomer;
    };
    CustomerRelationModel.prototype.getCustomerName = function (customerId) {
        var _a, _b;
        return this.isMain(customerId) ? (_a = this.otherCustomer) === null || _a === void 0 ? void 0 : _a.name : (_b = this.mainCustomer) === null || _b === void 0 ? void 0 : _b.name;
    };
    CustomerRelationModel.prototype.getRelationName = function (customerId) {
        return this.isMain(customerId) ? this.otherCustomerTitle : this.mainCustomerTitle;
    };
    Object.defineProperty(CustomerRelationModel.prototype, "shareOptionString", {
        get: function () {
            return this.shareOption === 'all'
                ? '서로공유'
                : this.shareOption === 'main'
                    ? "".concat(this.mainCustomerTitle, "\uAC83\uB9CC \uACF5\uC720")
                    : this.shareOption === 'sub'
                        ? "".concat(this.otherCustomerTitle, "\uAC83\uB9CC \uACF5\uC720")
                        : '공유안함';
        },
        enumerable: false,
        configurable: true
    });
    CustomerRelationModel.SHARE_OPTIONS = SHARE_OPTIONS;
    return CustomerRelationModel;
}(BaseModel));
export { CustomerRelationModel };
