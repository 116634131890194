var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from 'react-query';
import React from 'react';
import { omitEmptyValues } from './object';
export function usePager(key, options, requestFunction, model, config) {
    var _a = React.useState(options), pagerOptions = _a[0], setPagerOptions = _a[1];
    var _b = React.useState({}), pagerResult = _b[0], setPagerResult = _b[1];
    var _c = useQuery([key, pagerOptions], requestFunction, config), data = _c.data, rest = __rest(_c, ["data"]);
    React.useEffect(function () {
        var _a;
        if (data) {
            setPagerOptions(__assign(__assign({}, pagerOptions), { page: data.page, limit: data.limit }));
            setPagerResult({
                items: (_a = data.items) === null || _a === void 0 ? void 0 : _a.map(function (item) { return (model ? new model(item) : item); }),
                page: data.page,
                limit: data.limit,
                totalCount: data.totalCount,
                totalPages: data.totalPages
            });
        }
    }, [data]);
    var next = function () {
        setPagerOptions(__assign(__assign({}, pagerOptions), { page: data.page + 1 }));
    };
    var prev = function () {
        setPagerOptions(__assign(__assign({}, pagerOptions), { page: data.page - 1 }));
    };
    var goTo = function (page) {
        setPagerOptions(__assign(__assign({}, pagerOptions), { page: page }));
    };
    var setNewPagerOptions = function (options) {
        setPagerOptions(omitEmptyValues(__assign(__assign({}, pagerOptions), options)));
    };
    var getPaginateObject = function () {
        return {
            current: pagerResult.page,
            pageSize: pagerResult.limit,
            total: pagerResult.totalCount,
            onChange: function (page, limit) {
                setNewPagerOptions({ page: page, limit: limit });
            },
            showSizeChanger: true
        };
    };
    return __assign(__assign({}, rest), { isLoading: rest.isLoading, query: options, latestData: data, pagerResult: pagerResult, getPaginateObject: getPaginateObject, next: next, prev: prev, goTo: goTo, pagerOptions: pagerOptions, setNewPagerOptions: setNewPagerOptions });
}
