import { REGEX_URL } from 'renderer/constants';
export function loadScript(url, timeout) {
    if (timeout === void 0) { timeout = 5000; }
    if (!REGEX_URL.test(url)) {
        throw new Error('Enter valid URL.');
    }
    var mimeType;
    if (/.js$/.test(url)) {
        mimeType = 'text/javascript';
    }
    else if (/.css$/.test(url)) {
        mimeType = 'text/css';
    }
    else {
        throw new Error('Enter valid URL.');
    }
    return new Promise(function (resolve, reject) {
        var domType = /javascript/.test(mimeType) ? 'script' : 'link';
        var domElement = document.querySelector("".concat(domType, "[src=\"").concat(url, "\"]"));
        if (domElement) {
            return resolve(domElement);
        }
        domElement = document.createElement(domType);
        domElement.type = mimeType;
        if (domElement.readyState) {
            //IE
            domElement.onreadystatechange = function () {
                if (domElement.readyState === 'loaded' || domElement.readyState === 'complete') {
                    domElement.onreadystatechange = null;
                    resolve(domElement);
                }
            };
        }
        else {
            domElement.onload = function () { return resolve(undefined); };
            domElement.onerror = function (ev) { return reject(ev.error); };
        }
        if (domType === 'script') {
            domElement.src = url;
        }
        else {
            domElement.rel = 'stylesheet';
            domElement.media = 'screen';
            domElement.href = url;
        }
        document.getElementsByTagName('head')[0].appendChild(domElement);
        setTimeout(function () { return reject(new Error('Timeout')); }, timeout);
    });
}
