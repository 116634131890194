var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel, StoreSupplierModel, ManagerModel, EXTENDED_SALES_METHODS, EXTENDED_SALES_MEHOD_KOREAN } from 'renderer/models';
import { StoreStockHistoryModel } from './StoreStockHistoryModel';
import { sum } from 'renderer/utils';
import { KRWFomatToString } from 'renderer/components';
var STORE_STOCK_PURCHASE_TYPE;
(function (STORE_STOCK_PURCHASE_TYPE) {
    STORE_STOCK_PURCHASE_TYPE["PURCHASE"] = "PURCHASE";
    STORE_STOCK_PURCHASE_TYPE["PURCHASE_REFUND"] = "PURCHASE_REFUND";
})(STORE_STOCK_PURCHASE_TYPE || (STORE_STOCK_PURCHASE_TYPE = {}));
var StoreStockPurchaseModel = /** @class */ (function (_super) {
    __extends(StoreStockPurchaseModel, _super);
    function StoreStockPurchaseModel(props) {
        var _this = this;
        var _a;
        _this = _super.call(this, props) || this;
        if (props.storeStockHistory) {
            _this.storeStockHistory = (_a = props.storeStockHistory) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                return new StoreStockHistoryModel(item);
            });
        }
        if (props.manager) {
            _this.manager = new ManagerModel(props.manager);
        }
        if (props.storeSupplier) {
            _this.storeSupplier = new StoreSupplierModel(props.storeSupplier);
        }
        return _this;
    }
    Object.defineProperty(StoreStockPurchaseModel.prototype, "methods", {
        get: function () {
            var _this = this;
            var methods = {};
            Object.keys(EXTENDED_SALES_METHODS).map(function (key) {
                var deduction = _this[EXTENDED_SALES_METHODS[key]] || 0;
                if (deduction) {
                    methods[EXTENDED_SALES_METHODS[key]] = {
                        active: true,
                        deduction: deduction
                    };
                }
                else {
                    methods[EXTENDED_SALES_METHODS[key]] = {
                        active: false,
                        deduction: 0
                    };
                }
            });
            return methods;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreStockPurchaseModel.prototype, "totalPrice", {
        get: function () {
            var _this = this;
            return sum(Object.keys(EXTENDED_SALES_METHODS).map(function (key) {
                return _this[EXTENDED_SALES_METHODS[key]] || 0;
            }));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreStockPurchaseModel.prototype, "paidPrice", {
        get: function () {
            return this.totalPrice - this.discount;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreStockPurchaseModel.prototype, "methodsString", {
        get: function () {
            var _this = this;
            return [
                EXTENDED_SALES_METHODS.CARD,
                EXTENDED_SALES_METHODS.TRANSFER,
                EXTENDED_SALES_METHODS.CASH,
                EXTENDED_SALES_METHODS.ETC1,
                EXTENDED_SALES_METHODS.UNPAID
            ]
                .map(function (key) {
                var value = _this[key];
                return value ? "(".concat(EXTENDED_SALES_MEHOD_KOREAN[key], ") ").concat(KRWFomatToString({ value: value })) : '';
            })
                .filter(Boolean)
                .join(' / ');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreStockPurchaseModel.prototype, "deductionString", {
        get: function () {
            return this.discount ? "\uD560\uC778 ".concat(KRWFomatToString({ value: this.discount })) : '';
        },
        enumerable: false,
        configurable: true
    });
    StoreStockPurchaseModel.STORE_STOCK_PURCHASE_TYPE = STORE_STOCK_PURCHASE_TYPE;
    return StoreStockPurchaseModel;
}(BaseModel));
export { StoreStockPurchaseModel };
