import React from 'react';
import { isPlussharpMobileApp } from 'renderer/utils';
export function checkIsIOS() {
    var iOSPlatforms = [/iPhone/i, /iPad/i, /iPod/i];
    return iOSPlatforms.some(function (platform) { return navigator.userAgent.match(platform); });
}
// Hook
export function useWindowSize() {
    var _a = React.useState({
        width: undefined,
        height: undefined,
        device: 'pc',
        isPC: true,
        isIOS: checkIsIOS()
    }), windowSize = _a[0], setWindowSize = _a[1];
    function getDevice(width) {
        switch (true) {
            case width > 1024:
                return isPlussharpMobileApp() ? 'tablet' : 'pc';
            case width <= 1024 && width >= 600:
                return 'tablet';
            case width < 600:
                return 'mobile';
            default:
                return 'pc';
        }
    }
    function handleResize() {
        var innerWidth = window.innerWidth, innerHeight = window.innerHeight;
        var newDevice = getDevice(innerWidth);
        var newIsPC = newDevice === 'pc';
        // Update state only if necessary
        if (windowSize.width !== innerWidth ||
            windowSize.height !== innerHeight ||
            windowSize.device !== newDevice ||
            windowSize.isPC !== newIsPC) {
            setWindowSize({
                width: innerWidth,
                height: innerHeight,
                device: newDevice,
                isPC: newIsPC,
                isIOS: windowSize.isIOS // 이 값은 변하지 않으므로 초기값을 유지합니다.
            });
        }
    }
    React.useEffect(function () {
        var throttledResize = throttle(handleResize, 100); // 100ms 간격으로 Throttling
        window.addEventListener('resize', throttledResize);
        // 최초 실행
        handleResize();
        return function () { return window.removeEventListener('resize', throttledResize); };
    }, [windowSize]); // 의존성 배열에 windowSize를 추가하여 필요할 때만 effect가 실행되도록 합니다.
    function throttle(fn, wait) {
        var lastTime = 0;
        return function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            var now = new Date().getTime();
            if (now - lastTime >= wait) {
                fn.apply(void 0, args);
                lastTime = now;
            }
        };
    }
    return windowSize;
}
// Hook
export function useOnClickOutside(ref, handler) {
    React.useEffect(function () {
        var listener = function (event) {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return function () {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, 
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]);
}
