var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMutation, useQuery } from 'react-query';
import { RestClient } from 'renderer/utils';
import { QUERY_CACHE } from 'renderer/queries';
import notification from 'antd/lib/notification';
import { MessageTemplateModel } from '../models/MessageTemplateModel';
import { usePager } from 'renderer/utils/Pager';
import { StoreModel } from '../models/StoreModel';
import { AutoMessageSettingModel } from '../models/AutoMessageSettingModel';
import { StoreMessageModel } from 'renderer/models/StoreMessage';
var getMessageTemplates = function (options) {
    var storeId = options.queryKey[1];
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/messages/templates"))
        .then(function (result) { return result.data.map(function (item) { return new MessageTemplateModel(item); }); });
};
var getATSTemplates = function (options) {
    var storeId = options.queryKey[1];
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/messages/ats/templates"))
        .then(function (result) { return result.data.map(function (item) { return new MessageTemplateModel(item); }); });
};
export var getRegistrationSenderNumberURL = function (options) {
    var storeId = options.queryKey[1];
    return RestClient.getInstance()
        .post("/stores/".concat(storeId, "/messages/sms/senders"))
        .then(function (result) { return result.data; });
};
export var sendMessages = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/messages"), payload)
        .then(function (result) { return result.data; });
};
var resendMessages = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/messages/re"), payload)
        .then(function (result) { return result.data; });
};
var cancelMessages = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/messages/cancel"), payload)
        .then(function (result) { return result.data; });
};
export var getSMSSenderNumberList = function (options) {
    var storeId = options.queryKey[1];
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/messages/sms/senders"))
        .then(function (result) { return result.data; });
};
export var getMessageSettings = function (options) {
    var storeId = options.queryKey[1];
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/messages/settings"))
        .then(function (result) { return new StoreModel(result.data); });
};
export var getSMSHistory = function (options) {
    var _a = options.queryKey, _ = _a[0], params = _a[1];
    return RestClient.getInstance()
        .get("/stores/".concat(params.storeId, "/messages/sms/list"), { params: params })
        .then(function (result) { return result.data; });
};
export var getMessageHistory = function (options) {
    var _a = options.queryKey, _ = _a[0], params = _a[1];
    return RestClient.getInstance()
        .get("/stores/".concat(params.storeId, "/messages"), { params: params })
        .then(function (result) { return result.data; });
};
export var checkDormancy = function (options) {
    var payload = options.queryKey[1];
    var storeId = payload.storeId, rest = __rest(payload, ["storeId"]);
    return RestClient.getInstance()
        .post("/stores/".concat(storeId, "/messages/dormancy"), rest)
        .then(function (result) { return result.data; });
};
var createMessageTemplates = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/messages/templates"), payload)
        .then(function (result) { return new MessageTemplateModel(result.data); });
};
export var updateMessageTemplates = function (payload) {
    return RestClient.getInstance()
        .put("/stores/".concat(payload.storeId, "/messages/templates/").concat(payload.id), payload)
        .then(function (result) { return new MessageTemplateModel(result.data); });
};
export var deleteMessageTemplates = function (data) {
    return RestClient.getInstance()
        .remove("/stores/".concat(data.storeId, "/messages/templates/").concat(data.id))
        .then(function (result) { return new MessageTemplateModel(result.data); });
};
var mutationConfig = {
    onSuccess: function () {
        // TODO: 필터에 맞춰서 optimise
        QUERY_CACHE.invalidateQueries(['getAutoSettings']);
        QUERY_CACHE.invalidateQueries(['messageTemplates']);
        QUERY_CACHE.invalidateQueries(['messageHistory']);
        // QUERY_CACHE.invalidateQueries(['smsHistory']);
    },
    onError: function (error) {
        notification.error({ message: error.message });
    }
};
export var useMessageTemplates = function (storeId, config) {
    return useQuery(['messageTemplates', storeId], getMessageTemplates, config);
};
export var useRegistrationSenderNumberURL = function (storeId, config) {
    return useQuery(['registrationNumberURL', storeId], getRegistrationSenderNumberURL, config);
};
export var useGetSMSSenderNumberList = function (storeId, config) {
    return useQuery(['getSMSSenderNumberList', storeId], getSMSSenderNumberList, __assign(__assign({}, config), { retry: 0 }));
};
export var useGetMessageSettings = function (storeId, config) {
    return useQuery(['getMessageSettings', storeId], getMessageSettings, __assign(__assign({}, config), { retry: 1, refetchOnWindowFocus: true }));
};
export var useCreateMessageTemplateMutation = function (config) {
    return useMutation(createMessageTemplates, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateMessageTemplateMutation = function (config) {
    return useMutation(updateMessageTemplates, __assign(__assign({}, mutationConfig), config));
};
export var useDeleteMessageTemplateMutation = function (config) {
    return useMutation(deleteMessageTemplates, __assign(__assign({}, mutationConfig), config));
};
export var useSMSHistoryPager = function (query, config) {
    return usePager('smsHistory', query, getSMSHistory, undefined, config);
};
export var useMesageHistoryPager = function (query, config) {
    return usePager('messageHistory', query, getMessageHistory, StoreMessageModel, config);
};
export var useSendMessage = function (config) {
    return useMutation(sendMessages, __assign(__assign({}, mutationConfig), config));
};
export var useReSendMessage = function (config) {
    return useMutation(resendMessages, __assign(__assign({}, mutationConfig), config));
};
export var useCancelMessage = function (config) {
    return useMutation(cancelMessages, __assign(__assign({}, mutationConfig), config));
};
export var useATSTemplates = function (storeId, config) {
    return useQuery(['aTSTemplates', storeId], getATSTemplates, config);
};
var createAutoMessageSetting = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/messages/auto"), payload)
        .then(function (result) { return new AutoMessageSettingModel(result.data); });
};
var updateAutoMessageSetting = function (payload) {
    return RestClient.getInstance()
        .put("/stores/".concat(payload.storeId, "/messages/auto"), payload)
        .then(function (result) { return new AutoMessageSettingModel(result.data); });
};
var deleteAutoMessageSetting = function (payload) {
    return RestClient.getInstance()
        .remove("/stores/".concat(payload.storeId, "/messages/auto/").concat(payload.id))
        .then(function (result) { return result.data; });
};
var getAutoSettings = function (options) {
    var storeId = options.queryKey[1];
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/messages/auto"))
        .then(function (result) { return result.data.map(function (item) { return new AutoMessageSettingModel(item); }); });
};
export var useGetAutoSettings = function (storeId, config) {
    return useQuery(['getAutoSettings', storeId], getAutoSettings, config);
};
export var useCreateAutoMessageSetting = function (config) {
    return useMutation(createAutoMessageSetting, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateAutoMessageSetting = function (config) {
    return useMutation(updateAutoMessageSetting, __assign(__assign({}, mutationConfig), config));
};
export var useDeleteAutoMessageSetting = function (config) {
    return useMutation(deleteAutoMessageSetting, __assign(__assign({}, mutationConfig), config));
};
