/* eslint-disable no-unused-vars */
export var AUDIO_NAME;
(function (AUDIO_NAME) {
    AUDIO_NAME["TICKET_CONFIRM_FAILURE"] = "ticket_confirm_failure";
    AUDIO_NAME["TICKET_CONFIRM_SUCCESS"] = "ticket_confirm_success";
    AUDIO_NAME["TICKET_SUBMIT_PRICE"] = "ticket_submit_price";
})(AUDIO_NAME || (AUDIO_NAME = {}));
var AUDIO_LIST = [
    {
        name: AUDIO_NAME.TICKET_SUBMIT_PRICE,
        src: 'https://blockdeal-static-resources.s3.ap-northeast-2.amazonaws.com/sound/submit_price.mp3'
    },
    {
        name: AUDIO_NAME.TICKET_CONFIRM_FAILURE,
        src: 'https://blockdeal-static-resources.s3.ap-northeast-2.amazonaws.com/sound/ticket_confirm_failure.mp3'
    },
    {
        name: AUDIO_NAME.TICKET_CONFIRM_SUCCESS,
        src: 'https://blockdeal-static-resources.s3.ap-northeast-2.amazonaws.com/sound/ticket_confirm_success.mp3'
    }
];
var SYMBOL_INSTANCE = Symbol('singleton');
var AudioClient = /** @class */ (function () {
    function AudioClient() {
        this.soundMap = new Map();
    }
    AudioClient.prototype.init = function () {
        var _this = this;
        AUDIO_LIST.map(function (audioData) {
            var target = new Audio(audioData.src);
            _this.soundMap.set(audioData.name, target);
        });
    };
    AudioClient.prototype.play = function (name) {
        var audio = this.soundMap.get(name);
        audio.play();
    };
    AudioClient.prototype.pause = function (name) {
        var audio = this.soundMap.get(name);
        audio.pause();
    };
    AudioClient.getInstance = function () {
        var instanceDescriptor = Object.getOwnPropertyDescriptor(this, SYMBOL_INSTANCE);
        var instance = instanceDescriptor ? instanceDescriptor.value : new this();
        if (!instanceDescriptor) {
            Object.defineProperty(this, SYMBOL_INSTANCE, { value: instance });
        }
        return instance;
    };
    return AudioClient;
}());
export { AudioClient };
