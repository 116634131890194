import React from 'react';
export function useInterval(callback, delay) {
    var savedCallback = React.useRef();
    // Remember the latest callback.
    React.useEffect(function () {
        savedCallback.current = callback;
    }, [callback]);
    // Set up the interval.
    React.useEffect(function () {
        function tick() {
            savedCallback && savedCallback.current && savedCallback.current();
        }
        if (delay !== null) {
            var id_1 = setInterval(tick, delay);
            return function () { return clearInterval(id_1); };
        }
    }, [delay]);
}
