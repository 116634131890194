import React from 'react';
import { ManagerQuery, StoreQuery } from 'renderer/queries';
// Hook
// https://usehooks.com/useLocalStorage/
export var useLocalStorage = function (key, initialValue, privateData) {
    key = getLocalStorageKey(key, privateData);
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    var _a = React.useState(function () {
        try {
            // Get from local storage by key
            var item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? (['true', 'false'].includes(item) ? item === 'true' : JSON.parse(item)) : initialValue;
        }
        catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    }), storedValue = _a[0], setStoredValue = _a[1];
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    var setValue = function (value) {
        try {
            // Allow value to be a function so we have same API as useState
            var valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
        catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };
    return [storedValue, setValue];
};
var getLocalStorageKey = function (key, privateData) {
    var _a, _b;
    var result = key;
    if (privateData === null || privateData === void 0 ? void 0 : privateData.store) {
        var store = StoreQuery.getMyStoreInCache();
        result = (store === null || store === void 0 ? void 0 : store.id) ? "".concat(result, "_storeId=").concat(store.id) : result;
    }
    // TODO: 직원도 해야함 현재 쿼리 안만들어 놓음
    if (privateData === null || privateData === void 0 ? void 0 : privateData.manager) {
        var manager = ManagerQuery.getMeWithoutStoreIdInCache();
        result = ((_a = manager === null || manager === void 0 ? void 0 : manager.manager) === null || _a === void 0 ? void 0 : _a.id) ? "".concat(result, "_managerId=").concat((_b = manager === null || manager === void 0 ? void 0 : manager.manager) === null || _b === void 0 ? void 0 : _b.id) : result;
    }
    return result;
};
