import { LOCAL_STORAGE_DEVICE_UNIQUE } from 'renderer/constants';
import { createNanoId } from './nanoid';
function stringHasJsonStructure(str) {
    if (typeof str !== 'string')
        return false;
    try {
        var result = JSON.parse(str);
        var type = Object.prototype.toString.call(result);
        return type === '[object Object]' || type === '[object Array]';
    }
    catch (err) {
        return false;
    }
}
function convertToString(value) {
    return typeof value === 'object' ? JSON.stringify(value) : value;
}
function convertStoredValue(value) {
    var isNullOrUndefined = value === 'null' || value === 'undefined';
    return isNullOrUndefined
        ? value === 'null'
            ? null
            : undefined
        : stringHasJsonStructure(value)
            ? JSON.stringify(value)
            : value;
}
export var LocalStorage = {
    set: function (key, value) {
        if (value) {
            localStorage.setItem(key, convertToString(value));
        }
    },
    get: function (key) {
        return convertStoredValue(localStorage.getItem(key));
    },
    getDeviceUnique: function () {
        var deviceUnique = localStorage.getItem(LOCAL_STORAGE_DEVICE_UNIQUE);
        var deviceUnique1 = localStorage.getItem(undefined);
        if (!deviceUnique) {
            if (deviceUnique1) {
                deviceUnique = deviceUnique1;
            }
            else {
                deviceUnique = "".concat(createNanoId(), "_").concat(Date.now());
            }
            localStorage.setItem(LOCAL_STORAGE_DEVICE_UNIQUE, deviceUnique);
        }
        return deviceUnique;
    },
    removeItem: function (key) {
        localStorage.removeItem(key);
    }
};
export var SessionStorage = {
    set: function (key, value) {
        if (value) {
            sessionStorage.setItem(key, convertToString(value));
        }
    },
    get: function (key) {
        return convertStoredValue(sessionStorage.getItem(key));
    },
    removeItem: function (key) {
        sessionStorage.removeItem(key);
    }
};
export function setWithExpiry(key, value, ttl) {
    var item = {
        value: value,
        expiry: new Date().getTime() + ttl
    };
    localStorage.setItem(key, JSON.stringify(item));
}
export function getWithExpiry(key) {
    var itemString = window.localStorage.getItem(key);
    if (!itemString)
        return null;
    var item = JSON.parse(itemString);
    var isExpired = new Date().getTime() > item.expiry;
    if (isExpired) {
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
}
