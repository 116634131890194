var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMutation, useQuery } from 'react-query';
import { RestClient, usePager } from 'renderer/utils';
import { StoreLedgerAccountModel, StoreLedgerModel } from 'renderer/models';
import { QUERY_CACHE } from 'renderer/queries';
import notification from 'antd/lib/notification';
var mutationConfig = {
    onSuccess: function () {
        QUERY_CACHE.invalidateQueries('storeLedger');
        QUERY_CACHE.invalidateQueries('storeLedgerTotal');
        QUERY_CACHE.invalidateQueries('storeLedgerAccount');
    },
    onError: function (error) {
        notification.error({ message: error.message });
    }
};
export var useGetStoreLedgerStatistics = function (query, config) {
    return useQuery(['storeLedgerTotal', query.storeId], function () {
        return RestClient.getInstance()
            .get("/stores/".concat(query.storeId, "/ledger/statistics"), { params: query })
            .then(function (result) { return result.data; });
    }, __assign({ staleTime: 60000 }, config));
};
export var useGetStoreLedgerPager = function (query, config) {
    return usePager('storeLedger', query, function (query) {
        var _a = query.queryKey[1], storeId = _a.storeId, rest = __rest(_a, ["storeId"]);
        return RestClient.getInstance()
            .get("/stores/".concat(storeId, "/ledger"), { params: rest })
            .then(function (result) { return result.data; });
    }, StoreLedgerModel, __assign({}, config));
};
export var useCreateStoreLedgerMutation = function (config) {
    return useMutation(function (payload) {
        return RestClient.getInstance()
            .post("/stores/".concat(payload.storeId, "/ledger"), payload)
            .then(function (result) { return new StoreLedgerModel(result.data); });
    }, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateStoreLedgerMutation = function (config) {
    return useMutation(function (payload) {
        console.log('payload', payload);
        return RestClient.getInstance()
            .put("/stores/".concat(payload.storeId, "/ledger/").concat(payload.id), payload)
            .then(function (result) { return new StoreLedgerModel(result.data); });
    }, __assign(__assign({}, mutationConfig), config));
};
export var useDeleteStoreLedgerMutation = function (config) {
    return useMutation(function (payload) {
        console.log('payload', payload);
        return RestClient.getInstance()
            .remove("/stores/".concat(payload.storeId, "/ledger/").concat(payload.id))
            .then(function (result) { return new StoreLedgerModel(result.data); });
    }, __assign(__assign({}, mutationConfig), config));
};
export var useGetAllStoreLedgerAccount = function (query, config) {
    return useQuery(['storeLedgerAccount', query.storeId], function () {
        return RestClient.getInstance()
            .get("/stores/".concat(query.storeId, "/ledger/accounts"))
            .then(function (result) { return result.data; });
    }, __assign({ staleTime: 60000 }, config));
};
export var useCreateStoreLedgerAccountMutation = function (config) {
    return useMutation(function (payload) {
        return RestClient.getInstance()
            .post("/stores/".concat(payload.storeId, "/ledger/accounts"), payload)
            .then(function (result) { return new StoreLedgerAccountModel(result.data); });
    }, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateStoreLedgerAccountMutation = function (config) {
    return useMutation(function (payload) {
        return RestClient.getInstance()
            .put("/stores/".concat(payload.storeId, "/ledger/accounts/").concat(payload.id), payload)
            .then(function (result) { return new StoreLedgerAccountModel(result.data); });
    }, __assign(__assign({}, mutationConfig), config));
};
export var useDeleteStoreLedgerAccountMutation = function (config) {
    return useMutation(function (payload) {
        return RestClient.getInstance()
            .remove("/stores/".concat(payload.storeId, "/ledger/accounts/").concat(payload.id))
            .then(function (result) { return new StoreLedgerAccountModel(result.data); });
    }, __assign(__assign({}, mutationConfig), config));
};
