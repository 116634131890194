var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import { isPlussharpMobileApp } from './application';
import { getFirestore, collection, query, getDocs, doc, getDoc, setDoc } from 'firebase/firestore';
isSupported()
    .then(function (value) {
    if (!isPlussharpMobileApp() && value) {
        try {
            var firebaseMessaging = getMessaging(app);
            onMessage(firebaseMessaging, function (ev) {
                var _a = ev.data, title = _a.title, body = _a.body, icon = _a.icon, action = _a.action, url = _a.url;
                var options = {
                    body: body,
                    icon: icon
                };
                var notification = new Notification(title, options);
                notification.addEventListener('click', function () {
                    if (action === 'navigate' && url) {
                        window.location.href = url;
                    }
                });
            });
        }
        catch (err) { }
    }
})
    .catch(function (e) { });
var firebaseConfig = {
    apiKey: 'AIzaSyACIhuoWmjaZNZpQaV3gHucSLJBRKymusk',
    authDomain: 'plussharpcrm.firebaseapp.com',
    projectId: 'plussharpcrm',
    storageBucket: 'plussharpcrm.appspot.com',
    messagingSenderId: '245179184061',
    appId: '1:245179184061:web:82a36b0d68c10e0ca6d8d4',
    measurementId: 'G-VXW108EQ0P'
};
var app = initializeApp(firebaseConfig);
var firestore = getFirestore(app);
var getQuery = function (collectionString, queries) {
    return query.apply(void 0, __spreadArray([collection(firestore, collectionString)], queries, false));
};
var getDocuments = function (collectionString, queries) {
    return getDocs(getQuery(collectionString, queries));
};
var getDocumentReference = function (collectionString, id) {
    return doc(collection(firestore, collectionString), id);
};
var getDocument = function (collectionString, id) {
    return getDoc(getDocumentReference(collectionString, id));
};
var setDocument = function (document, data) {
    return setDoc(document, data);
};
var getFCMToken = function () { return __awaiter(void 0, void 0, void 0, function () {
    var isSupport, firebaseMessaging;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, isSupported()];
            case 1:
                isSupport = _a.sent();
                if (isSupport) {
                    firebaseMessaging = getMessaging(app);
                    return [2 /*return*/, getToken(firebaseMessaging)];
                }
                else {
                    throw new Error('This browser not support FCM');
                }
                return [2 /*return*/];
        }
    });
}); };
export { firestore, getQuery, getDocuments, getDocument, getDocumentReference, setDocument, getFCMToken };
