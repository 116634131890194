import { useState } from 'react';
export function useButtonLock(duration) {
    if (duration === void 0) { duration = 3000; }
    var _a = useState(false), isLocked = _a[0], setLocked = _a[1];
    var lockButton = function () {
        setLocked(true);
        setTimeout(function () { return setLocked(false); }, duration);
    };
    return [isLocked, lockButton];
}
