var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable no-unused-vars */
import { KRWFomatToString } from 'renderer/components';
import { BaseModel, TicketModel, ManagerModel } from 'renderer/models';
import { CustomerModel } from './CustomerModel';
import { INVOICE_STATUS_STRING } from './InvoiceModel';
import { isEmpty, sum } from 'renderer/utils';
import * as dayjs from 'dayjs';
import { UsingHistoryModel } from './UsingHistoryModel';
import { CustomerPointModel } from './CustomerPointModel';
export var PAYMENT_LOCATION;
(function (PAYMENT_LOCATION) {
    PAYMENT_LOCATION["OFFLINE"] = "offline";
    PAYMENT_LOCATION["APP"] = "app";
})(PAYMENT_LOCATION || (PAYMENT_LOCATION = {}));
export var PAYMENT_STATUS;
(function (PAYMENT_STATUS) {
    // 미납
    PAYMENT_STATUS["UNPAID"] = "unpaid";
    // 완료
    PAYMENT_STATUS["COMPLETED"] = "completed";
    // 결제완료되었으나 제품 생성 안됨 (공동구매)
    PAYMENT_STATUS["COMPLETED_BUT_NOT_CREATED_TICKET"] = "cbnct";
    // 환불
    PAYMENT_STATUS["CANCELLED"] = "cancelled";
    // 결제 실패
    PAYMENT_STATUS["FAILURE"] = "failure";
    // 결제 대기
    PAYMENT_STATUS["WAITING"] = "waiting";
})(PAYMENT_STATUS || (PAYMENT_STATUS = {}));
export var PAYMENT_REMITTANCE_STATUS;
(function (PAYMENT_REMITTANCE_STATUS) {
    // 입금지연
    PAYMENT_REMITTANCE_STATUS["PENDING"] = "pending";
    // 미납
    PAYMENT_REMITTANCE_STATUS["UNPAID"] = "unpaid";
    // 완료
    PAYMENT_REMITTANCE_STATUS["COMPLETED"] = "completed";
    // 취소
    PAYMENT_REMITTANCE_STATUS["CANCELLED"] = "cancelled";
    // 정산 예정
    PAYMENT_REMITTANCE_STATUS["SCHEDULED"] = "scheduled";
})(PAYMENT_REMITTANCE_STATUS || (PAYMENT_REMITTANCE_STATUS = {}));
export var PAYMENT_MEHOD;
(function (PAYMENT_MEHOD) {
    PAYMENT_MEHOD["CASH"] = "cash";
    PAYMENT_MEHOD["CARD"] = "card";
    PAYMENT_MEHOD["TRANSFER"] = "transfer";
})(PAYMENT_MEHOD || (PAYMENT_MEHOD = {}));
export var PAYMENT_MEHOD_KOREAN;
(function (PAYMENT_MEHOD_KOREAN) {
    PAYMENT_MEHOD_KOREAN["cash"] = "\uD604\uAE08";
    PAYMENT_MEHOD_KOREAN["card"] = "\uCE74\uB4DC";
    PAYMENT_MEHOD_KOREAN["transfer"] = "\uACC4\uC88C\uC774\uCCB4";
})(PAYMENT_MEHOD_KOREAN || (PAYMENT_MEHOD_KOREAN = {}));
var PaymentHistoryModel = /** @class */ (function (_super) {
    __extends(PaymentHistoryModel, _super);
    function PaymentHistoryModel(props) {
        var _this = _super.call(this, props) || this;
        _this.tickets = props.tickets && props.tickets.map(function (ticket) { return new TicketModel(ticket); });
        _this.usingHistory = props.usingHistory && props.usingHistory.map(function (history) { return new UsingHistoryModel(history); });
        _this.customer = props.customer && new CustomerModel(props.customer);
        // this.product = props.product && new StoreProductModel(props.product);
        _this.manager = props.manager && new ManagerModel(props.manager);
        return _this;
    }
    Object.defineProperty(PaymentHistoryModel.prototype, "remittanceStatusString", {
        get: function () {
            var _a;
            var key = (_a = this.invoiceData) === null || _a === void 0 ? void 0 : _a.remittanceStatus;
            return INVOICE_STATUS_STRING[key];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PaymentHistoryModel.prototype, "ticket", {
        get: function () {
            return this.tickets && this.tickets[0];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PaymentHistoryModel.prototype, "locationToString", {
        get: function () {
            switch (this.meta && this.meta.location) {
                case PAYMENT_LOCATION.APP:
                    return "\uC571";
                case PAYMENT_LOCATION.OFFLINE:
                    return "\uC624\uD504\uB77C\uC778";
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PaymentHistoryModel.prototype, "isSellInApp", {
        get: function () {
            var location = this.meta && this.meta.location;
            return location === PAYMENT_LOCATION.APP;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PaymentHistoryModel.prototype, "statusToString", {
        get: function () {
            switch (this.status) {
                case PAYMENT_STATUS.UNPAID:
                    return "\uBBF8\uB0A9";
                case PAYMENT_STATUS.COMPLETED:
                    return "\uC644\uB8CC";
                case PAYMENT_STATUS.COMPLETED_BUT_NOT_CREATED_TICKET:
                    return "\uACF5\uB3D9\uAD6C\uB9E4\uACB0\uC81C";
                case PAYMENT_STATUS.CANCELLED:
                    return "\uD658\uBD88";
                case PAYMENT_STATUS.FAILURE:
                    return "\uC2E4\uD328";
                case PAYMENT_STATUS.WAITING:
                    return "\uB300\uAE30";
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PaymentHistoryModel.prototype, "remittanceStatusToString", {
        get: function () {
            switch (this.invoiceData && this.invoiceData.remittanceStatus) {
                case PAYMENT_REMITTANCE_STATUS.UNPAID:
                    return '입금지연';
                case PAYMENT_REMITTANCE_STATUS.PENDING:
                    return '정산중';
                case PAYMENT_REMITTANCE_STATUS.SCHEDULED:
                    return '입금예정';
                case PAYMENT_REMITTANCE_STATUS.COMPLETED:
                    return '입금완료';
                case PAYMENT_REMITTANCE_STATUS.CANCELLED:
                    return '입금취소';
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PaymentHistoryModel.prototype, "saleListString", {
        get: function () {
            return !isEmpty(this.tickets)
                ? this.tickets.map(function (ticket) { return "[".concat(ticket.copiedTicketTemplate.getTypeToString(), "]").concat(ticket.name); }).join(' / ')
                : this.usingHistory &&
                    this.usingHistory
                        .map(function (history) {
                        var _a, _b;
                        return history.amount > 1
                            ? "".concat((_a = history.product) === null || _a === void 0 ? void 0 : _a.nameWithGroupName, "x").concat(history.amount)
                            : (_b = history.product) === null || _b === void 0 ? void 0 : _b.nameWithGroupName;
                    })
                        .join('/');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PaymentHistoryModel.prototype, "methodsString", {
        get: function () {
            var _this = this;
            var unpaid = this.unpaidPrice;
            var base = unpaid ? ["(\uBBF8\uC218\uAE08) ".concat(KRWFomatToString({ value: unpaid }))] : [];
            return base
                .concat(this.methods &&
                Object.keys(this.methods).map(function (key) { return "(".concat(PAYMENT_MEHOD_KOREAN[key], ") ").concat(KRWFomatToString({ value: _this.methods[key] })); }))
                .join(' / ');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PaymentHistoryModel.prototype, "deductionString", {
        get: function () {
            var deductionPriceByTicket = this.deductionPriceByTicket;
            var base = deductionPriceByTicket ? ["(\uC815\uAE30\uAD8C) ".concat(KRWFomatToString({ value: deductionPriceByTicket }))] : [];
            var usedPrepaidPoint = this.usedPrepaidPoint;
            if (usedPrepaidPoint) {
                base.push("(\uC120\uBD88\uC561) ".concat(KRWFomatToString({ value: usedPrepaidPoint })));
            }
            var usedCustomerPoint = this.usedCustomerPoint;
            if (usedCustomerPoint) {
                base.push("(\uD3EC\uC778\uD2B8) ".concat(KRWFomatToString({ value: usedCustomerPoint })));
            }
            return base.join(' / ');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PaymentHistoryModel.prototype, "deductionPriceByTicket", {
        get: function () {
            var _a;
            return sum((_a = this.usingHistory) === null || _a === void 0 ? void 0 : _a.map(function (using) { return using.deductionPriceByTicket; })) || 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PaymentHistoryModel.prototype, "usingTotalPrice", {
        get: function () {
            var _a;
            return this.ticket ? this.totalPrice : sum((_a = this.usingHistory) === null || _a === void 0 ? void 0 : _a.map(function (using) { return using.price; }));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PaymentHistoryModel.prototype, "managerNames", {
        get: function () {
            var _a, _b;
            return !isEmpty(this.usingHistory)
                ? (_a = this.usingHistory) === null || _a === void 0 ? void 0 : _a.map(function (history) { var _a; return ((_a = history.manager) === null || _a === void 0 ? void 0 : _a.displayName) || '-'; }).join('/')
                : ((_b = this.manager) === null || _b === void 0 ? void 0 : _b.displayName) || '-';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PaymentHistoryModel.prototype, "paymentHistoryForm", {
        get: function () {
            var _this = this;
            var _a;
            var ticket = this.ticket;
            var methods = {};
            Object.keys(this.methods || {}).map(function (key) {
                var methodsValue = _this.methods[key];
                if (methodsValue) {
                    methods[key] = { checked: true, value: methodsValue };
                }
            });
            if (this.discount > 0) {
                methods.discount = { checked: true, value: this.discount };
            }
            if (this.unpaidPrice && methods) {
                methods['unpaid'] = { checked: true, value: this.unpaidPrice };
            }
            return __assign(__assign({}, this), { finalPrice: this.totalPrice, customer: {
                    type: 'customer',
                    id: this.customerId
                }, 
                // 정기권 구매 시
                ticketTemplateId: ticket
                    ? [ticket.copiedTicketTemplate.type, ticket.copiedTicketTemplate.id]
                    : undefined, ticket: ticket
                    ? __assign(__assign({}, ticket), { expiredAt: dayjs(ticket.expiredAt), data: ticket.data.startedAt
                            ? __assign(__assign({}, ticket.data), { startedAt: dayjs(ticket.data.startedAt), expiredAt: dayjs(ticket.data.expiredAt) }) : __assign({}, ticket.data) }) : undefined, 
                // 상품 구매 시
                usingHistory: ((_a = this.usingHistory) === null || _a === void 0 ? void 0 : _a.map(function (history) {
                    return __assign(__assign(__assign({}, history), history.product), { totalPrice: history.originalPaidPrice, adjustPrice: history.adjustPrice, price: history.price, amount: history.amount });
                })) || [], methods: methods, paidAt: dayjs(this.paidAt) });
        },
        enumerable: false,
        configurable: true
    });
    PaymentHistoryModel.prototype.isManagerPayment = function (managerId) {
        var _a;
        return !!((_a = this.salesPerManager) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.managerId === managerId; }));
    };
    PaymentHistoryModel.prototype.getPaidAtStringByInterval = function (interval) {
        if (interval === 'year') {
            return dayjs(this.paidAt).startOf('year').format('YYYYMMDD');
        }
        if (interval === 'month') {
            return dayjs(this.paidAt).startOf('month').format('YYYYMMDD');
        }
        if (interval === 'day') {
            return dayjs(this.paidAt).format('YYYYMMDD');
        }
    };
    PaymentHistoryModel.prototype.getPointRemainAfterUsing = function (type) {
        var remain = 0;
        var items = type === 'prepaid' ? this.prepaidPoints : this.customerPoints;
        if (!isEmpty(items)) {
            var used = items.find(function (item) { return item.type === CustomerPointModel.POINT_TYPE.USED; });
            if (used) {
                remain = used.latestRemain;
            }
        }
        return remain;
    };
    PaymentHistoryModel.prototype.getPointRemainBeforeUsing = function (type) {
        var remain = 0;
        var items = type === 'prepaid' ? this.prepaidPoints : this.customerPoints;
        if (!isEmpty(items)) {
            var used = items.find(function (item) { return item.type === CustomerPointModel.POINT_TYPE.USED; });
            if (used) {
                remain = used.latestRemain + (used.change || 0);
            }
        }
        return remain;
    };
    PaymentHistoryModel.PAYMENT_STATUS = PAYMENT_STATUS;
    PaymentHistoryModel.PAYMENT_MEHOD = PAYMENT_MEHOD;
    return PaymentHistoryModel;
}(BaseModel));
export { PaymentHistoryModel };
