var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation, useQuery } from 'react-query';
import { floor, RestClient, sum } from 'renderer/utils';
import { CustomerGroupModel } from 'renderer/models';
import { QUERY_CACHE } from 'renderer/queries';
import notification from 'antd/lib/notification';
var getCustomerGroupsByStoreId = function (options) {
    var storeId = options.queryKey[1];
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/customers/groups"))
        .then(function (result) { return result.data.map(function (item) { return new CustomerGroupModel(item); }); });
};
var createCustomerGroups = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/customers/groups"), payload)
        .then(function (result) { return new CustomerGroupModel(result.data); });
};
export var updateCustomerGroups = function (data) {
    return RestClient.getInstance()
        .put("/stores/".concat(data.storeId, "/customers/groups"), data.payload)
        .then(function (result) { return new CustomerGroupModel(result.data); });
};
export var deleteCustomerGroups = function (data) {
    return RestClient.getInstance()
        .remove("/stores/".concat(data.storeId, "/customers/groups/").concat(data.id))
        .then(function (result) { return new CustomerGroupModel(result.data); });
};
var mutationConfig = {
    onSuccess: function () {
        // TODO: 필터에 맞춰서 optimise
        QUERY_CACHE.invalidateQueries(['customerGroups']);
        QUERY_CACHE.invalidateQueries(['customers']);
    },
    onError: function (error) {
        notification.error({ message: error.message });
    }
};
export var useCustomerGroups = function (storeId, config) {
    return useQuery(['customerGroups', storeId], getCustomerGroupsByStoreId, __assign(__assign({}, config), { staleTime: 60000 }));
};
export var useCreateCustomerGroupMutation = function (config) {
    return useMutation(createCustomerGroups, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateCustomerGroupMutation = function (config) {
    return useMutation(updateCustomerGroups, __assign(__assign({}, mutationConfig), config));
};
export var useDeleteCustomerGroupMutation = function (config) {
    return useMutation(deleteCustomerGroups, __assign(__assign({}, mutationConfig), config));
};
export var getCustomerGroupById = function (id, storeId) {
    var data = QUERY_CACHE.getQueryData(['customerGroups', storeId]);
    return data.find(function (item) { return item.id === id; }) || data.find(function (item) { return item.priority === 0; });
};
export var calculateWillCreateCustomerPoint = function (methods, customerGroupId, storeId) {
    if (!methods || !customerGroupId || !storeId) {
        return 0;
    }
    var group = getCustomerGroupById(customerGroupId, storeId);
    if (!group) {
        return 0;
    }
    var result = floor(sum(Object.keys(methods).map(function (key) {
        var data = methods[key];
        if (!data) {
            return 0;
        }
        if (data.active && data.deduction) {
            return (data.deduction * ((group === null || group === void 0 ? void 0 : group.pointRate[key]) || 0)) / 100;
        }
        return 0;
    })), 0);
    return result;
};
