var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { floor, isEmpty } from 'renderer/utils';
import { KRWFomatToString } from 'renderer/components/Intl';
import { BaseModel } from './BaseModel';
import { StoreProductGroupModel } from './StoreProductGroupModel';
import { convertMinutesToYYYYMMDDHHmm } from 'renderer/utils/dayjs';
import { TicketTemplateModel } from './TicketTemplateModel';
var StoreProductModel = /** @class */ (function (_super) {
    __extends(StoreProductModel, _super);
    function StoreProductModel(props) {
        var _this = _super.call(this, props) || this;
        _this.ticketTemplates = props.ticketTemplates && props.ticketTemplates.map(function (item) { return new TicketTemplateModel(item); });
        if (props.productGroup) {
            var _a = _this.productGroup, products = _a.products, rest = __rest(_a, ["products"]);
            _this.productGroup = new StoreProductGroupModel(rest);
        }
        return _this;
    }
    Object.defineProperty(StoreProductModel.prototype, "timeString", {
        get: function () {
            var hours = floor(this.time / 60, 0) === 0 ? '' : "".concat(floor(this.time / 60, 0), "\uC2DC\uAC04 ");
            var minutes = "".concat(this.time % 60, "\uBD84");
            return "".concat(hours).concat(minutes);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreProductModel.prototype, "timeYYYYMMDDHHmm", {
        get: function () {
            return convertMinutesToYYYYMMDDHHmm(this.time);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreProductModel.prototype, "priceString", {
        get: function () {
            return KRWFomatToString({ value: this.price });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreProductModel.prototype, "nameWithGroupName", {
        get: function () {
            return "(".concat(this.productGroup ? this.productGroup.name : '-', ") ").concat(this.name);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreProductModel.prototype, "hasTemplate", {
        get: function () {
            return !isEmpty(this.ticketTemplates);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreProductModel.prototype, "hasActiveTicket", {
        get: function () {
            var _a;
            return !!((_a = this.ticketTemplates) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.tickets && item.tickets[0]; }));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreProductModel.prototype, "priceWithTime", {
        get: function () {
            return "".concat(this.timeString, " / ").concat(this.priceString);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreProductModel.prototype, "isInside", {
        get: function () {
            return this.demandType === 'inside';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreProductModel.prototype, "isNotInside", {
        get: function () {
            return !this.demandType || this.demandType === 'sale';
        },
        enumerable: false,
        configurable: true
    });
    return StoreProductModel;
}(BaseModel));
export { StoreProductModel };
