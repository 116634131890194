export var convertHexToRgbA = function (hex, opacity) {
    if (opacity === void 0) { opacity = 1; }
    var c;
    if (/^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3 || c.length === 4) {
            c = c.length === 3 ? [c[0], c[0], c[1], c[1], c[2], c[2]] : [c[0], c[0], c[1], c[1], c[2], c[2], c[3], c[3]];
        }
        else if (c.length === 8) {
            // 8자리 HEX 코드 처리
            // 마지막 2자리(알파 값)를 opacity로 변환
            opacity = parseInt(c.splice(6, 2).join(''), 16) / 255;
        }
        c = '0x' + c.join('');
        return "rgba(".concat([(c >> 16) & 255, (c >> 8) & 255, c & 255].join(','), ",").concat(opacity, ")");
    }
    else {
        return hex;
    }
};
