var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable no-unused-vars */
import { BaseModel, CustomerModel } from 'renderer/models';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'renderer/constants';
import { FormattedNumber } from 'renderer/components/Intl';
import { TICKET_USED_TYPE, TicketHistoryModel } from './TicketHistoryModel';
import { KRWFomatToString } from 'renderer/components';
import { SalesPerManagerModel } from './SalesPerManagerModel';
import { TICKET_TYPE, TicketTemplateModel } from './TicketTemplateModel';
export var TICKET_STATUS;
(function (TICKET_STATUS) {
    TICKET_STATUS["EXPIRED"] = "expired";
    TICKET_STATUS["COMPLETE"] = "complete";
    TICKET_STATUS["ASSIGNMENT"] = "assignment";
    // 3개는 점주가 바꿀 수 있음
    TICKET_STATUS["ACTIVE"] = "active";
    TICKET_STATUS["CANCELLED"] = "cancelled";
    TICKET_STATUS["STOP"] = "stop";
})(TICKET_STATUS || (TICKET_STATUS = {}));
var TicketModel = /** @class */ (function (_super) {
    __extends(TicketModel, _super);
    function TicketModel(props) {
        var _this = _super.call(this, props) || this;
        _this.customer = props.customer && new CustomerModel(props.customer);
        _this.copiedTicketTemplate = props.copiedTicketTemplate && new TicketTemplateModel(props.copiedTicketTemplate);
        _this.ticketHistories =
            props.ticketHistories && props.ticketHistories.map(function (history) { return new TicketHistoryModel(history); });
        _this.salesPerManagers =
            props.salesPerManagers && props.salesPerManagers.map(function (history) { return new SalesPerManagerModel(history); });
        return _this;
    }
    Object.defineProperty(TicketModel.prototype, "isTicket", {
        get: function () {
            return true;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TicketModel.prototype, "isExpired", {
        get: function () {
            return dayjs().isAfter(this.expiredAt);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TicketModel.prototype, "isAvailable", {
        get: function () {
            return !this.isExpired && this.status === TICKET_STATUS.ACTIVE;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TicketModel.prototype, "error", {
        get: function () {
            if (this.isExpired) {
                return "\uC0AC\uC6A9\uAE30\uAC04\uC774 \uB9CC\uB8CC\uB41C \uC815\uAE30\uAD8C\uC785\uB2C8\uB2E4.";
            }
            if (this.type === TICKET_TYPE.TERM) {
                if (dayjs().isAfter(this.data.expiredAt, 'day')) {
                    return "\uB9CC\uB8CC\uB41C \uAE30\uAC04\uAD8C \uC815\uAE30\uAD8C\uC785\uB2C8\uB2E4.";
                }
                else if (dayjs().isBefore(this.data.startedAt, 'day')) {
                    return "\uC544\uC9C1 \uC0AC\uC6A9\uD560 \uC218 \uC5C6\uB294 \uC815\uAE30\uAD8C\uC785\uB2C8\uB2E4.";
                }
            }
            switch (this.status) {
                case TICKET_STATUS.EXPIRED:
                    return "\uAE30\uAC04 \uB9CC\uB8CC\uC774 \uB9CC\uB8CC\uB41C \uC815\uAE30\uAD8C\uC785\uB2C8\uB2E4.";
                case TICKET_STATUS.ASSIGNMENT:
                    return "\uC591\uB3C4\uB41C \uC815\uAE30\uAD8C\uC740 \uC0AC\uC6A9\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
                case TICKET_STATUS.CANCELLED:
                    return "\uCDE8\uC18C\uB41C \uC815\uAE30\uAD8C\uC740 \uC0AC\uC6A9\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
                case TICKET_STATUS.STOP:
                    return "\uD604\uC7AC \uC911\uC9C0\uB41C \uC815\uAE30\uAD8C\uC740 \uC0AC\uC6A9\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
                default:
                    return false;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TicketModel.prototype, "historyBaseData", {
        get: function () {
            if (!this.data) {
                return null;
            }
            switch (this.type) {
                case TICKET_TYPE.TERM:
                    return {
                        type: TICKET_USED_TYPE.USED,
                        data: {
                            createdAt: new Date()
                        },
                        ticketId: this.id
                    };
                case TICKET_TYPE.COUNT:
                    return {
                        type: TICKET_USED_TYPE.USED,
                        data: {
                            deduction: 1,
                            remain: this.data.remain - 1,
                            total: this.data.total,
                            createdAt: new Date()
                        },
                        ticketId: this.id
                    };
                case TICKET_TYPE.CHARGING_MONEY:
                    return {
                        type: TICKET_USED_TYPE.USED,
                        data: {
                            total: this.data.total,
                            createdAt: new Date()
                        },
                        ticketId: this.id
                    };
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TicketModel.prototype, "benefitString", {
        get: function () {
            var template = this.copiedTicketTemplate && new TicketTemplateModel(this.copiedTicketTemplate);
            return template ? "\uC81C\uACF5\uD61C\uD0DD ".concat(template.benefitString) : '';
        },
        enumerable: false,
        configurable: true
    });
    TicketModel.prototype.getTicketDataToString = function (includeLabel) {
        var data = this.data;
        if (!data) {
            return '-';
        }
        switch (this.type) {
            case TICKET_TYPE.TERM:
                return includeLabel
                    ? "\uC794\uC5EC : ".concat(dayjs(data.startedAt).format(DATE_FORMAT), "~").concat(dayjs(data.expiredAt).format(DATE_FORMAT))
                    : "".concat(dayjs(data.startedAt).format(DATE_FORMAT), "~").concat(dayjs(data.expiredAt).format(DATE_FORMAT));
            case TICKET_TYPE.COUNT:
                return includeLabel
                    ? "\uC794\uC5EC : ".concat(FormattedNumber({ value: data.remain }), "\uD68C / ").concat(FormattedNumber({
                        value: data.total
                    }), "\uD68C")
                    : "".concat(FormattedNumber({ value: data.remain }), "\uD68C / ").concat(FormattedNumber({ value: data.total }), "\uD68C");
            case TICKET_TYPE.CHARGING_MONEY:
                return includeLabel
                    ? "\uC794\uC5EC : ".concat(KRWFomatToString({ value: data.remain }), " / ").concat(KRWFomatToString({ value: data.total }))
                    : "".concat(KRWFomatToString({ value: data.remain }), " / ").concat(KRWFomatToString({ value: data.total }));
        }
    };
    TicketModel.prototype.getStatusToString = function () {
        switch (this.status) {
            case TICKET_STATUS.ACTIVE:
                return "\uC0AC\uC6A9\uAC00\uB2A5";
            case TICKET_STATUS.EXPIRED:
                return "\uAE30\uAC04\uB9CC\uB8CC";
            case TICKET_STATUS.ASSIGNMENT:
                return "\uC591\uB3C4\uB41C \uC815\uAE30\uAD8C";
            case TICKET_STATUS.CANCELLED:
                return "\uCDE8\uC18C\uB41C \uC815\uAE30\uAD8C";
            case TICKET_STATUS.STOP:
                return "\uC911\uC9C0\uB41C \uC815\uAE30\uAD8C";
            case TICKET_STATUS.COMPLETE:
                return "\uC0AC\uC6A9\uC644\uB8CC";
        }
    };
    TicketModel.prototype.getRelationDescription = function (myId, relations) {
        var _this = this;
        if (myId === this.customerId) {
            return '';
        }
        var isMain = false;
        var relation = relations.find(function (item) {
            if (item.mainCustomerId === _this.customerId) {
                isMain = true;
                return true;
            }
            if (item.otherCustomerId === _this.customerId) {
                return true;
            }
        });
        var customer = isMain ? relation.mainCustomer : relation.otherCustomer;
        return "*".concat(customer === null || customer === void 0 ? void 0 : customer.name, "(").concat(isMain ? relation.mainCustomerTitle : relation.otherCustomerTitle, ")");
    };
    TicketModel.TICKET_TYPE = TICKET_TYPE;
    TicketModel.TICKET_STATUS = TICKET_STATUS;
    return TicketModel;
}(BaseModel));
export { TicketModel };
