var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { UsingHistoryModel } from './UsingHistoryModel';
import { BaseModel } from './BaseModel';
import { SalesHistoryModel } from './SalesHistoryModel';
import { ManagerModel } from './ManagerModel';
import { TicketModel } from './TicketModel';
import { pick, sum } from 'renderer/utils';
export var SALES_TYPES;
(function (SALES_TYPES) {
    SALES_TYPES["SALES"] = "sales";
    SALES_TYPES["PRODUCT"] = "product";
    SALES_TYPES["SERVICE"] = "service";
    SALES_TYPES["TICKET"] = "ticket";
    SALES_TYPES["REFUND"] = "refund";
    SALES_TYPES["RESERVATION_FEE"] = "reservationFee";
    SALES_TYPES["UNPAID"] = "unpaid";
})(SALES_TYPES || (SALES_TYPES = {}));
var SalesPerManagerModel = /** @class */ (function (_super) {
    __extends(SalesPerManagerModel, _super);
    function SalesPerManagerModel(props) {
        var _this = _super.call(this, props) || this;
        _this.usingHistory = props.usingHistory && new UsingHistoryModel(props.usingHistory);
        _this.ticket = props.ticket && new TicketModel(props.ticket);
        _this.salesHistory = props.salesHistory && new SalesHistoryModel(props.salesHistory);
        _this.manager = props.manager && new ManagerModel(props.manager);
        return _this;
    }
    Object.defineProperty(SalesPerManagerModel.prototype, "sharePrice", {
        get: function () {
            var _this = this;
            return sum(Object.keys(this.methods).map(function (key) { return _this.methods[key] || 0; }));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesPerManagerModel.prototype, "paidPrice", {
        get: function () {
            var _this = this;
            var paidTarget = pick(this.methods, SalesHistoryModel.PAID_ELEMENTS);
            return sum(Object.keys(paidTarget).map(function (key) { return _this.methods[key] || 0; }));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesPerManagerModel.prototype, "totalPrice", {
        get: function () {
            var _this = this;
            return sum(Object.keys(this.methods).map(function (key) { return _this.methods[key] || 0; }));
        },
        enumerable: false,
        configurable: true
    });
    return SalesPerManagerModel;
}(BaseModel));
export { SalesPerManagerModel };
