import * as yup from 'yup';
import { convertFormmatedToNumber } from './object';
function equalTo(ref, msg) {
    return yup.mixed().test({
        name: 'equalTo',
        exclusive: false,
        message: msg || '${path} must be the same as ${reference}',
        params: {
            reference: ref.path
        },
        test: function (value) {
            return value === this.resolve(ref);
        }
    });
}
function minTo(ref, msg) {
    return yup.number().test({
        name: 'minTo',
        exclusive: false,
        message: msg || '${path} must be the below ${reference}',
        params: {
            reference: ref.path
        },
        test: function (value) {
            var convertedValue = convertFormmatedToNumber(value);
            var convertedTargetValue = convertFormmatedToNumber(this.resolve(ref));
            return convertedValue < convertedTargetValue;
        }
    });
}
export function setValidator() {
    yup.setLocale({
        mixed: {
            required: '${label}은(는) 필수 입력항목입니다.'
        },
        string: {
            min: '${label}은(는) ${min} 글자 이상 입력해주세요.',
            max: '${label}은(는) ${max} 글자 이하로 입력해주세요.'
        },
        number: {
            // required:
            integer: '${label}은(는) 정수로 입력해주세요.'
        }
    });
}
// TODO: 마스크 밸류를 스트링으로 바꾸고, 디스카운트 값을 검증할 수 있을까??
// TODO: 패스워드에 이거 적용하기
// https://github.com/jaredpalmer/formik/issues/90#issuecomment-317804880
yup.addMethod(yup.string, 'equalTo', equalTo);
yup.addMethod(yup.number, 'minTo', minTo);
yup.addMethod(yup.number, 'formmatedToNumber', function () {
    return this.transform(function (currentValue, originalValue) {
        return convertFormmatedToNumber(originalValue);
    });
});
