var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable no-unused-vars */
import { GENDER_ENUM } from './UserModel';
import { BaseModel } from './BaseModel';
import { TicketModel, TICKET_STATUS } from './TicketModel';
import { CustomerPointModel } from './CustomerPointModel';
import { PrepaidPointModel } from './PrepaidPointModel';
import { PaymentHistoryModel, TICKET_TYPE, CustomerRelationModel } from 'renderer/models';
import { orderBy, PhoneNumberUtils, sum } from 'renderer/utils';
import { UsingHistoryModel } from './UsingHistoryModel';
import { maskPhoneNumberByPermission } from 'renderer/queries/manager';
var CustomerModel = /** @class */ (function (_super) {
    __extends(CustomerModel, _super);
    function CustomerModel(props) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        _this = _super.call(this, props) || this;
        _this.tickets = props.tickets && props.tickets.map(function (ticket) { return new TicketModel(ticket); });
        _this.payments = props.payments && props.payments.map(function (ticket) { return new PaymentHistoryModel(ticket); });
        _this.usingHistory = (_a = props.usingHistory) === null || _a === void 0 ? void 0 : _a.map(function (history) { return new UsingHistoryModel(history); });
        _this.prepaidPoints = (_b = props.prepaidPoints) === null || _b === void 0 ? void 0 : _b.map(function (point) { return new PrepaidPointModel(point); });
        _this.customerPoints = (_c = props.customerPoints) === null || _c === void 0 ? void 0 : _c.map(function (point) { return new CustomerPointModel(point); });
        _this.sharedTickets = (_d = props.sharedTickets) === null || _d === void 0 ? void 0 : _d.map(function (point) { return new TicketModel(point); });
        _this.mainCustomerRelations = (_e = props.mainCustomerRelations) === null || _e === void 0 ? void 0 : _e.map(function (item) { return new CustomerRelationModel(item); });
        _this.otherCustomerRelations = (_f = props.otherCustomerRelations) === null || _f === void 0 ? void 0 : _f.map(function (item) { return new CustomerRelationModel(item); });
        return _this;
    }
    Object.defineProperty(CustomerModel.prototype, "managerName", {
        get: function () {
            var _a;
            return (_a = this.manager) === null || _a === void 0 ? void 0 : _a.displayName;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerModel.prototype, "latestPoint", {
        // FIXME: remove
        get: function () {
            return (this.customerStorePoint && this.customerStorePoint.latestPoint) || 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerModel.prototype, "customerRelations", {
        get: function () {
            return (this.mainCustomerRelations || []).concat(this.otherCustomerRelations || []);
        },
        enumerable: false,
        configurable: true
    });
    CustomerModel.prototype.genderToText = function () {
        switch (this.gender) {
            case GENDER_ENUM.MALE:
                return '남성';
            case GENDER_ENUM.FEMALE:
                return '여성';
        }
    };
    Object.defineProperty(CustomerModel.prototype, "addressString", {
        get: function () {
            return this.address ? "".concat(this.address.address || '', " ").concat(this.address.detailAddress || '') : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerModel.prototype, "customerPoint", {
        get: function () {
            var _a;
            return ((_a = this.meta) === null || _a === void 0 ? void 0 : _a.customerPoint) || 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerModel.prototype, "prepaidPoint", {
        get: function () {
            var _a;
            return ((_a = this.meta) === null || _a === void 0 ? void 0 : _a.prepaid) || 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerModel.prototype, "totalSales", {
        get: function () {
            var _a;
            return ((_a = this.meta) === null || _a === void 0 ? void 0 : _a.totalPaid) || 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerModel.prototype, "unpaid", {
        get: function () {
            var _a;
            return ((_a = this.meta) === null || _a === void 0 ? void 0 : _a.unpaid) || 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerModel.prototype, "activeTicketCount", {
        get: function () {
            return (this.tickets || []).length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerModel.prototype, "activeAvailableTicketsWithoutCharging", {
        get: function () {
            var _a, _b;
            return orderBy(((_b = (_a = this.tickets) === null || _a === void 0 ? void 0 : _a.concat(this.sharedTickets || [])) === null || _b === void 0 ? void 0 : _b.filter(function (item) { return item.type !== TICKET_TYPE.CHARGING_MONEY && item.status === TICKET_STATUS.ACTIVE; })) || [], 'expired', 'asc');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerModel.prototype, "activeTicketsWithoutCharging", {
        get: function () {
            var _a;
            return orderBy(((_a = this.tickets) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.type !== TICKET_TYPE.CHARGING_MONEY && item.status === TICKET_STATUS.ACTIVE; })) || [], 'expired', 'asc');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerModel.prototype, "activeSharedTicketsWithoutCharging", {
        get: function () {
            var _a;
            return orderBy(((_a = this.sharedTickets) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.type !== TICKET_TYPE.CHARGING_MONEY && item.status === TICKET_STATUS.ACTIVE; })) || [], 'expired', 'asc');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerModel.prototype, "activeTicketsCharging", {
        get: function () {
            var _a;
            return orderBy(((_a = this.tickets) === null || _a === void 0 ? void 0 : _a.filter(function (item) { var _a; return item.type === TICKET_TYPE.CHARGING_MONEY && item.status === TICKET_STATUS.ACTIVE && ((_a = item.data) === null || _a === void 0 ? void 0 : _a.remain) > 0; })) || [], 'expired', 'asc');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerModel.prototype, "activeShareTicketsCharging", {
        get: function () {
            var _a;
            return orderBy(((_a = this.sharedTickets) === null || _a === void 0 ? void 0 : _a.filter(function (item) { var _a; return item.type === TICKET_TYPE.CHARGING_MONEY && item.status === TICKET_STATUS.ACTIVE && ((_a = item.data) === null || _a === void 0 ? void 0 : _a.remain) > 0; })) || [], 'expired', 'asc');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerModel.prototype, "sharedPrepaidPoint", {
        get: function () {
            return sum(this.activeShareTicketsCharging.map(function (ticket) { var _a; return ((_a = ticket.data) === null || _a === void 0 ? void 0 : _a.remain) || 0; })) || 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerModel.prototype, "availableReceive", {
        get: function () {
            var _a, _b, _c;
            var countryCode = ((_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.phone) === null || _b === void 0 ? void 0 : _b.countryCode) || 'kr';
            return !!this.phone && !((_c = this.data) === null || _c === void 0 ? void 0 : _c.rejectReceive) && countryCode === 'kr';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerModel.prototype, "phoneToString", {
        get: function () {
            var _a, _b;
            if (!this.phone) {
                return '';
            }
            if (this.phone.length < 5) {
                return this.phone;
            }
            try {
                var parsedPhoneNumber = PhoneNumberUtils.parsePhoneNumber("+".concat(((_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.phone) === null || _b === void 0 ? void 0 : _b.dialCode) || 82, " ").concat(this.phone));
                if (parsedPhoneNumber.formatNational) {
                    return maskPhoneNumberByPermission(parsedPhoneNumber.formatNational());
                }
                else {
                    return this.phone;
                }
            }
            catch (err) {
                return this.phone;
            }
        },
        enumerable: false,
        configurable: true
    });
    CustomerModel.prototype.getCustomerRelation = function (customerId) {
        var _a, _b;
        var relaction = this.customerRelations.find(function (item) { return item.mainCustomerId === customerId || item.otherCustomerId === customerId; });
        if (relaction) {
            var isOther = relaction.otherCustomerId === customerId;
            return {
                relaction: relaction,
                title: isOther ? relaction.otherCustomerTitle : relaction.mainCustomerTitle,
                customerName: isOther ? (_a = relaction.otherCustomer) === null || _a === void 0 ? void 0 : _a.name : (_b = relaction.mainCustomer) === null || _b === void 0 ? void 0 : _b.name
            };
        }
    };
    return CustomerModel;
}(BaseModel));
export { CustomerModel };
