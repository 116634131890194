import { WebviewInterface } from './RNWebviewInterfase';
import notification from 'antd/lib/notification';
export var isPlussharpMobileApp = function () {
    var _a;
    var userAgent = window.navigator.userAgent.toLowerCase();
    var isIos = /iphone|ipod|ipad/.test(userAgent);
    return !!((_a = window.Plussharp) === null || _a === void 0 ? void 0 : _a.isApp) || (!isIos && userAgent.includes('wv'));
};
export var isPlussharpMobileAppAndAndroid = function () {
    var _a;
    var userAgent = window.navigator.userAgent.toLowerCase();
    return !!((_a = window.Plussharp) === null || _a === void 0 ? void 0 : _a.isApp) && userAgent.includes('wv');
};
export var openWindow = function (url, title) {
    if (isPlussharpMobileApp()) {
        WebviewInterface.getInstance().requestPopup({
            url: url,
            title: title || '외부 서비스로 이동'
        });
    }
    else {
        var open = window.open(url, '_blank');
        if (open == null || typeof open == 'undefined') {
            notification.info({ message: 'Safari에서는 팝업을 띄울 수 없습니다. 모바일앱 또는 PC를 이용해주세요.' });
        }
    }
};
export var gotoNaverBooking = function (store, schedule) {
    var _a, _b, _c, _d, _e, _f, _g;
    var bookingBusinessId = ((_d = (_c = (_b = (_a = store === null || store === void 0 ? void 0 : store.preference) === null || _a === void 0 ? void 0 : _a.naverSmartPlace) === null || _b === void 0 ? void 0 : _b.storeInfo) === null || _c === void 0 ? void 0 : _c.bookingBusinesses[0]) === null || _d === void 0 ? void 0 : _d.bookingBusinessId) ||
        ((_f = (_e = store === null || store === void 0 ? void 0 : store.preference) === null || _e === void 0 ? void 0 : _e.smartplace) === null || _f === void 0 ? void 0 : _f.bookingBusinessId);
    var bookingId = (_g = schedule.data) === null || _g === void 0 ? void 0 : _g.idFromProvider;
    if (bookingId && bookingBusinessId) {
        return openWindow("https://partner.booking.naver.com/bizes/".concat(bookingBusinessId, "/booking-list-view/bookings/").concat(bookingId));
    }
};
export var gotoExternal = function (url) {
    if (window) {
        window.location.href = url;
    }
};
export var goToMarket = function () {
    var userAgent = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
    var url;
    if (userAgent.indexOf('android') > -1) {
        //안드로이드
        url = 'market://details?id=kr.hatchling.plussharpcrm';
    }
    else if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1 || userAgent.indexOf('ipod') > -1) {
        //IOS
        url = 'https://apps.apple.com/app/id1562087342';
    }
    else {
        //아이폰, 안드로이드 외
        url = 'https://play.google.com/store/apps/details?id=kr.hatchling.plussharpcrm';
    }
    window.open(url, '_blank');
};
