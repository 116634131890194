var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable no-unused-vars */
import { BaseModel } from 'renderer/models';
import dayjs from 'dayjs';
import { omitEmptyValues } from 'renderer/utils';
import { KRWFomatToString } from 'renderer/components/Intl';
import { isEmpty } from 'renderer/utils';
export var TICKET_TYPE;
(function (TICKET_TYPE) {
    TICKET_TYPE["CHARGING_MONEY"] = "charging_money";
    TICKET_TYPE["COUNT"] = "count";
    TICKET_TYPE["TIME"] = "time";
    TICKET_TYPE["TERM"] = "term";
})(TICKET_TYPE || (TICKET_TYPE = {}));
export var TICKET_BENEFIT_TYPE;
(function (TICKET_BENEFIT_TYPE) {
    TICKET_BENEFIT_TYPE["ADD"] = "add";
    TICKET_BENEFIT_TYPE["NONE"] = "none";
    TICKET_BENEFIT_TYPE["DISCOUNT"] = "discount";
})(TICKET_BENEFIT_TYPE || (TICKET_BENEFIT_TYPE = {}));
var TicketTemplateModel = /** @class */ (function (_super) {
    __extends(TicketTemplateModel, _super);
    function TicketTemplateModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TicketTemplateModel.prototype.sellingPrice = function () {
        return this.price - this.discount;
    };
    TicketTemplateModel.getTypeToString = function (type) {
        switch (type) {
            case TICKET_TYPE.CHARGING_MONEY:
                return '선불액';
            case TICKET_TYPE.COUNT:
                return '횟수권';
            case TICKET_TYPE.TERM:
                return '기간권';
            case TICKET_TYPE.TIME:
                return '시간권';
            default:
                return type;
        }
    };
    TicketTemplateModel.prototype.getTypeToString = function () {
        return TicketTemplateModel.getTypeToString(this.type);
    };
    Object.defineProperty(TicketTemplateModel.prototype, "titleWithAdditional", {
        get: function () {
            return isEmpty(this.data.additionalFeatures) ? this.name : "".concat(this.name, " +").concat(this.data.additionalFeatures[0]);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TicketTemplateModel.prototype, "additional", {
        get: function () {
            var _a;
            return isEmpty(this.data.additionalFeatures) ? null : (_a = this.data.additionalFeatures) === null || _a === void 0 ? void 0 : _a.join('+');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TicketTemplateModel.prototype, "amountUnitString", {
        get: function () {
            switch (this.type) {
                case TICKET_TYPE.CHARGING_MONEY:
                    return '원';
                case TICKET_TYPE.COUNT:
                    return '회';
                case TICKET_TYPE.TERM:
                    return TicketTemplateModel.unitToString(this.data.unit);
                case TICKET_TYPE.TIME:
                    return '시간';
            }
        },
        enumerable: false,
        configurable: true
    });
    TicketTemplateModel.prototype.getExpiredOptionToString = function () {
        if (!this.data.expiredOption) {
            return '무제한';
        }
        var amount = this.data.expiredOption && this.data.expiredOption.amount;
        var unit = this.data.expiredOption && this.data.expiredOption.unit;
        return "".concat(amount).concat(TicketTemplateModel.unitToString(unit));
    };
    TicketTemplateModel.prototype.getDefaultTicketData = function (startedAt) {
        switch (this.type) {
            case TICKET_TYPE.COUNT:
            case TICKET_TYPE.CHARGING_MONEY:
            case TICKET_TYPE.TIME:
                return {
                    remain: this.finalAmount,
                    total: this.finalAmount,
                    startedAt: undefined,
                    expiredAt: undefined
                };
            case TICKET_TYPE.TERM:
                var benefit = this.data.benefit;
                var isAdd = (benefit === null || benefit === void 0 ? void 0 : benefit.type) === TICKET_BENEFIT_TYPE.ADD;
                var expiredAt = dayjs(startedAt).add(this.data.amount, this.data.unit).subtract(1, 'day');
                var unit = benefit.unit, amount = benefit.amount;
                return {
                    startedAt: dayjs(startedAt).toDate(),
                    expiredAt: (isAdd && benefit.amount ? expiredAt.add(amount, unit).toDate() : expiredAt.toDate()),
                    remain: undefined,
                    total: undefined
                };
        }
    };
    Object.defineProperty(TicketTemplateModel.prototype, "finalAmount", {
        get: function () {
            var _a, _b, _c;
            var amount = ((_a = this.data) === null || _a === void 0 ? void 0 : _a.amount) || 0;
            var add = ((_b = this.data.benefit) === null || _b === void 0 ? void 0 : _b.type) === TICKET_BENEFIT_TYPE.ADD ? ((_c = this.data.benefit) === null || _c === void 0 ? void 0 : _c.amount) || 0 : 0;
            return amount + add;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TicketTemplateModel.prototype, "finalAmountString", {
        get: function () {
            var _a, _b;
            var isTerm = this.type === TICKET_TYPE.TERM;
            return isTerm
                ? ((_a = this.data.benefit) === null || _a === void 0 ? void 0 : _a.type) === TICKET_BENEFIT_TYPE.ADD
                    ? "".concat(this.data.amount).concat(TicketTemplateModel.unitToString(this.data.unit), " + ").concat(this.data.benefit.amount).concat(TicketTemplateModel.unitToString(this.data.benefit.unit))
                    : "".concat(this.data.amount).concat(TicketTemplateModel.unitToString(this.data.unit))
                        ? ((_b = this.data.benefit) === null || _b === void 0 ? void 0 : _b.amount) || 0
                        : 0
                : this.finalAmount;
        },
        enumerable: false,
        configurable: true
    });
    TicketTemplateModel.prototype.getExpiredAt = function (target) {
        if (!this.data.expiredOption) {
            return undefined;
        }
        var _a = this.data.expiredOption, amount = _a.amount, unit = _a.unit;
        return dayjs(target).add(amount, unit).subtract(1, 'day').endOf('day');
    };
    TicketTemplateModel.prototype.getCancellableDaysToString = function () {
        var cancellableDays = this.meta.cancellableDays;
        return cancellableDays ? "".concat(cancellableDays, "\uC77C") : '취소불가';
    };
    TicketTemplateModel.prototype.getIsSellToString = function () {
        return this.meta.isSell ? '판매중' : '판매중지';
    };
    TicketTemplateModel.defaultValues = function () {
        return {
            type: TICKET_TYPE.COUNT,
            price: '',
            discount: '',
            data: { expiredOption: { unit: 'year', amount: 1 }, unit: 'month' },
            meta: { isSell: 'true', cancellableDays: 7 }
        };
    };
    TicketTemplateModel.unitToString = function (unit) {
        switch (unit) {
            case 'day':
                return "\uC77C";
            case 'week':
                return "\uC8FC";
            case 'month':
                return "\uAC1C\uC6D4";
            case 'year':
                return "\uB144";
            default:
                return '없음';
        }
    };
    Object.defineProperty(TicketTemplateModel.prototype, "unitStringByType", {
        get: function () {
            var _a;
            if (this.type === TICKET_TYPE.COUNT) {
                return '회';
            }
            if (this.type === TICKET_TYPE.CHARGING_MONEY) {
                return '원';
            }
            if (this.type === TICKET_TYPE.TERM) {
                var unit = (_a = this.data) === null || _a === void 0 ? void 0 : _a.unit;
                switch (unit) {
                    case 'day':
                        return "\uC77C";
                    case 'week':
                        return "\uC8FC";
                    case 'month':
                        return "\uAC1C\uC6D4";
                    case 'year':
                        return "\uB144";
                    default:
                        return '없음';
                }
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TicketTemplateModel.prototype, "totalPrice", {
        get: function () {
            return this.price - this.discountPrice;
            // return this.price - (this.discount || discount || 0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TicketTemplateModel.prototype, "discountPrice", {
        get: function () {
            var _a, _b;
            return ((_a = this.data.benefit) === null || _a === void 0 ? void 0 : _a.type) === TICKET_BENEFIT_TYPE.DISCOUNT ? ((_b = this.data.benefit) === null || _b === void 0 ? void 0 : _b.amount) || 0 : 0;
            // return this.price - (this.discount || discount || 0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TicketTemplateModel.prototype, "benefitString", {
        get: function () {
            var _a;
            var benefit = ((_a = this.data.benefit) === null || _a === void 0 ? void 0 : _a.type) !== TICKET_BENEFIT_TYPE.NONE && this.data.benefit;
            return benefit
                ? benefit.type === TICKET_BENEFIT_TYPE.DISCOUNT
                    ? "(\uD560\uC778) ".concat(KRWFomatToString({ value: benefit.amount }))
                    : "(\uCD94\uAC00) ".concat(benefit.amount).concat(this.amountUnitString)
                : '-';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TicketTemplateModel.prototype, "amountString", {
        get: function () {
            var _a, _b, _c, _d, _e, _f;
            var ticketType = this.type;
            var benefitType = (_a = this.data.benefit) === null || _a === void 0 ? void 0 : _a.type;
            // 충전권은 가격으로 Amount계산
            var amount = ticketType === TICKET_TYPE.CHARGING_MONEY ? this.price || 0 : ((_b = this.data) === null || _b === void 0 ? void 0 : _b.amount) || 0;
            var finalAmount;
            if (TICKET_BENEFIT_TYPE.ADD === benefitType) {
                var benfitAmount = ((_c = this.data.benefit) === null || _c === void 0 ? void 0 : _c.amount) || 0;
                var dataUnit = ((_d = this.data) === null || _d === void 0 ? void 0 : _d.unit) || 'month';
                var benefitUnit = ((_e = this.data.benefit) === null || _e === void 0 ? void 0 : _e.unit) || 'month';
                if (TICKET_TYPE.TERM === ticketType) {
                    finalAmount = "".concat(amount).concat(TicketTemplateModel.unitToString(dataUnit), " + ").concat(benfitAmount).concat(TicketTemplateModel.unitToString(benefitUnit));
                }
                else {
                    finalAmount = "".concat(amount + benfitAmount).concat(this.unitStringByType);
                }
            }
            else {
                if (TICKET_TYPE.TERM === ticketType) {
                    var dataUnit = ((_f = this.data) === null || _f === void 0 ? void 0 : _f.unit) || 'month';
                    finalAmount = "".concat(amount).concat(TicketTemplateModel.unitToString(dataUnit));
                }
                else {
                    finalAmount = "".concat(amount).concat(this.unitStringByType);
                }
            }
            return finalAmount;
        },
        enumerable: false,
        configurable: true
    });
    TicketTemplateModel.convertObjectToTemplatePayload = function (target, storeId) {
        var _a;
        var isLimit = target.isLimit, finalAmount = target.finalAmount, totalPrice = target.totalPrice, data = target.data, description = target.description, name = target.name, price = target.price, type = target.type, storeProductId = target.storeProductId, rest = __rest(target, ["isLimit", "finalAmount", "totalPrice", "data", "description", "name", "price", "type", "storeProductId"]);
        var payload = {
            storeId: storeId,
            name: name,
            price: price,
            type: type,
            description: description,
            storeProductId: storeProductId
        };
        var additionalFeatures = Object.keys(rest)
            .map(function (key) {
            if (key.includes('add-')) {
                return rest[key] ? rest[key] : undefined;
            }
        })
            .filter(Boolean) || [];
        var benefit = omitEmptyValues(__assign(__assign({}, data.benefit), { unit: data.benefit.type === TICKET_BENEFIT_TYPE.ADD && type === TICKET_TYPE.TERM ? data.benefit.unit : undefined }));
        var newData = omitEmptyValues(__assign(__assign({}, data), { expiredOption: !isLimit ? null : (_a = target.data) === null || _a === void 0 ? void 0 : _a.expiredOption, amount: type === TICKET_TYPE.CHARGING_MONEY ? price : data.amount, benefit: benefit, unit: type === TICKET_TYPE.TERM ? data.unit : undefined, additionalFeatures: additionalFeatures }));
        return new TicketTemplateModel(omitEmptyValues(__assign(__assign({}, payload), { data: newData })));
    };
    Object.defineProperty(TicketTemplateModel.prototype, "isProductDeleted", {
        get: function () {
            return !this.storeProduct && this.type !== TICKET_TYPE.CHARGING_MONEY;
        },
        enumerable: false,
        configurable: true
    });
    TicketTemplateModel.TICKET_TYPE = TICKET_TYPE;
    TicketTemplateModel.createTemplatetName = function (nameWithGroupName, template) {
        var _a, _b;
        var amount = (_a = template.data) === null || _a === void 0 ? void 0 : _a.amount;
        var unit = (_b = template.data) === null || _b === void 0 ? void 0 : _b.unit;
        var subName = '';
        if (template.type === TICKET_TYPE.COUNT && amount) {
            subName = "".concat(amount, "\uD68C \uC774\uC6A9\uAD8C");
        }
        else if (template.type === TICKET_TYPE.TERM && amount) {
            subName = "".concat(amount).concat(TicketTemplateModel.unitToString(unit), " \uC774\uC6A9\uAD8C");
        }
        var productName = nameWithGroupName;
        return productName && subName ? "".concat(productName, " ").concat(subName) : '';
    };
    return TicketTemplateModel;
}(BaseModel));
export { TicketTemplateModel };
