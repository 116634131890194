var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel, ManagerModel, StoreProductModel, SalesHistoryModel } from 'renderer/models';
var STORE_STOCK_HISTORY_TYPE;
(function (STORE_STOCK_HISTORY_TYPE) {
    STORE_STOCK_HISTORY_TYPE["SALE"] = "SALE";
    STORE_STOCK_HISTORY_TYPE["PURCHASE"] = "PURCHASE";
    STORE_STOCK_HISTORY_TYPE["SALE_REFUND"] = "SALE_REFUND";
    STORE_STOCK_HISTORY_TYPE["PURCHASE_REFUND"] = "PURCHASE_REFUND";
    STORE_STOCK_HISTORY_TYPE["USER_ADJUSTMENT"] = "USER_ADJUSTMENT";
    STORE_STOCK_HISTORY_TYPE["CONSUMPTION"] = "CONSUMPTION";
})(STORE_STOCK_HISTORY_TYPE || (STORE_STOCK_HISTORY_TYPE = {}));
var StoreStockHistoryModel = /** @class */ (function (_super) {
    __extends(StoreStockHistoryModel, _super);
    function StoreStockHistoryModel(props) {
        var _this = _super.call(this, props) || this;
        if (props.salesHistory) {
            _this.salesHistory = new SalesHistoryModel(props.manager);
        }
        if (props.storeProduct) {
            _this.storeProduct = new StoreProductModel(props.storeProduct);
        }
        if (props.manager) {
            _this.manager = new ManagerModel(props.manager);
        }
        return _this;
    }
    Object.defineProperty(StoreStockHistoryModel.prototype, "typeToString", {
        get: function () {
            switch (this.type) {
                case STORE_STOCK_HISTORY_TYPE.SALE:
                    return '제품판매';
                case STORE_STOCK_HISTORY_TYPE.PURCHASE:
                    return '매입';
                case STORE_STOCK_HISTORY_TYPE.PURCHASE_REFUND:
                    return '매입(반품)';
                case STORE_STOCK_HISTORY_TYPE.SALE_REFUND:
                    return '제품환불';
                case STORE_STOCK_HISTORY_TYPE.USER_ADJUSTMENT:
                    return '재고조정';
                case STORE_STOCK_HISTORY_TYPE.CONSUMPTION:
                    return '내수소모';
            }
        },
        enumerable: false,
        configurable: true
    });
    StoreStockHistoryModel.STORE_STOCK_HISTORY_TYPE = STORE_STOCK_HISTORY_TYPE;
    return StoreStockHistoryModel;
}(BaseModel));
export { StoreStockHistoryModel };
