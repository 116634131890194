var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CustomerModel } from './CustomerModel';
import { TicketModel } from './TicketModel';
import { CustomerPointModel } from './CustomerPointModel';
import { UsingHistoryModel } from './UsingHistoryModel';
import { BaseModel } from './BaseModel';
import { isEmpty, Lodash, sum, floor, times, orderBy, isNumber } from 'renderer/utils';
import { SalesPerManagerModel, SALES_TYPES } from './SalesPerManagerModel';
import { KRWFomatToString } from 'renderer/components';
import * as dayjs from 'dayjs';
import { StoreProductModel } from './StoreProductModel';
import { StoreProductGroupModel } from './StoreProductGroupModel';
import { TICKET_BENEFIT_TYPE, TICKET_TYPE } from './TicketTemplateModel';
import { StoreQuery } from 'renderer/queries';
import { TICKET_STATUS } from 'renderer/models';
export var SALES_LOCATION;
(function (SALES_LOCATION) {
    SALES_LOCATION["OFFLINE"] = "offline";
    SALES_LOCATION["APP"] = "app";
})(SALES_LOCATION || (SALES_LOCATION = {}));
export var SALES_STATUS;
(function (SALES_STATUS) {
    // 미납
    SALES_STATUS["UNPAID"] = "unpaid";
    // 완료
    SALES_STATUS["COMPLETED"] = "completed";
    // 결제완료되었으나 제품 생성 안됨 (공동구매)
    SALES_STATUS["COMPLETED_BUT_NOT_CREATED_TICKET"] = "cbnct";
    // 환불
    SALES_STATUS["CANCELLED"] = "cancelled";
    // 결제 실패
    SALES_STATUS["FAILURE"] = "failure";
    // 결제 대기
    SALES_STATUS["WAITING"] = "waiting";
})(SALES_STATUS || (SALES_STATUS = {}));
export var SALES_REMITTANCE_STATUS;
(function (SALES_REMITTANCE_STATUS) {
    // 미납
    SALES_REMITTANCE_STATUS["PENDING"] = "pending";
    // 완료
    SALES_REMITTANCE_STATUS["COMPLETED"] = "completed";
    // 취소
    SALES_REMITTANCE_STATUS["CANCELLED"] = "cancelled";
    // 정산 예정
    SALES_REMITTANCE_STATUS["SCHEDULED"] = "scheduled";
})(SALES_REMITTANCE_STATUS || (SALES_REMITTANCE_STATUS = {}));
export var SALES_MEHOD_KOREAN;
(function (SALES_MEHOD_KOREAN) {
    SALES_MEHOD_KOREAN["cash"] = "\uD604\uAE08";
    SALES_MEHOD_KOREAN["card"] = "\uCE74\uB4DC";
    SALES_MEHOD_KOREAN["transfer"] = "\uACC4\uC88C\uC774\uCCB4";
    SALES_MEHOD_KOREAN["unpaid"] = "\uBBF8\uC218";
    SALES_MEHOD_KOREAN["discount"] = "\uD560\uC778";
})(SALES_MEHOD_KOREAN || (SALES_MEHOD_KOREAN = {}));
export var SALES_METHODS;
(function (SALES_METHODS) {
    SALES_METHODS["CASH"] = "cash";
    SALES_METHODS["CARD"] = "card";
    SALES_METHODS["TRANSFER"] = "transfer";
    SALES_METHODS["UNPAID"] = "unpaid";
})(SALES_METHODS || (SALES_METHODS = {}));
export var EXTENDED_SALES_MEHOD_KOREAN;
(function (EXTENDED_SALES_MEHOD_KOREAN) {
    EXTENDED_SALES_MEHOD_KOREAN["cash"] = "\uD604\uAE08";
    EXTENDED_SALES_MEHOD_KOREAN["card"] = "\uCE74\uB4DC";
    EXTENDED_SALES_MEHOD_KOREAN["transfer"] = "\uACC4\uC88C\uC774\uCCB4";
    EXTENDED_SALES_MEHOD_KOREAN["unpaid"] = "\uBBF8\uC218";
    EXTENDED_SALES_MEHOD_KOREAN["discount"] = "\uD560\uC778";
    EXTENDED_SALES_MEHOD_KOREAN["customerPoint"] = "\uD3EC\uC778\uD2B8";
    EXTENDED_SALES_MEHOD_KOREAN["prepaidPoint"] = "\uC120\uBD88\uC561";
    // 'sharedPrepaidPoint' = '공유선불액',
    EXTENDED_SALES_MEHOD_KOREAN["deductionPriceByTicket"] = "\uC815\uAE30\uAD8C";
    EXTENDED_SALES_MEHOD_KOREAN["naverPay"] = "\uB124\uC774\uBC84\uD398\uC774";
    EXTENDED_SALES_MEHOD_KOREAN["etc1"] = "\uAE30\uD0C01";
    EXTENDED_SALES_MEHOD_KOREAN["etc2"] = "\uAE30\uD0C02";
    EXTENDED_SALES_MEHOD_KOREAN["etc3"] = "\uAE30\uD0C03";
    EXTENDED_SALES_MEHOD_KOREAN["etc4"] = "\uAE30\uD0C04";
    EXTENDED_SALES_MEHOD_KOREAN["etc5"] = "\uAE30\uD0C05";
})(EXTENDED_SALES_MEHOD_KOREAN || (EXTENDED_SALES_MEHOD_KOREAN = {}));
export var EXTENDED_SALES_METHODS;
(function (EXTENDED_SALES_METHODS) {
    EXTENDED_SALES_METHODS["CARD"] = "card";
    EXTENDED_SALES_METHODS["TRANSFER"] = "transfer";
    EXTENDED_SALES_METHODS["CASH"] = "cash";
    EXTENDED_SALES_METHODS["NAVER_PAY"] = "naverPay";
    EXTENDED_SALES_METHODS["ETC1"] = "etc1";
    EXTENDED_SALES_METHODS["ETC2"] = "etc2";
    EXTENDED_SALES_METHODS["ETC3"] = "etc3";
    EXTENDED_SALES_METHODS["ETC4"] = "etc4";
    EXTENDED_SALES_METHODS["ETC5"] = "etc5";
    EXTENDED_SALES_METHODS["UNPAID"] = "unpaid";
    EXTENDED_SALES_METHODS["PREPAID_POINT"] = "prepaidPoint";
    EXTENDED_SALES_METHODS["CUSTOMER_POINT"] = "customerPoint";
    EXTENDED_SALES_METHODS["SHARED_PREPAID_POINT"] = "sharedPrepaidPoint";
    EXTENDED_SALES_METHODS["DEDUCTION_PRICE_BY_TICKET"] = "deductionPriceByTicket";
    EXTENDED_SALES_METHODS["DISCOUNT"] = "discount";
})(EXTENDED_SALES_METHODS || (EXTENDED_SALES_METHODS = {}));
export var SALES_METHODS_KEYS = Object.values(EXTENDED_SALES_METHODS);
var SalesHistoryModel = /** @class */ (function (_super) {
    __extends(SalesHistoryModel, _super);
    function SalesHistoryModel(props) {
        var _this = _super.call(this, props) || this;
        _this.tickets = props.tickets && props.tickets.map(function (ticket) { return new TicketModel(ticket); });
        _this.usingHistory = props.usingHistory && props.usingHistory.map(function (history) { return new UsingHistoryModel(history); });
        _this.salesPerManagers =
            props.salesPerManagers && props.salesPerManagers.map(function (history) { return new SalesPerManagerModel(history); });
        _this.customer = props.customer && new CustomerModel(props.customer);
        return _this;
    }
    Object.defineProperty(SalesHistoryModel.prototype, "type", {
        get: function () {
            var spm = this.salesPerManagers && this.salesPerManagers[0];
            return (spm === null || spm === void 0 ? void 0 : spm.type) === SALES_TYPES.SALES
                ? this.ticket
                    ? SALES_TYPES.TICKET
                    : SALES_TYPES.PRODUCT
                : (spm === null || spm === void 0 ? void 0 : spm.type) || (this.ticket ? SALES_TYPES.TICKET : SALES_TYPES.PRODUCT);
            // 예전 마이그레이션 티켓매출에 spm을 안만들고있음..
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesHistoryModel.prototype, "typeString", {
        get: function () {
            return !this.type
                ? '상품'
                : this.type === SALES_TYPES.PRODUCT
                    ? '상품'
                    : this.type === SALES_TYPES.TICKET
                        ? '선불액∙정기권'
                        : this.type === SALES_TYPES.REFUND
                            ? '환불'
                            : this.type === SALES_TYPES.RESERVATION_FEE
                                ? '예약금'
                                : this.type === SALES_TYPES.UNPAID
                                    ? '미수입금'
                                    : '정기권';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesHistoryModel.prototype, "prepaidHistory", {
        get: function () {
            var _a;
            return orderBy((_a = this.ticketHistory) === null || _a === void 0 ? void 0 : _a.filter(function (item) { var _a; return ((_a = item.ticket) === null || _a === void 0 ? void 0 : _a.type) === TICKET_TYPE.CHARGING_MONEY; }), 'ticket.expiredAt', 'asc');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesHistoryModel.prototype, "myPrepaidHistory", {
        get: function () {
            var _a;
            return orderBy((_a = this.ticketHistory) === null || _a === void 0 ? void 0 : _a.filter(function (item) { var _a; return ((_a = item.ticket) === null || _a === void 0 ? void 0 : _a.type) === TICKET_TYPE.CHARGING_MONEY && !item.isShared; }), 'ticket.expiredAt', 'asc');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesHistoryModel.prototype, "sharedPrepaidHistory", {
        get: function () {
            var _a;
            return orderBy((_a = this.ticketHistory) === null || _a === void 0 ? void 0 : _a.filter(function (item) { var _a; return ((_a = item.ticket) === null || _a === void 0 ? void 0 : _a.type) === TICKET_TYPE.CHARGING_MONEY && item.isShared; }), 'ticket.expiredAt', 'asc');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesHistoryModel.prototype, "ticket", {
        get: function () {
            return this.tickets && this.tickets[0];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesHistoryModel.prototype, "locationToString", {
        get: function () {
            switch (this.meta && this.meta.location) {
                case SALES_LOCATION.APP:
                    return "\uC571";
                case SALES_LOCATION.OFFLINE:
                    return "\uC624\uD504\uB77C\uC778";
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesHistoryModel.prototype, "managerNames", {
        get: function () {
            var _a;
            return ((_a = this.salesPerManagers) === null || _a === void 0 ? void 0 : _a.map(function (sale) { var _a; return ((_a = sale.manager) === null || _a === void 0 ? void 0 : _a.displayName) || '-'; }).join('/')) || '-';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesHistoryModel.prototype, "saleListString", {
        get: function () {
            return !isEmpty(this.tickets)
                ? this.tickets.map(function (ticket) { var _a; return "[".concat((_a = ticket.copiedTicketTemplate) === null || _a === void 0 ? void 0 : _a.getTypeToString(), "]").concat(ticket.name); }).join(' / ')
                : this.usingHistory &&
                    this.usingHistory
                        .map(function (history) {
                        var _a, _b;
                        return history.amount > 1
                            ? "".concat((_a = history.product) === null || _a === void 0 ? void 0 : _a.nameWithGroupName, "x").concat(history.amount)
                            : (_b = history.product) === null || _b === void 0 ? void 0 : _b.nameWithGroupName;
                    })
                        .join('/');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesHistoryModel.prototype, "usingTotalPrice", {
        get: function () {
            return this.totalPrice;
            // return this.tickets ? this.totalPrice : sum(this.usingHistory?.map((using) => using.price));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesHistoryModel.prototype, "totalPriceWithoutPoint", {
        get: function () {
            var _a, _b;
            return this.totalPrice - (((_a = this.methods) === null || _a === void 0 ? void 0 : _a.customerPoint) || 0) - ((_b = this.methods) === null || _b === void 0 ? void 0 : _b.prepaidPoint);
        },
        enumerable: false,
        configurable: true
    });
    SalesHistoryModel.prototype.isManagerPayment = function (managerId) {
        var _a;
        return !!((_a = this.salesPerManagers) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.managerId === managerId; }));
    };
    Object.defineProperty(SalesHistoryModel.prototype, "paidPrice", {
        get: function () {
            var _this = this;
            if (this.methods) {
                var types = SalesHistoryModel.PAID_ELEMENTS;
                return sum(types.map(function (type) { return _this.methods[type] || 0; }));
            }
            else {
                return 0;
            }
        },
        enumerable: false,
        configurable: true
    });
    SalesHistoryModel.prototype.getPaidPriceByManagerId = function (managerId) {
        var _a;
        if (!managerId || managerId === 'all') {
            return this.paidPrice || 0;
        }
        var salesPerManagers = ((_a = this.salesPerManagers) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.managerId === managerId; })) || [];
        if (salesPerManagers.length > 0) {
            var types_1 = SalesHistoryModel.PAID_ELEMENTS;
            return sum(salesPerManagers.map(function (spm) { return sum(types_1.map(function (type) { return ((spm === null || spm === void 0 ? void 0 : spm.methods) && (spm === null || spm === void 0 ? void 0 : spm.methods[type])) || 0; })); }));
        }
        else {
            return 0;
        }
    };
    SalesHistoryModel.prototype.getTotalPriceByManagerId = function (managerId) {
        var _a;
        if (!managerId || managerId === 'all') {
            // 총영업액 구하기임 -> totalPrice에는 정기권 차감액이 빠져있다.
            return (this.totalPrice || 0) + (this.deductionPriceByTicket || 0);
        }
        var salesPerManagers = ((_a = this.salesPerManagers) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.managerId === managerId; })) || [];
        if (salesPerManagers.length > 0) {
            return sum(salesPerManagers.map(function (spm) {
                return sum(Object.keys(spm.methods).map(function (key) { return ((spm === null || spm === void 0 ? void 0 : spm.methods) && (spm === null || spm === void 0 ? void 0 : spm.methods[key])) || 0; }));
            }));
        }
        else {
            return 0;
        }
    };
    Object.defineProperty(SalesHistoryModel.prototype, "deductionString", {
        get: function () {
            if (this.methods) {
                var _a = this.methods, deductionPriceByTicket = _a.deductionPriceByTicket, prepaidPoint = _a.prepaidPoint, customerPoint = _a.customerPoint, sharedPrepaidPoint = _a.sharedPrepaidPoint;
                var base = deductionPriceByTicket ? ["(\uC815\uAE30\uAD8C) ".concat(KRWFomatToString({ value: deductionPriceByTicket }))] : [];
                if (prepaidPoint || sharedPrepaidPoint) {
                    base.push("(\uC120\uBD88\uC561) ".concat(KRWFomatToString({ value: (prepaidPoint || 0) + (sharedPrepaidPoint || 0) })));
                }
                if (customerPoint) {
                    base.push("(\uD3EC\uC778\uD2B8) ".concat(KRWFomatToString({ value: customerPoint })));
                }
                return base.join(' / ');
            }
            else {
                return '-';
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesHistoryModel.prototype, "deductionPriceByTicket", {
        get: function () {
            var _a;
            return ((_a = this.methods) === null || _a === void 0 ? void 0 : _a.deductionPriceByTicket) || 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesHistoryModel.prototype, "usedPrepaidPoint", {
        get: function () {
            var _a;
            return ((_a = this.methods) === null || _a === void 0 ? void 0 : _a.prepaidPoint) || 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesHistoryModel.prototype, "usedCustomerPoint", {
        get: function () {
            var _a;
            return ((_a = this.methods) === null || _a === void 0 ? void 0 : _a.customerPoint) || 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesHistoryModel.prototype, "methodsString", {
        get: function () {
            var _this = this;
            var types = SalesHistoryModel.PAID_ELEMENTS;
            var store = StoreQuery.getMyStoreInCache();
            return types
                .map(function (key) {
                var _a;
                return _this.methods &&
                    _this.methods[key] &&
                    "(".concat(((_a = store === null || store === void 0 ? void 0 : store.paymentMethods[key]) === null || _a === void 0 ? void 0 : _a.name) || EXTENDED_SALES_MEHOD_KOREAN[key], ") ").concat(KRWFomatToString({
                        value: _this.methods[key]
                    }));
            })
                .filter(Boolean)
                .join(' / ');
        },
        enumerable: false,
        configurable: true
    });
    SalesHistoryModel.getMethodsForForm = function (methods) {
        var newMethods = {};
        Object.keys(methods || {})
            .filter(function (key) { return key !== 'deductionPriceByTicket'; })
            .map(function (key) {
            var methodsValue = methods[key];
            if (methodsValue) {
                newMethods[key] = { active: true, deduction: methodsValue };
            }
        });
        return newMethods;
    };
    Object.defineProperty(SalesHistoryModel.prototype, "paymentHistoryForm", {
        get: function () {
            var _a, _b, _c, _d, _e, _f, _g;
            var ticket = this.ticket;
            var methods = SalesHistoryModel.getMethodsForForm(this.methods);
            return {
                finalPrice: this.totalPrice,
                customerId: this.customerId,
                id: this.id,
                // 정기권 구매 시
                ticketTemplateId: ticket
                    ? [(_a = ticket.copiedTicketTemplate) === null || _a === void 0 ? void 0 : _a.type, (_b = ticket.copiedTicketTemplate) === null || _b === void 0 ? void 0 : _b.id]
                    : undefined,
                ticket: ticket
                    ? __assign(__assign({}, ticket), { copiedTicketTemplate: __assign(__assign({}, ticket === null || ticket === void 0 ? void 0 : ticket.copiedTicketTemplate), { totalPrice: ((_c = ticket === null || ticket === void 0 ? void 0 : ticket.copiedTicketTemplate) === null || _c === void 0 ? void 0 : _c.totalPrice) || 0, benefitString: ((_d = ticket === null || ticket === void 0 ? void 0 : ticket.copiedTicketTemplate) === null || _d === void 0 ? void 0 : _d.benefitString) || '' }), salesPerManagers: ticket.salesPerManagers.map(function (item) {
                            return __assign(__assign({}, item), { sharePrice: item.sharePrice });
                        }), expiredAt: ticket.expiredAt ? dayjs(ticket.expiredAt) : undefined, data: ticket.data.startedAt
                            ? __assign(__assign({}, ticket.data), { startedAt: dayjs(ticket.data.startedAt), expiredAt: dayjs(ticket.data.expiredAt) }) : __assign({}, ticket.data) }) : undefined,
                // 상품 구매 시
                usingHistory: ((_e = this.usingHistory) === null || _e === void 0 ? void 0 : _e.map(function (history) {
                    var _a;
                    return __assign(__assign({}, history), { ticketHistory: history.ticketHistory ? __assign({}, history.ticketHistory) : null, product: history.product, totalPrice: history.originalPaidPrice, adjustPrice: history.adjustPrice, price: history.productPrice, 
                        // price: history.productPrice + ((history.ticketHistory?.data as any)?.deductionPrice || 0),
                        amount: history.amount, salesPerManagers: (_a = history.salesPerManagers) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                            return __assign(__assign({}, item), { sharePrice: item.sharePrice });
                        }) });
                })) || [],
                methods: methods,
                // usedCustomerPoint: this.methods?.customerPoint,
                // usedPrepaidPoint: this.methods?.prepaidPoint,
                paidAt: this.paidAt,
                prepaidPoints: (_f = this.myPrepaidHistory) === null || _f === void 0 ? void 0 : _f.map(function (item) {
                    var _a, _b;
                    return {
                        ticketId: item.ticketId,
                        deduction: ((_a = item.data) === null || _a === void 0 ? void 0 : _a.deduction) || 0,
                        data: item.data,
                        name: ((_b = item.ticket) === null || _b === void 0 ? void 0 : _b.name) || ''
                    };
                }),
                sharedPrepaidPoints: (_g = this.sharedPrepaidHistory) === null || _g === void 0 ? void 0 : _g.map(function (item) {
                    var _a, _b;
                    return {
                        ticketId: item.ticketId,
                        deduction: ((_a = item.data) === null || _a === void 0 ? void 0 : _a.deduction) || 0,
                        data: item.data,
                        name: ((_b = item.ticket) === null || _b === void 0 ? void 0 : _b.name) || ''
                    };
                })
            };
        },
        enumerable: false,
        configurable: true
    });
    // get paymentHistoryForm(): PaymentHistoryForm.FormData {
    //   const ticket = this.ticket;
    //   const methods = {} as any;
    //   Object.keys(this.methods || {})
    //     .filter((key) => ['card', 'cash', 'transfer', 'unpaid', 'discount'].includes(key))
    //     .map((key) => {
    //       const methodsValue = this.methods[key];
    //       if (methodsValue) {
    //         methods[key] = { checked: true, value: methodsValue };
    //       }
    //     });
    //   return {
    //     ...this,
    //     finalPrice: this.totalPrice,
    //     customer: {
    //       type: 'customer',
    //       id: this.customerId
    //     },
    //     // 정기권 구매 시
    //     ticketTemplateId: ticket
    //       ? ([ticket.copiedTicketTemplate?.type, ticket.copiedTicketTemplate?.id] as any)
    //       : (undefined as any),
    //     ticket: ticket
    //       ? {
    //           ...ticket,
    //           expiredAt: ticket.expiredAt ? dayjs(ticket.expiredAt) : undefined,
    //           data: ticket.data.startedAt
    //             ? {
    //                 ...ticket.data,
    //                 startedAt: dayjs(ticket.data.startedAt),
    //                 expiredAt: dayjs(ticket.data.expiredAt)
    //               }
    //             : {
    //                 ...ticket.data
    //               }
    //         }
    //       : undefined,
    //     // 상품 구매 시
    //     usingHistory:
    //       this.usingHistory?.map((history) => {
    //         return {
    //           ...history,
    //           ...history.product,
    //           totalPrice: history.originalPaidPrice,
    //           adjustPrice: history.adjustPrice,
    //           price: history.price,
    //           amount: history.amount
    //         };
    //       }) || [],
    //     methods,
    //     usedCustomerPoint: this.methods?.customerPoint,
    //     usedPrepaidPoint: this.methods?.prepaidPoint,
    //     paidAt: dayjs(this.paidAt)
    //   } as any;
    // }
    SalesHistoryModel.prototype.getPointRemainBeforeUsing = function (type) {
        var _a, _b;
        var remain = 0;
        var isPrepaid = type === 'prepaid';
        var item = isPrepaid
            ? this.ticketHistory && this.ticketHistory[0]
            : this.customerPoints.find(function (item) { return item.type === CustomerPointModel.POINT_TYPE.USED; });
        if (item) {
            remain = !isPrepaid
                ? item.latestRemain + (item.change || item.deduction || 0)
                : ((_a = item.data) === null || _a === void 0 ? void 0 : _a.remain) + (item.change || ((_b = item.data) === null || _b === void 0 ? void 0 : _b.deduction) || 0);
        }
        return remain;
    };
    SalesHistoryModel.prototype.getPointRemainAfterUsing = function (type) {
        var _a;
        var remain = 0;
        var isPrepaid = type === 'prepaid';
        var prepaid = this.ticketHistory.find(function (item) { var _a; return ((_a = item.ticket) === null || _a === void 0 ? void 0 : _a.type) === TicketModel.TICKET_TYPE.CHARGING_MONEY; });
        var item = isPrepaid
            ? !isEmpty(this.prepaidPoints)
                ? this.prepaidPoints[0]
                : prepaid
            : this.customerPoints.find(function (item) { return item.type === CustomerPointModel.POINT_TYPE.USED; });
        if (item) {
            remain = !isPrepaid
                ? item.latestRemain + (item.change || 0)
                : !isEmpty(this.prepaidPoints)
                    ? item.latestRemain + (item.change || 0)
                    : (_a = item.data) === null || _a === void 0 ? void 0 : _a.totalRemain;
        }
        return remain;
    };
    Object.defineProperty(SalesHistoryModel.prototype, "isUpdated", {
        get: function () {
            return !!this.id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesHistoryModel.prototype, "isUsingHistory", {
        get: function () {
            return !isEmpty(this.usingHistory);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesHistoryModel.prototype, "hasExpiredOrCompletedPrepaid", {
        get: function () {
            return (this.isUpdated &&
                !!this.prepaidHistory.find(function (item) { var _a, _b; return !item.ticket || item.ticket.status !== TICKET_STATUS.ACTIVE || !((_b = (_a = item.ticket) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.remain); }));
        },
        enumerable: false,
        configurable: true
    });
    // get isDisableUpdateCustomerPoint(): boolean {}
    SalesHistoryModel.getSalesMethodLabel = function (key, store) {
        var _a;
        var paymentMethods = store.paymentMethodsSetting;
        return ((_a = paymentMethods[key]) === null || _a === void 0 ? void 0 : _a.name) || EXTENDED_SALES_MEHOD_KOREAN[key];
    };
    SalesHistoryModel.PAYMENT_STATUS = SALES_STATUS;
    SalesHistoryModel.PAYMENT_MEHOD = SALES_METHODS;
    SalesHistoryModel.PAYMENT_LOCATION = SALES_LOCATION;
    SalesHistoryModel.PAYMENT_REMITTANCE_STATUS = SALES_REMITTANCE_STATUS;
    SalesHistoryModel.PAID_ELEMENTS = ['card', 'transfer', 'cash', 'unpaid', 'naverPay', 'etc1', 'etc2', 'etc3', 'etc4', 'etc5'];
    return SalesHistoryModel;
}(BaseModel));
export { SalesHistoryModel };
export var getOtherDataFromUsingData = function (using, customer, products) {
    var _a, _b, _c, _d, _e, _f, _g;
    var adjustPrice = 0;
    var adjustValue = 0;
    var adjustOption = using.adjustOption;
    var product = using.product
        ? new StoreProductModel(using.product)
        : (products === null || products === void 0 ? void 0 : products.map(function (item) { return new StoreProductGroupModel(item); }))
            ? getSelectedProductById(products, using === null || using === void 0 ? void 0 : using.productId)
            : undefined;
    var usingPrice = isNumber(using.price) ? using.price : product === null || product === void 0 ? void 0 : product.price;
    var isPlus = adjustOption === null || adjustOption === void 0 ? void 0 : adjustOption.includes('plus');
    var totalPrice = usingPrice * using.amount;
    if (!adjustOption) {
        adjustPrice = 0;
        adjustValue = 0;
    }
    else {
        adjustValue = using.adjustValue;
        if (adjustOption === null || adjustOption === void 0 ? void 0 : adjustOption.includes('Percent')) {
            adjustPrice = (totalPrice / 100) * (adjustValue || 0);
        }
        else {
            adjustPrice = adjustValue || 0;
        }
    }
    adjustPrice = isPlus ? adjustPrice : -adjustPrice;
    var ticket = getTicket(customer, (_a = using.ticketHistory) === null || _a === void 0 ? void 0 : _a.ticketId) || ((_b = using.ticketHistory) === null || _b === void 0 ? void 0 : _b.ticket);
    var deductionPriceByTicket = ((_c = using.ticketHistory) === null || _c === void 0 ? void 0 : _c.ticketId) ? usingPrice * using.amount : 0;
    var finalPrice = (Lodash.floor(usingPrice * using.amount + (adjustPrice || 0)) || 0) - deductionPriceByTicket;
    var newSalesPerManager = ((_d = using.salesPerManagers) === null || _d === void 0 ? void 0 : _d.filter(function (manger) { return manger.managerId; })) || [];
    var total = deductionPriceByTicket + finalPrice;
    var salesPerManagers = newSalesPerManager.map(function (manager, index) {
        // const shareRate =
        var sharePrice = manager.sharePrice ||
            (manager.methods && sum(Object.keys(manager.methods).map(function (key) { return manager.methods[key] || 0; })));
        var sharePriceTotal = sum(newSalesPerManager.map(function (manager) {
            return (manager.sharePrice ||
                (manager.methods && sum(Object.keys(manager.methods).map(function (key) { return manager.methods[key] || 0; }))));
        }));
        var ceilOrFloor = index === 0 ? floor : floor;
        return {
            managerId: manager.managerId,
            // shareRate: 50,
            sharePrice: ceilOrFloor(total * (sharePrice / sharePriceTotal) || 0)
        };
    });
    var diff = total - sum(salesPerManagers.map(function (item) { return item.sharePrice || 0; }));
    if (diff > 0) {
        if (isEmpty(salesPerManagers)) {
        }
        else {
            times(diff).map(function (item) {
                var _a;
                var targetKey = item % 2;
                var targetValue = ((_a = salesPerManagers[targetKey]) === null || _a === void 0 ? void 0 : _a.sharePrice) || 0;
                if (salesPerManagers[targetKey]) {
                    salesPerManagers[targetKey].sharePrice = targetValue + 1;
                }
            });
        }
    }
    return __assign(__assign({}, using), { finalPrice: finalPrice, adjustPrice: adjustPrice, adjustValue: adjustValue, 
        // 없을수도 있음
        product: product, deductionPriceByTicket: ((_e = using.ticketHistory) === null || _e === void 0 ? void 0 : _e.ticketId) ? usingPrice * using.amount : 0, salesPerManagers: salesPerManagers, ticketHistory: ((_f = using.ticketHistory) === null || _f === void 0 ? void 0 : _f.ticketId) && ticket
            ? __assign(__assign({}, using.ticketHistory), { ticket: ticket, data: { deduction: using.amount, remain: ((_g = ticket === null || ticket === void 0 ? void 0 : ticket.data) === null || _g === void 0 ? void 0 : _g.remain) || 0 } }) : {} });
};
export var getOtherDataFromTicket = function (ticket) {
    var _a, _b, _c, _d, _e, _f, _g;
    var newSalesPerManager = ((_a = ticket === null || ticket === void 0 ? void 0 : ticket.salesPerManagers) === null || _a === void 0 ? void 0 : _a.filter(function (manger) { return manger.managerId; })) || [];
    var template = ticket === null || ticket === void 0 ? void 0 : ticket.copiedTicketTemplate;
    var discount = ((_c = (_b = template === null || template === void 0 ? void 0 : template.data) === null || _b === void 0 ? void 0 : _b.benefit) === null || _c === void 0 ? void 0 : _c.type) === TICKET_BENEFIT_TYPE.DISCOUNT ? ((_e = (_d = template === null || template === void 0 ? void 0 : template.data) === null || _d === void 0 ? void 0 : _d.benefit) === null || _e === void 0 ? void 0 : _e.amount) || 0 : 0;
    var totalPrice = (((_f = ticket === null || ticket === void 0 ? void 0 : ticket.copiedTicketTemplate) === null || _f === void 0 ? void 0 : _f.price) || 0) - (discount || 0);
    var salesPerManagers = newSalesPerManager === null || newSalesPerManager === void 0 ? void 0 : newSalesPerManager.map(function (spm, index) {
        // const shareRate =
        var sharePrice = spm.sharePrice ||
            (spm.methods &&
                sum(Object.keys(spm.methods).map(function (key) { var _a; return isNumber(spm.methods[key]) ? spm.methods[key] : ((_a = spm.methods[key]) === null || _a === void 0 ? void 0 : _a.deduction) || 0; }))) ||
            0;
        var sharePriceTotal = sum((newSalesPerManager === null || newSalesPerManager === void 0 ? void 0 : newSalesPerManager.map(function (manager) {
            return (manager.sharePrice ||
                (manager.methods && sum(Object.keys(manager.methods).map(function (key) { return manager.methods[key] || 0; }))));
        })) || 0) || 0;
        var ceilOrFloor = index === 0 ? floor : floor;
        return {
            managerId: spm.managerId,
            // shareRate: 50,
            sharePrice: newSalesPerManager.length === 1
                ? totalPrice
                : sharePriceTotal
                    ? ceilOrFloor(totalPrice * (sharePrice / sharePriceTotal) || 0)
                    : index === 0
                        ? totalPrice
                        : 0
        };
    });
    var diff = totalPrice - (sum(salesPerManagers.map(function (item) { return item.sharePrice || 0; })) || 0);
    if (diff > 0) {
        if (isEmpty(salesPerManagers)) {
        }
        else {
            times(diff).map(function (item) {
                var _a;
                var targetKey = item % 2;
                var targetValue = ((_a = salesPerManagers[targetKey]) === null || _a === void 0 ? void 0 : _a.sharePrice) || 0;
                if (salesPerManagers[targetKey]) {
                    salesPerManagers[targetKey].sharePrice = targetValue + 1;
                }
            });
        }
    }
    return __assign(__assign({}, ticket), { copiedTicketTemplate: __assign(__assign({}, ticket === null || ticket === void 0 ? void 0 : ticket.copiedTicketTemplate), { benefitString: (_g = ticket === null || ticket === void 0 ? void 0 : ticket.copiedTicketTemplate) === null || _g === void 0 ? void 0 : _g.benefitString, totalPrice: totalPrice }), finalPrice: totalPrice, salesPerManagers: salesPerManagers });
};
var getSelectedProductById = function (productGroupQuery, id) {
    var product;
    productGroupQuery === null || productGroupQuery === void 0 ? void 0 : productGroupQuery.map(function (group) {
        var _a;
        var temp = (_a = group === null || group === void 0 ? void 0 : group.products) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.id === Number(id); });
        if (temp) {
            product = new StoreProductModel(__assign(__assign({}, temp), { productGroup: group }));
        }
    });
    return product;
};
var getTicket = function (customer, ticketId) {
    return customer === null || customer === void 0 ? void 0 : customer.activeTicketsWithoutCharging.find(function (ticket) { return ticket.id === ticketId; });
};
