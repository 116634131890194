var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Cookies } from 'react-cookie';
var SYMBOL_INSTANCE = Symbol('singleton');
var ThinkofyouCookie = /** @class */ (function () {
    function ThinkofyouCookie() {
        this.cookie = new Cookies();
    }
    ThinkofyouCookie.prototype.get = function (name, option) {
        return this.cookie.get(name, option);
    };
    ThinkofyouCookie.prototype.set = function (name, value, options) {
        return this.cookie.set(name, value, __assign({ path: ThinkofyouCookie.path }, options));
    };
    ThinkofyouCookie.prototype.remove = function (name, options) {
        return this.cookie.remove(name, __assign({ path: ThinkofyouCookie.path }, options));
    };
    ThinkofyouCookie.getInstance = function () {
        var instanceDescriptor = Object.getOwnPropertyDescriptor(this, SYMBOL_INSTANCE);
        var instance = instanceDescriptor ? instanceDescriptor.value : new this();
        if (!instanceDescriptor) {
            Object.defineProperty(this, SYMBOL_INSTANCE, { value: instance });
        }
        return instance;
    };
    ThinkofyouCookie.path = '/';
    return ThinkofyouCookie;
}());
export { ThinkofyouCookie };
