var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { get } from 'renderer/utils';
export function getErrorText(key, errorObject) {
    var error = get(errorObject, key);
    return error && error.message;
}
export function getIsError(key, errorObject) {
    var error = get(errorObject, key);
    return !!error;
}
export var getSearchParams = function (search) {
    return new URLSearchParams(search);
};
export var searchToObject = function (search) {
    var entries = getSearchParams(search);
    return Array.from(entries).reduce(function (o, i) {
        var _a;
        return (__assign(__assign({}, o), (_a = {}, _a[i[0]] = i[1], _a)));
    }, {});
};
export var checkBizNumber = function (value) {
    var isValid = false;
    var numberMap = value
        .replace(/-/gi, '')
        .split('')
        .map(function (d) {
        return parseInt(d, 10);
    });
    if (numberMap.length == 10) {
        var keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
        var chk_1 = 0;
        keyArr.forEach(function (d, i) {
            chk_1 += d * numberMap[i];
        });
        chk_1 += parseInt(((keyArr[8] * numberMap[8]) / 10), 10);
        isValid = Math.floor(numberMap[9]) === (10 - (chk_1 % 10)) % 10;
    }
    return isValid;
};
