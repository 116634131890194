var _a;
import { generateMedia } from 'styled-media-query';
var mediaQuery = !!((_a = window.Plussharp) === null || _a === void 0 ? void 0 : _a.isApp)
    ? generateMedia({
        desktop: '9999px',
        tablet: '600px',
        mobile: '599px'
    })
    : generateMedia({
        desktop: '1024px',
        tablet: '600px',
        mobile: '599px'
    });
export var tabletWithMobile = mediaQuery.lessThan('desktop');
