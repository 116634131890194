var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomerModel } from './CustomerModel';
import { BaseModel } from './BaseModel';
import { KRWFomatToString } from 'renderer/components';
var POINT_TYPE;
(function (POINT_TYPE) {
    POINT_TYPE["USED"] = "used";
    POINT_TYPE["CREATED"] = "created";
    POINT_TYPE["EXPIRED"] = "expired";
    POINT_TYPE["CANCEL_CREATED"] = "cancel_created";
    POINT_TYPE["CANCEL_USED"] = "cancel_used";
})(POINT_TYPE || (POINT_TYPE = {}));
var CustomerPointModel = /** @class */ (function (_super) {
    __extends(CustomerPointModel, _super);
    function CustomerPointModel(props) {
        var _this = _super.call(this, props) || this;
        _this.customer = props.customer && new CustomerModel(props.customer);
        return _this;
    }
    Object.defineProperty(CustomerPointModel.prototype, "typeToString", {
        get: function () {
            // if (this.isCancel) {
            //   return '결제 취소';
            // }
            switch (this.type) {
                case POINT_TYPE.USED:
                case POINT_TYPE.EXPIRED:
                case POINT_TYPE.CANCEL_CREATED:
                case POINT_TYPE.EXPIRED:
                    return '사용';
                case POINT_TYPE.CREATED:
                case POINT_TYPE.CANCEL_USED:
                    return '적립';
                // return '만료';
                // return '충전 취소';
                // return '사용 취소';
                // return '사용 취소';
                default:
                    return '기타';
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerPointModel.prototype, "changeString", {
        get: function () {
            return this.isPlus
                ? "+".concat(KRWFomatToString({ value: this.change }))
                : "-".concat(KRWFomatToString({ value: this.change }));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerPointModel.prototype, "isPlus", {
        get: function () {
            return [POINT_TYPE.CREATED, POINT_TYPE.CANCEL_USED].includes(this.type);
        },
        enumerable: false,
        configurable: true
    });
    CustomerPointModel.POINT_TYPE = POINT_TYPE;
    return CustomerPointModel;
}(BaseModel));
export { CustomerPointModel };
