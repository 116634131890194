var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from './BaseModel';
import { StoreProductModel } from './StoreProductModel';
import { TicketHistoryModel } from './TicketHistoryModel';
import { ManagerModel } from './ManagerModel';
import { KRWFomatToString } from 'renderer/components';
import { SalesPerManagerModel } from './SalesPerManagerModel';
var UsingHistoryModel = /** @class */ (function (_super) {
    __extends(UsingHistoryModel, _super);
    function UsingHistoryModel(props) {
        var _this = this;
        var _a;
        _this = _super.call(this, props) || this;
        _this.product = props.product && new StoreProductModel(props.product);
        _this.manager = props.manager && new ManagerModel(props.manager);
        _this.ticketHistory = props.ticketHistory && new TicketHistoryModel(props.ticketHistory);
        _this.salesPerManagers = (_a = props.salesPerManagers) === null || _a === void 0 ? void 0 : _a.map(function (item) { return new SalesPerManagerModel(item); });
        return _this;
    }
    Object.defineProperty(UsingHistoryModel.prototype, "benefitString", {
        get: function () {
            switch (this.adjustOption) {
                case 'plusAmount':
                    return "(\uCD94\uAC00) ".concat(KRWFomatToString({ value: this.adjustPrice }));
                case 'plusPercent':
                    return "(\uCD94\uAC00) ".concat(this.adjustValue, "%");
                case 'minusAmount':
                    return "(\uD560\uC778) ".concat(KRWFomatToString({ value: this.adjustPrice }));
                case 'plusPercent':
                    return "(\uD560\uC778) -".concat(this.adjustValue, "%");
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UsingHistoryModel.prototype, "price", {
        get: function () {
            return this.finalPrice + this.deductionPriceByTicket;
            // return this.product?.price || 0 + this.adjustPrice;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UsingHistoryModel.prototype, "productPrice", {
        get: function () {
            // return (this.finalPrice - this.adjustPrice) / this.amount;
            return (this.finalPrice - this.adjustPrice + (this.deductionPriceByTicket || 0)) / this.amount;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UsingHistoryModel.prototype, "usingContentToString", {
        get: function () {
            var _a, _b, _c, _d;
            var managerString = ((_a = this.manager) === null || _a === void 0 ? void 0 : _a.displayName) || '담당자 없음';
            var productName = (this.product && "(".concat((_c = (_b = this.product) === null || _b === void 0 ? void 0 : _b.productGroup) === null || _c === void 0 ? void 0 : _c.name, ") ").concat((_d = this.product) === null || _d === void 0 ? void 0 : _d.name)) || '이용 상품 없음';
            return "".concat(managerString, " : ").concat(productName);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UsingHistoryModel.prototype, "originalPaidPrice", {
        get: function () {
            return (this.price || 0) - (this.adjustPrice || 0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UsingHistoryModel.prototype, "isUsingHistory", {
        get: function () {
            return true;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UsingHistoryModel.prototype, "type", {
        get: function () {
            var _a, _b;
            return ((_b = (_a = this.product) === null || _a === void 0 ? void 0 : _a.productGroup) === null || _b === void 0 ? void 0 : _b.type) === 'product' ? 'product' : 'service';
        },
        enumerable: false,
        configurable: true
    });
    return UsingHistoryModel;
}(BaseModel));
export { UsingHistoryModel };
// finalPrice -> 최종 결제할 금액 (가격 * 수량 - 정기권 차감량 + 조정량)
//
