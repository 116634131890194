import { useState, useEffect } from 'react';
function useTimezoneOffset() {
    var _a = useState(new Date().getTimezoneOffset()), offset = _a[0], setOffset = _a[1];
    var _b = useState(Intl.DateTimeFormat().resolvedOptions().timeZone), timezone = _b[0], setTimezone = _b[1];
    useEffect(function () {
        var interval = setInterval(function () {
            var newOffset = new Date().getTimezoneOffset();
            if (offset !== newOffset) {
                setOffset(newOffset);
                setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
            }
        }, 60000); // 1분마다 확인
        return function () { return clearInterval(interval); };
    }, [offset]);
    var diffFromKorean = (-540 - (offset || 0)) / 60;
    return { offset: offset, timezone: timezone, diffFromKorean: diffFromKorean };
}
export default useTimezoneOffset;
