import React from 'react';
import { useLocation } from 'react-router';
export * from './promise';
export * from './interval';
export * from './event';
export * from './localstoreage';
export * from './window';
export * from './click';
export * from './hover';
export var useDebounce = function (value, delay) {
    // State and setters for debounced value
    var _a = React.useState(value), debouncedValue = _a[0], setDebouncedValue = _a[1];
    React.useEffect(function () {
        // Update debounced value after delay
        var handler = setTimeout(function () {
            setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return function () {
            clearTimeout(handler);
        };
    }, [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
};
export function useURLQuery() {
    return new URLSearchParams(useLocation().search);
}
