import memoize from 'lodash-es/memoize';
import { Backoff, FibonacciStrategy } from 'backoff';
export var ClientState;
(function (ClientState) {
    ClientState[ClientState["READY"] = 0] = "READY";
    ClientState[ClientState["CONNECTING"] = 1] = "CONNECTING";
    ClientState[ClientState["CONNECTED"] = 2] = "CONNECTED";
    ClientState[ClientState["RECONNECTING"] = 3] = "RECONNECTING";
    ClientState[ClientState["CLOSING"] = 4] = "CLOSING";
    ClientState[ClientState["CLOSED"] = 5] = "CLOSED";
})(ClientState || (ClientState = {}));
var WebSocketClient = /** @class */ (function () {
    function WebSocketClient(url, options) {
        this.listeners = {
            data: new Set(),
            status: new Set()
        };
        this.status = ClientState.READY;
        this.options = options;
        this.url = url;
        this.init();
    }
    WebSocketClient.prototype.init = function () {
        var _this = this;
        var _a;
        // prepare reconnect backoff strategy
        if (!this.reconnectBackoff) {
            this.reconnectBackoff = new Backoff(new FibonacciStrategy({
                randomisationFactor: 0,
                initialDelay: 500,
                maxDelay: 60000
            }));
            this.reconnectBackoff.failAfter(10);
            this.reconnectBackoff.on('backoff', function (error) {
                _this.status = ClientState.RECONNECTING;
            });
            this.reconnectBackoff.on('fail', function (error) {
                _this.status = ClientState.CLOSED;
            });
            this.reconnectBackoff.on('ready', function (value, delay) {
                _this.init();
            });
        }
        this.ws = new WebSocket(this.url, (_a = this.options) === null || _a === void 0 ? void 0 : _a.protocols);
        this.status = ClientState.CONNECTING;
        this.ws.addEventListener('open', function (ev) {
            _this.status = ClientState.CONNECTED;
            if (_this.reconnectBackoff) {
                _this.reconnectBackoff.reset();
            }
        });
        this.ws.addEventListener('close', function (ev) {
            if (_this.reconnectBackoff) {
                try {
                    return _this.reconnectBackoff.backoff();
                }
                catch (_a) {
                    // ignore error
                }
            }
            _this.status = ClientState.CLOSED;
        });
        this.ws.addEventListener('error', function (ev) {
            _this.status = ClientState.CLOSED;
        });
        this.ws.addEventListener('message', function (ev) { });
    };
    WebSocketClient.prototype.addListener = function (event, listener, useCapture) {
        if (event === 'data' || event === 'status') {
            this.listeners[event].add(listener);
        }
        else {
            this.ws.addEventListener(event, listener, useCapture);
        }
    };
    WebSocketClient.prototype.removeListener = function (event, listener) {
        if (event === 'data' || event === 'status') {
            this.listeners[event].delete(listener);
        }
        else {
            this.ws.removeEventListener(event, listener);
        }
    };
    WebSocketClient.prototype.clearListeners = function () {
        this.listeners = { data: new Set(), status: new Set() };
    };
    WebSocketClient.prototype.emitEvent = function (type, payload, event) {
        this.listeners[type].forEach(function (listener) { return listener(payload, event); });
    };
    WebSocketClient.prototype.close = function () {
        // remove backoff to cancel or disable reconnect
        if (this.reconnectBackoff) {
            this.reconnectBackoff.reset();
            delete this.reconnectBackoff;
        }
        if (this.status === ClientState.CLOSED || this.status === ClientState.CLOSING) {
            console.warn('WebSocket is already closed!');
            return;
        }
        this.ws.close();
    };
    WebSocketClient.prototype.open = function () {
        var _a;
        this.ws = new WebSocket(this.url, (_a = this.options) === null || _a === void 0 ? void 0 : _a.protocols);
    };
    WebSocketClient.prototype.send = function (data) {
        this.ws.send(JSON.stringify(data));
    };
    Object.defineProperty(WebSocketClient.prototype, "ws", {
        get: function () {
            return this._ws;
        },
        set: function (socket) {
            this._ws = socket;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WebSocketClient.prototype, "status", {
        get: function () {
            return this._status;
        },
        set: function (_status) {
            this._status = _status;
            this.emitEvent('status', _status);
        },
        enumerable: false,
        configurable: true
    });
    // tslint:disable-next-line:variable-name
    WebSocketClient.Status = ClientState;
    WebSocketClient.getInstance = memoize(function (url, options) {
        return new WebSocketClient(url, options);
    });
    return WebSocketClient;
}());
export { WebSocketClient };
