var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ATS_FEE, FTS_FEE, FMS_FEE, SMS_FEE, LMS_FEE, MMS_FEE } from 'renderer/constants';
import { BaseModel } from './BaseModel';
import * as dayjs from 'dayjs';
import { PhoneNumberUtils } from 'renderer/utils';
import { maskPhoneNumberByPermission } from 'renderer/queries/manager';
export var FEE_OBJECT = {
    ats: ATS_FEE,
    fts: FTS_FEE,
    fms: FMS_FEE,
    sms: SMS_FEE,
    lms: LMS_FEE,
    mms: MMS_FEE
};
export var MESSAGE_TYPE_OBJECT = {
    sms: { label: 'SMS', price: SMS_FEE },
    lms: { label: 'LMS', price: LMS_FEE },
    mms: { label: 'MMS', price: MMS_FEE },
    fts: { label: '친구톡', price: FTS_FEE },
    fms: { label: '친구톡(이미지)', price: FMS_FEE },
    ats: { label: '알림톡', price: ATS_FEE }
};
// https://docs.popbill.com/kakao/sendCode?lang=node
export var MESSAGE_STATUS = {
    0: {
        description: 'API 요청이 팝빌 시스템에 접수된 상태'
    },
    1: {
        description: '전송요청 전 대기 상태'
    },
    2: {
        description: '전송요청 후 처리결과 수신 대기중'
    },
    3: {
        description: '처리완료, 전송결과코드에 따라 전송상태확인'
    },
    4: {
        description: '예약건 취소'
    }
};
export var MESSAGE_RESULT = {
    100: {
        description: '전송성공'
    }
};
var StoreMessageModel = /** @class */ (function (_super) {
    __extends(StoreMessageModel, _super);
    function StoreMessageModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(StoreMessageModel.prototype, "isGroup", {
        get: function () {
            return this.type === 'raw' || (this.type === 'group' && !this.raw);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreMessageModel.prototype, "isMessage", {
        get: function () {
            return ['sms', 'lms', 'mms'].includes(this.messageType);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreMessageModel.prototype, "fee", {
        get: function () {
            return FEE_OBJECT[this.messageType];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreMessageModel.prototype, "sendTypeString", {
        get: function () {
            var _a;
            return ((_a = this.trigger) === null || _a === void 0 ? void 0 : _a.type) === 'manual' ? '직접 발송' : '자동 발송';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreMessageModel.prototype, "messageTypeString", {
        get: function () {
            return MESSAGE_TYPE_OBJECT[this.messageType].label;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreMessageModel.prototype, "isReserved", {
        get: function () {
            var _a, _b;
            return (this.status === 'requested' &&
                ((((_a = this.result) === null || _a === void 0 ? void 0 : _a.reserveDT) && dayjs((_b = this.result) === null || _b === void 0 ? void 0 : _b.reserveDT).isAfter(new Date())) ||
                    (this.sendAt && dayjs(this.sendAt).isAfter(new Date()))));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreMessageModel.prototype, "receiverToString", {
        get: function () {
            var _a;
            var receiver = (_a = this.raw) === null || _a === void 0 ? void 0 : _a.receiver;
            if (!receiver) {
                return '';
            }
            if (receiver.length < 5) {
                return receiver;
            }
            try {
                return maskPhoneNumberByPermission(PhoneNumberUtils.parsePhoneNumber("+82 ".concat(receiver)).formatNational());
            }
            catch (err) {
                return receiver;
            }
        },
        enumerable: false,
        configurable: true
    });
    StoreMessageModel.receiverToString = function (receiver) {
        if (!receiver) {
            return '';
        }
        if (receiver.length < 5) {
            return receiver;
        }
        try {
            return maskPhoneNumberByPermission(PhoneNumberUtils.parsePhoneNumber("+82 ".concat(receiver)).formatNational());
        }
        catch (err) {
            return receiver;
        }
    };
    Object.defineProperty(StoreMessageModel.prototype, "statusString", {
        // 언제가 예약일까??
        get: function () {
            var _a, _b, _c, _d, _e;
            return this.status === 'failure' && !((_a = this.point) === null || _a === void 0 ? void 0 : _a.charged)
                ? '실패(포인트부족)'
                : this.status === 'failure'
                    ? '실패'
                    : this.status === 'cancelled'
                        ? this.receiptNum
                            ? '취소'
                            : '취소(광고)'
                        : this.status === 'rejected'
                            ? '실패(수신거부)'
                            : (!this.result || [0, 1, 2].includes(this.result.state)) && this.status === 'requested'
                                ? this.isReserved
                                    ? '예약'
                                    : '접수'
                                : this.result.state === 4
                                    ? '취소'
                                    : this.result.result === 100
                                        ? '성공'
                                        : [409, 507, 800, 802].includes(this.result.result)
                                            ? '실패(수신거부)'
                                            : [500, 503, 506, 801].includes(this.result.result)
                                                ? '실패(광고)'
                                                : ((_b = this.result) === null || _b === void 0 ? void 0 : _b.result) === 999 && !!((_c = this.result) === null || _c === void 0 ? void 0 : _c.altResult)
                                                    ? ((_e = (_d = this.result) === null || _d === void 0 ? void 0 : _d.altResult) === null || _e === void 0 ? void 0 : _e.result) === 100
                                                        ? '대체(성공)'
                                                        : '대체(실패)'
                                                    : '실패';
        },
        enumerable: false,
        configurable: true
    });
    return StoreMessageModel;
}(BaseModel));
export { StoreMessageModel };
