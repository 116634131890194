var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var _a;
import { BaseModel } from './BaseModel';
export var INVOICE_STATUS;
(function (INVOICE_STATUS) {
    // 입금 대기 혹은 보류 상태
    INVOICE_STATUS["PENDING"] = "pending";
    // 입금 예정 (기본값)
    INVOICE_STATUS["SCHEDULED"] = "scheduled";
    // 입금 완료
    INVOICE_STATUS["COMPLETED"] = "completed";
    // 입금 취소
    INVOICE_STATUS["CANCELLED"] = "cancelled";
})(INVOICE_STATUS || (INVOICE_STATUS = {}));
export var INVOICE_STATUS_STRING = (_a = {},
    // 입금 대기 혹은 보류 상태
    _a[INVOICE_STATUS.PENDING] = '입금 대기',
    _a[INVOICE_STATUS.SCHEDULED] = '입금 예정',
    _a[INVOICE_STATUS.COMPLETED] = '입금 완료',
    _a[INVOICE_STATUS.CANCELLED] = '입금 취소',
    _a);
var InvoiceModel = /** @class */ (function (_super) {
    __extends(InvoiceModel, _super);
    function InvoiceModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(InvoiceModel.prototype, "statusToString", {
        get: function () {
            return INVOICE_STATUS_STRING[this.status];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InvoiceModel.prototype, "paymentString", {
        get: function () {
            var _a, _b, _c;
            return "".concat((_a = this.paymentInfo) === null || _a === void 0 ? void 0 : _a.bankName, " ").concat((_b = this.paymentInfo) === null || _b === void 0 ? void 0 : _b.accountNumber, "(").concat((_c = this.paymentInfo) === null || _c === void 0 ? void 0 : _c.owner, ")");
        },
        enumerable: false,
        configurable: true
    });
    return InvoiceModel;
}(BaseModel));
export { InvoiceModel };
