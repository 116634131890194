var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery, useMutation } from 'react-query';
import { BaseModel } from 'renderer/models';
import { RestClient } from '../utils/RestClient';
export function usePostMutation(config) {
    var _a = config || {}, url = _a.url, rest = __rest(_a, ["url"]);
    return useMutation(function (payload) {
        return RestClient.getInstance()
            .post(url, payload)
            .then(function (response) {
            return BaseModel.build(response.data);
        });
    }, rest);
}
export function usePutMutation(config) {
    var _a = config || {}, url = _a.url, rest = __rest(_a, ["url"]);
    return useMutation(function (payload) {
        return RestClient.getInstance()
            .put(url, payload)
            .then(function (response) {
            return BaseModel.build(response.data);
        });
    }, rest);
}
export function useRemoveMutation(config) {
    var _a = config || {}, url = _a.url, rest = __rest(_a, ["url"]);
    return useMutation(function () {
        return RestClient.getInstance()
            .remove(url)
            .then(function (response) {
            return BaseModel.build(response.data);
        });
    }, rest);
}
export function useGetQueryItems(queryKey, options) {
    var url = options.url, rest = __rest(options, ["url"]);
    return useQuery(queryKey, function () {
        return RestClient.getInstance()
            .post(url)
            .then(function (response) {
            return response.data.map(function (item) { return BaseModel.build(response.data); });
        });
    }, options);
}
export function getItems(queryKey, path, requestOption, options) {
    return useQuery(queryKey, function () {
        return RestClient.getInstance()
            .get(path, requestOption)
            .then(function (response) {
            return response.data.map(function (item) { return BaseModel.build(item); });
        });
    }, options);
}
export function postMutation(options) {
    return useMutation(function (payload) {
        return RestClient.getInstance()
            .post(payload.url, payload.payload, payload.options)
            .then(function (response) {
            return response.data.map(function (item) { return BaseModel.build(item); });
        });
    }, options);
}
