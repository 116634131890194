var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation, useQuery } from 'react-query';
import { StoreReviewModel } from '../models/StoreReviewModel';
import { QUERY_CACHE } from 'renderer/queries';
import { RestClient, usePager } from 'renderer/utils';
export var useGetReviewById = function (reviewId, config) {
    return useQuery(['getReviewById', reviewId], function () {
        return RestClient.getInstance()
            .get("/reviews/".concat(reviewId))
            .then(function (result) {
            return new StoreReviewModel(result.data);
        })
            .catch(function (err) {
            console.log('rerrr netword', err);
        });
    }, __assign(__assign({}, config), { staleTime: 60000 }));
};
export var useRequestReview = function (config) {
    return useMutation(function (payload) {
        return RestClient.getInstance()
            .post("/reviews", payload)
            .then(function (result) { return new StoreReviewModel(result.data); });
    }, {
        onSuccess: function (data) {
            QUERY_CACHE.invalidateQueries(['storePoint']);
        }
    });
};
export var useUpdateScreenshot = function (config) {
    return useMutation(function (payload) {
        return RestClient.getInstance()
            .put("/reviews/".concat(payload.id, "/upload"), payload)
            .then(function (result) { return new StoreReviewModel(result.data); });
    }, __assign({}, config));
};
export var useAdminReviewHistoryPager = function (query, config) {
    return usePager('adminReviewHistory', query, function (options) {
        var _a = options.queryKey, _ = _a[0], params = _a[1];
        return RestClient.getInstance()
            .get("/reviews", { params: params })
            .then(function (result) { return result.data; });
    }, StoreReviewModel, config);
};
export var useUpdateReviewStatusForAdmin = function (config) {
    return useMutation(function (payload) {
        return RestClient.getInstance()
            .put("/reviews/".concat(payload.id, "/status"), payload)
            .then(function (result) { return new StoreReviewModel(result.data); });
    }, __assign(__assign({}, config), { onSuccess: function () { return QUERY_CACHE.invalidateQueries('adminReviewHistory'); } }));
};
