import React from "react";
export function useHover() {
    var _a = React.useState(false), value = _a[0], setValue = _a[1];
    var ref = React.useRef(null);
    var handleMouseOver = function () { return setValue(true); };
    var handleMouseOut = function () { return setValue(false); };
    React.useEffect(function () {
        var node = ref.current;
        if (node) {
            node.addEventListener('mouseover', handleMouseOver);
            node.addEventListener('mouseleave', handleMouseOut);
            return function () {
                node.removeEventListener('mouseover', handleMouseOver);
                node.removeEventListener('mouseleave', handleMouseOut);
            };
        }
    }, [ref.current] // Recall only if ref changes
    );
    return [ref, value];
}
