var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// eslint-disable-next-line no-unused-vars
import axios from 'axios';
import set from 'lodash/set';
import { AWS_LAMBDA_BASE_URL } from 'renderer/constants';
var SYMBOL_INSTANCE = Symbol('singleton');
var LambdaClient = /** @class */ (function () {
    /**
     * Creates an instance of ApiClient.
     *
     * @memberOf ApiClient
     */
    function LambdaClient(defaultOptions) {
        this.client = axios.create(__assign({ baseURL: AWS_LAMBDA_BASE_URL, timeout: 20000, headers: { 'Content-Type': 'application/json' } }, defaultOptions));
    }
    LambdaClient.prototype.get = function (path, config) {
        return this.wrapRequest(this.client.get(path, config));
    };
    LambdaClient.prototype.post = function (path, data, config) {
        return this.wrapRequest(this.client.post(path, data, config));
    };
    LambdaClient.prototype.put = function (path, body, config) {
        return this.wrapRequest(this.client.put(path, body, config));
    };
    LambdaClient.prototype.remove = function (path, config) {
        return this.wrapRequest(this.client.delete(path, config));
    };
    LambdaClient.prototype.setErrorHandler = function (handler) {
        this.errorHandler = handler;
    };
    LambdaClient.prototype.wrapRequest = function (promise) {
        var _this = this;
        return Promise.resolve(promise).catch(function (error) {
            var _a, _b;
            if (_this.errorHandler) {
                return Promise.resolve(_this.errorHandler(error));
            }
            throw new Error((_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message);
        });
    };
    LambdaClient.prototype.setAccessToken = function (token) {
        set(this.client.defaults, "headers.Authorization", token ? "Bearer ".concat(token) : null);
    };
    LambdaClient.prototype.getInternalClient = function () {
        return this.client;
    };
    LambdaClient.getInstance = function () {
        var instanceDescriptor = Object.getOwnPropertyDescriptor(this, SYMBOL_INSTANCE);
        var instance = instanceDescriptor ? instanceDescriptor.value : new this();
        if (!instanceDescriptor) {
            Object.defineProperty(this, SYMBOL_INSTANCE, { value: instance });
        }
        return instance;
    };
    return LambdaClient;
}());
export { LambdaClient };
