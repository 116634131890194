import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { APP_VERSION, BUILD_NUMBER, FORM_VALIDATION_ERROR_MESSAGE, NODE_ENV } from 'renderer/constants';
import { LambdaClient } from 'renderer/utils';
var SYMBOL_INSTANCE = Symbol('singleton');
var BugsnagService = /** @class */ (function () {
    function BugsnagService() {
        this.isInit = false;
    }
    BugsnagService.prototype.boot = function () {
        if (this.isInit) {
            return;
        }
        this.isInit = true;
        if (NODE_ENV !== 'production') {
            return;
        }
        Bugsnag.start({
            apiKey: '367a97c7eccc3d58bb337efab3111208090',
            plugins: [new BugsnagPluginReact()],
            onError: function (event) {
                var ignoreErrors = [
                    'ResizeObserver loop limit exceeded',
                    'ResizeObserver loop completed with undelivered notifications',
                    FORM_VALIDATION_ERROR_MESSAGE
                ];
                var ignoreErrorMessagesIncludes = [
                    "'_f' of undefined",
                    "undefined (reading '_f')",
                    "undefined is not an object (evaluating 'n._f')",
                    'e.target.className.includes is not a function',
                    'ResizeObserver loop completed with undelivered notifications'
                ];
                if (event.errors.some(function (error) {
                    return ignoreErrors.includes(error.errorMessage) ||
                        ignoreErrorMessagesIncludes.some(function (msg) { var _a; return (_a = error.errorMessage) === null || _a === void 0 ? void 0 : _a.includes(msg); });
                })) {
                    return false;
                }
            }
        });
    };
    BugsnagService.getInstance = function () {
        var instanceDescriptor = Object.getOwnPropertyDescriptor(this, SYMBOL_INSTANCE);
        var instance = instanceDescriptor ? instanceDescriptor.value : new this();
        if (!instanceDescriptor) {
            Object.defineProperty(this, SYMBOL_INSTANCE, { value: instance });
        }
        return instance;
    };
    BugsnagService.prototype.notify = function (error) {
        if (NODE_ENV !== 'production') {
            return;
        }
        Bugsnag.notify(error);
        LambdaClient.getInstance()
            .post("/messages", { channel: '#error-notification', message: "[\uD504\uB860\uD2B8\uC5D0\uB7EC!]: ".concat(JSON.stringify(error)) }, { headers: { 'Content-Type': 'application/json' } })
            .catch(function () { });
    };
    BugsnagService.prototype.addMetadata = function (store) {
        if (NODE_ENV !== 'production') {
            return;
        }
        Bugsnag.addMetadata('store', store);
        Bugsnag.addMetadata('versions', {
            buildNumber: BUILD_NUMBER,
            appVersion: APP_VERSION
        });
    };
    return BugsnagService;
}());
export { BugsnagService };
