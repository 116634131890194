var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMutation, useQuery } from 'react-query';
import { StorePointModel, CustomerModel, StoreModel, StoreProductGroupModel, StoreSubscriptionModel, ManagerModel, TicketTemplateModel, CustomerGroupModel, InvoiceModel, PaymentHistoryModel, FranchiseInvoiceModel, FranchiseModel, OurProductModel, OurInvoiceModel, AlimtalkTemplateModel } from 'renderer/models';
import { RestClient } from 'renderer/utils';
import { QUERY_CACHE } from 'renderer/queries';
import { usePager } from '../../utils/Pager';
import * as dayjs from 'dayjs';
var getJoinStatitics = function (options) {
    var duration = options.queryKey[1];
    return RestClient.getInstance()
        .get("/admin/stores/statistics/total", { params: duration })
        .then(function (response) {
        return response.data;
    });
};
var getAllStoresWithStatitics = function (options) {
    var _a = options.queryKey, _ = _a[0], pagerOptions = _a[1];
    return RestClient.getInstance()
        .get("/admin/stores/statistics", { params: pagerOptions })
        .then(function (response) {
        return response.data;
    });
};
var updateSMSService = function (payload) {
    return RestClient.getInstance()
        .put("/admin/smsservice", payload)
        .then(function (result) { return result.data; });
};
var resetStore = function (payload) {
    return RestClient.getInstance()
        .post("/admin/stores/reset", payload)
        .then(function (result) { return result.data; });
};
var downloadStoreData = function (payload) {
    var name = payload.name, body = __rest(payload, ["name"]);
    return RestClient.getInstance()
        .post("/admin/export", body, { responseType: 'blob' })
        .then(function (response) {
        // 다운로드(서버에서 전달 받은 데이터) 받은 바이너리 데이터를 blob으로 변환합니다.
        var blob = new Blob([response.data]); // 특정 타입을 정의해야 경우에는 옵션을 사용해 MIME 유형을 정의 할 수 있습니다. // const blob = new Blob([this.content], {type: 'text/plain'}) // blob을 사용해 객체 URL을 생성합니다.
        var fileObjectUrl = window.URL.createObjectURL(blob); // blob 객체 URL을 설정할 링크를 만듭니다.
        var link = document.createElement('a');
        link.href = fileObjectUrl;
        link.style.display = 'none'; // 다운로드 파일 이름을 지정 할 수 있습니다. // 일반적으로 서버에서 전달해준 파일 이름은 응답 Header의 Content-Disposition에 설정됩니다.
        link.download = "".concat(name, "_").concat(dayjs().format('YYYYMMDDhhmmss'), ".xlsx"); // 다운로드 파일 이름을 추출하는 함수
        document.body.appendChild(link);
        link.click();
        link.remove(); // 다운로드가 끝난 리소스(객체 URL)를 해제합니다.
        window.URL.revokeObjectURL(fileObjectUrl);
    });
};
var downloadOurInvoiceData = function (payload) {
    return RestClient.getInstance()
        .post("/admin/ourinvoices/excel", payload, { responseType: 'blob' })
        .then(function (response) {
        // 다운로드(서버에서 전달 받은 데이터) 받은 바이너리 데이터를 blob으로 변환합니다.
        var blob = new Blob([response.data]); // 특정 타입을 정의해야 경우에는 옵션을 사용해 MIME 유형을 정의 할 수 있습니다. // const blob = new Blob([this.content], {type: 'text/plain'}) // blob을 사용해 객체 URL을 생성합니다.
        var fileObjectUrl = window.URL.createObjectURL(blob); // blob 객체 URL을 설정할 링크를 만듭니다.
        var link = document.createElement('a');
        link.href = fileObjectUrl;
        link.style.display = 'none'; // 다운로드 파일 이름을 지정 할 수 있습니다. // 일반적으로 서버에서 전달해준 파일 이름은 응답 Header의 Content-Disposition에 설정됩니다.
        link.download = "\uD305\uCEE4\uBDF0\uACB0\uC81C\uB0B4\uC5ED_".concat(dayjs().format('YYYYMMDDhhmmss'), ".xlsx"); // 다운로드 파일 이름을 추출하는 함수
        document.body.appendChild(link);
        link.click();
        link.remove(); // 다운로드가 끝난 리소스(객체 URL)를 해제합니다.
        window.URL.revokeObjectURL(fileObjectUrl);
    });
};
var postStoreSubscription = function (payload) {
    return RestClient.getInstance()
        .post("/admin/subscriptions", payload)
        .then(function (result) { return new StoreSubscriptionModel(result.data); });
};
var postStorePoint = function (payload) {
    return RestClient.getInstance()
        .post("/admin/point", payload)
        .then(function (result) { return new StorePointModel(result.data); });
};
var checkStore = function (payload) {
    return RestClient.getInstance()
        .post("/admin/migration/store/check", payload)
        .then(function (result) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return {
            store: new StoreModel(result.data.store),
            customers: (_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.customers) === null || _b === void 0 ? void 0 : _b.map(function (item) { return new CustomerModel(item); }),
            managers: (_d = (_c = result.data) === null || _c === void 0 ? void 0 : _c.managers) === null || _d === void 0 ? void 0 : _d.map(function (item) { return new ManagerModel(item); }),
            groups: (_f = (_e = result.data) === null || _e === void 0 ? void 0 : _e.groups) === null || _f === void 0 ? void 0 : _f.map(function (item) { return new CustomerGroupModel(item); }),
            templates: (_h = (_g = result.data) === null || _g === void 0 ? void 0 : _g.templates) === null || _h === void 0 ? void 0 : _h.map(function (item) { return new TicketTemplateModel(item); }),
            productGroupModels: (_k = (_j = result.data) === null || _j === void 0 ? void 0 : _j.productGroupModels) === null || _k === void 0 ? void 0 : _k.map(function (item) { return new StoreProductGroupModel(item); })
        };
    });
};
var migrateProductsAndTemplates = function (payload) {
    return RestClient.getInstance()
        .post("/admin/migration/products", payload)
        .then(function (result) { return result === null || result === void 0 ? void 0 : result.data; });
};
var migrateCustomers = function (payload) {
    return RestClient.getInstance()
        .post("/admin/migration/customers", payload)
        .then(function (result) { return result === null || result === void 0 ? void 0 : result.data; });
};
var migrateSales = function (payload) {
    return RestClient.getInstance()
        .post("/admin/migration/sales", payload)
        .then(function (result) { return result === null || result === void 0 ? void 0 : result.data; });
};
var updateCustomerMeta = function (payload) {
    return RestClient.getInstance()
        .post("/admin/migration/customer/meta", payload)
        .then(function (result) { return result === null || result === void 0 ? void 0 : result.data; });
};
export var useJoinStatitics = function (duration, config) {
    return useQuery(['getJoinStatitics', duration], getJoinStatitics, config);
};
export var useAllStoresWithStatitics = function (duration, config) {
    return usePager(['getAllStoresWithStatitics'], duration, getAllStoresWithStatitics, StoreModel, config);
};
var adminMutationConfig = {
    onSuccess: function () {
        // TODO: 필터에 맞춰서 optimise
        QUERY_CACHE.invalidateQueries(['getAllStoresWithStatitics']);
    }
};
export var useUpdateSMSService = function (config) {
    return useMutation(updateSMSService, config);
};
export var useResetStore = function (config) {
    return useMutation(resetStore, config);
};
export var useDownloadStoreData = function (config) {
    return useMutation(downloadStoreData, config);
};
export var useUpdateStore = function (config) {
    return useMutation(function (payload) {
        return RestClient.getInstance()
            .put("/admin/stores/".concat(payload.id), payload)
            .then(function (response) {
            return new StoreModel(response.data);
        });
    }, config);
};
export var usePostSubscription = function (config) {
    return useMutation(postStoreSubscription, config);
};
export var useUpdatePoint = function (config) {
    return useMutation(postStorePoint, config);
};
export var useCheckStore = function (config) {
    return useMutation(checkStore, config);
};
export var useMigrateCustomers = function (config) {
    return useMutation(migrateCustomers, config);
};
export var useMigrateProductsAndTemplates = function (config) {
    return useMutation(migrateProductsAndTemplates, config);
};
export var useMigrateSales = function (config) {
    return useMutation(migrateSales, config);
};
export var useUpdateCustomerMeta = function (config) {
    return useMutation(updateCustomerMeta, config);
};
export var validateStoreSales = function (storeId) {
    return RestClient.getInstance()
        .get("/admin/migration/sales/".concat(storeId))
        .then(function (result) { return result === null || result === void 0 ? void 0 : result.data; });
};
export var useStoreInvoices = function (query, config) {
    return usePager('invoices', query, function (params) {
        return RestClient.getInstance()
            .get("/admin/invoices", { params: params.queryKey })
            .then(function (response) {
            return __assign(__assign({}, response.data), { items: response.data.items.map(function (item) { return new InvoiceModel(item); }) });
        });
    }, InvoiceModel, config);
};
export var useUdateInvoiceStatus = function (config) {
    return useMutation(updateInvoiceStatus, {
        onSuccess: function () {
            QUERY_CACHE.invalidateQueries(['invoices']);
        }
    });
};
var updateInvoiceStatus = function (payload) {
    return RestClient.getInstance()
        .put("/admin/invoices/".concat(payload.id), { status: payload.status })
        .then(function (response) {
        return new InvoiceModel(response.data);
    });
};
export var usePaymentHistoryInApp = function (query, config) {
    return usePager('paymentHistoryInApp', query, function (params) {
        return RestClient.getInstance()
            .get("/admin/payments", { params: params.queryKey })
            .then(function (response) {
            return __assign(__assign({}, response.data), { items: response.data.items.map(function (item) { return new PaymentHistoryModel(item); }) });
        });
    }, PaymentHistoryModel, config);
};
export var useStores = function (query, config) {
    return usePager('storesFormAdmin', query, function (params) {
        return RestClient.getInstance()
            .get("/admin/stores", { params: params.queryKey })
            .then(function (response) {
            return __assign(__assign({}, response.data), { items: response.data.items.map(function (item) { return new StoreModel(item); }) });
        });
    }, StoreModel, config);
};
export var useOurInvoices = function (query, config) {
    return usePager('ourInvoicesForAdmin', query, function (params) {
        var _a = params.queryKey, _ = _a[0], pagerOptions = _a[1];
        return RestClient.getInstance()
            .get("/admin/ourinvoices", { params: pagerOptions })
            .then(function (response) {
            return __assign(__assign({}, response.data), { items: response.data.items.map(function (item) { return new OurInvoiceModel(item); }) });
        });
    }, OurInvoiceModel, config);
};
export var useAlimtalkTemplates = function (query, config) {
    return usePager('alimtalkTemplatesForAdmin', query, function (options) {
        var params = options.queryKey[1];
        return RestClient.getInstance()
            .get("/admin/alimtalk/templates", { params: params })
            .then(function (response) {
            return __assign(__assign({}, response.data), { items: response.data.items.map(function (item) { return new OurInvoiceModel(item); }) });
        });
    }, AlimtalkTemplateModel, config);
};
var createAlimtalTemplate = function (payload) {
    return RestClient.getInstance()
        .post("/admin/alimtalk/templates", payload)
        .then(function (result) { return new AlimtalkTemplateModel(result.data); });
};
export var useCreateCustomerMutation = function (config) {
    return useMutation(createAlimtalTemplate, __assign(__assign({}, config), { onSuccess: function () {
            QUERY_CACHE.invalidateQueries(['alimtalkTemplatesForAdmin']);
            QUERY_CACHE.invalidateQueries(['storeAlimtalkTemplatesForAdmin']);
        } }));
};
var getAlimtalkTemplatesByStoreId = function (options) {
    var storeId = options.queryKey[1];
    return RestClient.getInstance()
        .get("/admin/stores/".concat(storeId, "/alimtalk/templates"))
        .then(function (result) { return result.data.map(function (item) { return new AlimtalkTemplateModel(item); }); });
};
export var useAlimtalkTemplatesByStoreId = function (storeId, config) {
    var queryKey = ['storeAlimtalkTemplatesForAdmin', storeId];
    return useQuery(queryKey, getAlimtalkTemplatesByStoreId, __assign(__assign({}, config), { staleTime: 60000 }));
};
var getStoreByStoreId = function (options) {
    var storeId = options.queryKey[1];
    return RestClient.getInstance()
        .get("/admin/stores/".concat(storeId))
        .then(function (result) { return new StoreModel(result.data); });
};
export var useStoreByStoreId = function (storeId, config) {
    var queryKey = ['storeForAdmin', storeId];
    return useQuery(queryKey, getStoreByStoreId, __assign(__assign({}, config), { staleTime: 60000 }));
};
export var useFranchises = function (query, config) {
    return usePager('franchises', query, function (params) {
        var _a = params.queryKey, _ = _a[0], pagerOptions = _a[1];
        return RestClient.getInstance()
            .get("/admin/franchises", { params: pagerOptions })
            .then(function (response) {
            return __assign(__assign({}, response.data), { items: response.data.items.map(function (item) { return new FranchiseModel(item); }) });
        });
    }, FranchiseModel, config);
};
export var useInvoices = function (options, config) {
    return usePager('invoicesForFranchise', options, function (params) {
        var _a = params.queryKey, _ = _a[0], pagerOptions = _a[1];
        return RestClient.getInstance()
            .get("/admin/franchises/invoices", { params: pagerOptions })
            .then(function (response) {
            return __assign(__assign({}, response.data), { items: response.data.items.map(function (item) { return new FranchiseInvoiceModel(item); }) });
        });
    }, FranchiseInvoiceModel, config);
};
export var createFranchiseInvoice = function (payload) {
    return RestClient.getInstance()
        .post("/admin/franchises/invoices", payload)
        .then(function (response) {
        QUERY_CACHE.invalidateQueries(['invoicesForFranchise']);
        return new InvoiceModel(response.data);
    });
};
export var updateFranchiseInvoice = function (payload) {
    return RestClient.getInstance()
        .put("/admin/franchises/invoices/".concat(payload.id), payload)
        .then(function (response) {
        QUERY_CACHE.invalidateQueries(['invoicesForFranchise']);
        return new InvoiceModel(response.data);
    });
};
export var deleteFranchiseInvoice = function (id) {
    return RestClient.getInstance()
        .remove("/admin/franchises/invoices/".concat(id))
        .then(function (response) {
        QUERY_CACHE.invalidateQueries(['invoicesForFranchise']);
        return response.data;
    });
};
var getOurProducts = function () {
    return RestClient.getInstance()
        .get("/admin/ourproducts")
        .then(function (response) {
        return response.data.map(function (item) { return new OurProductModel(item); });
    });
};
export var useOurProducts = function (config) {
    return useQuery('ourProductsInAdmin', getOurProducts, __assign(__assign({}, config), { refetchInterval: 1000 * 60 * 5, refetchIntervalInBackground: true }));
};
export var useDownloadOurInvoiceData = function (config) {
    return useMutation(downloadOurInvoiceData, config);
};
export var useCancelOurInvoice = function (config) {
    return useMutation(cancelOurInvoice, {
        onSuccess: function () {
            QUERY_CACHE.invalidateQueries(['ourInvoicesForAdmin']);
        }
    });
};
export var cancelOurInvoice = function (payload) {
    return RestClient.getInstance()
        .put("/admin/ourinvoices/cancel", payload)
        .then(function (response) {
        QUERY_CACHE.invalidateQueries(['ourInvoicesForAdmin']);
        return response.data;
    });
};
export var createOurInvoice = function (payload) {
    return RestClient.getInstance()
        .post("/admin/paymentByStoreId", payload)
        .then(function (response) {
        QUERY_CACHE.invalidateQueries(['ourInvoicesForAdmin']);
        return response.data;
    });
};
