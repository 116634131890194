var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BaseModel } from './BaseModel';
import { floor, set } from 'renderer/utils';
var SALES_GOAL_POLICY;
(function (SALES_GOAL_POLICY) {
    SALES_GOAL_POLICY["ALL"] = "all";
    SALES_GOAL_POLICY["EACH"] = "each";
})(SALES_GOAL_POLICY || (SALES_GOAL_POLICY = {}));
var SalesGoalModel = /** @class */ (function (_super) {
    __extends(SalesGoalModel, _super);
    function SalesGoalModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SalesGoalModel.prototype.addSalesWithGoal = function (data, days, daysInMonth) {
        var _this = this;
        var result = __assign({}, data);
        if (this.goals) {
            Object.keys(data).map(function (managerId) {
                // const salesData = data[managerId];
                var goalData = _this.goals[managerId] || _this.goals['all'];
                var serviceGoal = days && daysInMonth ? floor((((goalData === null || goalData === void 0 ? void 0 : goalData.service) || 0) / daysInMonth) * days, 0) : goalData === null || goalData === void 0 ? void 0 : goalData.service;
                var productGoal = days && daysInMonth ? floor((((goalData === null || goalData === void 0 ? void 0 : goalData.product) || 0) / daysInMonth) * days, 0) : goalData === null || goalData === void 0 ? void 0 : goalData.product;
                var ticketGoal = days && daysInMonth ? floor((((goalData === null || goalData === void 0 ? void 0 : goalData.ticket) || 0) / daysInMonth) * days, 0) : goalData === null || goalData === void 0 ? void 0 : goalData.ticket;
                set(result, "".concat(managerId, ".service.goal"), serviceGoal || 0);
                set(result, "".concat(managerId, ".product.goal"), productGoal || 0);
                set(result, "".concat(managerId, ".ticket.goal"), ticketGoal || 0);
            });
            return result;
        }
    };
    SalesGoalModel.SALES_GOAL_POLICY = SALES_GOAL_POLICY;
    return SalesGoalModel;
}(BaseModel));
export { SalesGoalModel };
