var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { NODE_ENV } from 'renderer/constants';
import { SHA256 } from './josa';
var SYMBOL_INSTANCE = Symbol('singleton');
var ChannelService = /** @class */ (function () {
    function ChannelService() {
        this.loadScript();
    }
    ChannelService.prototype.loadScript = function () {
        var w = window;
        if (w.ChannelIO) {
            return (window.console.error || window.console.log || function () { })('ChannelIO script included twice.');
        }
        var ch = function () {
            ch.c(arguments);
        };
        ch.q = [];
        ch.c = function (args) {
            ch.q.push(args);
        };
        w.ChannelIO = ch;
        function l() {
            if (w.ChannelIOInitialized) {
                return;
            }
            w.ChannelIOInitialized = true;
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
            s.charset = 'UTF-8';
            var x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
        }
        if (document.readyState === 'complete') {
            l();
        }
        else if (window.attachEvent) {
            window.attachEvent('onload', l);
        }
        else {
            window.addEventListener('DOMContentLoaded', l, false);
            window.addEventListener('load', l, false);
        }
    };
    ChannelService.prototype.boot = function (settings, profile) {
        if (NODE_ENV !== 'production') {
            return;
        }
        window.ChannelIO('boot', __assign({ 
            // TODO: .env로 이동 - 개발 플러그인 키 추가 하기
            pluginKey: 'd39e27b8-e05f-4234-8b63-06946324db5a', profile: __assign({ userType: 'crmGuest' }, profile), memberId: (profile === null || profile === void 0 ? void 0 : profile.memberId) ? SHA256("".concat(profile === null || profile === void 0 ? void 0 : profile.memberId)) : null, zIndex: 999 }, settings));
    };
    ChannelService.prototype.setPage = function (pathname) {
        var _a;
        if (!pathname || NODE_ENV !== 'production') {
            return;
        }
        var origin = (_a = window.location) === null || _a === void 0 ? void 0 : _a.origin;
        window.ChannelIO('setPage', "".concat(origin).concat(pathname));
    };
    ChannelService.prototype.track = function (eventName, eventProperty) {
        if (!eventName || NODE_ENV !== 'production') {
            return;
        }
        window.ChannelIO('track', eventName, eventProperty);
    };
    ChannelService.prototype.shutdown = function () {
        if (NODE_ENV !== 'production') {
            return;
        }
        window.ChannelIO('shutdown');
    };
    ChannelService.getInstance = function () {
        var instanceDescriptor = Object.getOwnPropertyDescriptor(this, SYMBOL_INSTANCE);
        var instance = instanceDescriptor ? instanceDescriptor.value : new this();
        if (!instanceDescriptor) {
            Object.defineProperty(this, SYMBOL_INSTANCE, { value: instance });
        }
        return instance;
    };
    return ChannelService;
}());
export { ChannelService };
