import jwtDecode from 'jwt-decode';
import { random, times } from 'renderer/utils';
// tslint:disable-next-line:function-name
export function JWTDecode(token) {
    return jwtDecode(token);
}
export function decodeTicketId(token) {
    var decoded = btoa(token);
    var length = Number(decoded.substring(decoded.length - 1));
    return Number(decoded.substring(length, decoded.length - (length + 1)));
}
export function encodeTicketId(id) {
    var length = random(3, 5);
    var randomString = function (length) { return times(length, function () { return random(35).toString(36); }).join(''); };
    var prefix = randomString(length);
    var last = randomString(length);
    return btoa("".concat(prefix).concat(id).concat(last).concat(length));
}
export function decodeBase64(token) {
    return Buffer.from(token, 'base64').toString();
}
export function encodeBase64(id) {
    return Buffer.from("".concat(id)).toString('base64');
}
