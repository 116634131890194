var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from 'react-query';
import { CategoryModel } from 'renderer/models';
import { RestClient } from 'renderer/utils';
var getCategories = function () {
    return RestClient.getInstance()
        .get("/categories")
        .then(function (response) {
        return [
            '뷰티-네일아트',
            '뷰티-속눈썹',
            '뷰티-왁싱',
            '뷰티-에스테틱',
            '뷰티-헤어',
            '뷰티-메이크업',
            '뷰티-뷰티',
            '힐링-마사지',
            '힐링-사우나/찜질방',
            '힐링-풋케어',
            '교육-학원',
            '교육-피아노',
            '교육-기타',
            '카페-카페',
            '카페-키즈카페',
            '카페-스터디카페',
            '카페-플라워카페',
            '카페-기타',
            '운동-헬스',
            '운동-요가',
            '운동-필라테스',
            '운동-G.X',
            '운동-P.T',
            '운동-기타',
            '숙소-파티룸',
            '기타-기타'
        ]
            .map(function (key) {
            var _a;
            var target = (_a = response.data) === null || _a === void 0 ? void 0 : _a.find(function (item) {
                var keyString = "".concat(item.group, "-").concat(item.item);
                return keyString === key;
            });
            return !!target ? new CategoryModel(target) : undefined;
        })
            .filter(Boolean);
    });
};
export var useCategories = function (config) {
    return useQuery('category', getCategories, __assign(__assign({}, config), { staleTime: 60 * 1000 * 24 }));
};
export var getVersions = function () {
    return RestClient.getInstance()
        .get("/external/versions/thinkofyou")
        .then(function (response) {
        return response.data;
    });
};
export var useAppVeresion = function (config) {
    return useQuery('versions', getVersions, __assign(__assign({}, config), { refetchInterval: 1000 * 60 * 5, refetchIntervalInBackground: true }));
};
