var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery, useMutation } from 'react-query';
import { InvoiceModel, StoreModel, ManagerModel } from 'renderer/models';
import { RestClient } from 'renderer/utils';
import { QUERY_CACHE } from 'renderer/queries';
import { usePager } from 'renderer/utils/Pager';
import { PaymentHistoryModel } from '../models/PaymentHistoryModel';
var getStoreInvoicePreview = function (options) {
    var id = options.queryKey[1];
    return RestClient.getInstance()
        .get("/stores/".concat(id, "/invoices/preview"))
        .then(function (result) { return result.data; });
};
export var getStoreInvoicesPager = function (options) {
    var _a = options.queryKey, _ = _a[0], pagerOptions = _a[1];
    var id = pagerOptions.id, rest = __rest(pagerOptions, ["id"]);
    return RestClient.getInstance()
        .get("/stores/".concat(pagerOptions.id, "/invoices"), { params: rest })
        .then(function (result) { return result.data; });
};
export var getStorePaymentHistoryInAppPager = function (options) {
    var _a = options.queryKey, _ = _a[0], pagerOptions = _a[1];
    var id = pagerOptions.id, rest = __rest(pagerOptions, ["id"]);
    return RestClient.getInstance()
        .get("/stores/".concat(pagerOptions.id, "/payments"), { params: __assign(__assign({}, rest), { location: 'app' }) })
        .then(function (result) { return result.data; });
};
var getStoreByToken = function () {
    return RestClient.getInstance()
        .get("/store/my")
        .then(function (result) { return new StoreModel(result.data); });
};
var getSMSAvailableIds = function (options) {
    var params = options.queryKey[1];
    return RestClient.getInstance()
        .post("/store/customers/sms-available", params)
        .then(function (result) { return result.data; });
};
export var verificationPhoneNumber = function (payload) {
    return RestClient.getInstance()
        .post("/store/verify/phone", payload)
        .then(function (result) {
        QUERY_CACHE.invalidateQueries(['store']);
        return new StoreModel(result.data);
    });
};
var updateStore = function (payload) {
    return RestClient.getInstance()
        .put("/store/".concat(payload.id), payload)
        .then(function (result) { return new StoreModel(result.data); });
};
export var useStore = function (config) {
    return useQuery('store', getStoreByToken, __assign(__assign({}, config), { staleTime: 60000 }));
};
export var useGetSMSAvailableIds = function (params) {
    return useQuery(['getSMSAvailableIds', params], getSMSAvailableIds);
};
var mutationConfig = {
    onSuccess: function () {
        // TODO: 필터에 맞춰서 optimise
        QUERY_CACHE.invalidateQueries(['store']);
    },
    onError: function (error) { }
};
export var useUpdateStore = function (config) {
    return useMutation(updateStore, __assign(__assign({}, mutationConfig), config));
};
export var useStoreInvoicesPager = function (query, config) {
    return usePager('invoices', query, getStoreInvoicesPager, InvoiceModel);
};
export var useStorePaymentHistoryInAppPager = function (query, config) {
    return usePager('invoices', query, getStorePaymentHistoryInAppPager, PaymentHistoryModel);
};
export var useStoreInvoicePreview = function (id, config) {
    return useQuery(['invoice/preview', id], getStoreInvoicePreview, config);
};
export var getMyStoreInCache = function () {
    return QUERY_CACHE.getQueryData(['store']);
};
export var refreshMyStoreInCache = function () {
    return QUERY_CACHE.refetchQueries(['store']);
};
var getServiceGuides = function () {
    return RestClient.getInstance()
        .get("/external/guides")
        .then(function (result) { return result.data; });
};
export var useServiceGuides = function (config) {
    return useQuery('serviceGuides', getServiceGuides, __assign({ staleTime: Infinity }, config));
};
var createOnlyStore = function (payload) {
    return RestClient.getInstance()
        .post("/store", payload)
        .then(function (result) {
        var manager = new ManagerModel(result.data.manager);
        return {
            accessToken: result.data.accessToken,
            refreshToken: result.data.refreshToken,
            manager: manager
        };
    });
};
export var useCreateOnlyStore = function (config) {
    return useMutation(function (payload) { return createOnlyStore(payload); }, __assign(__assign(__assign({}, mutationConfig), config), { onSuccess: function (newData) {
            QUERY_CACHE.invalidateQueries(['store']);
            QUERY_CACHE.setQueryData('me', newData);
        } }));
};
