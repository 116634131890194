var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from './BaseModel';
import { StoreProductModel } from './StoreProductModel';
var StoreProductGroupModel = /** @class */ (function (_super) {
    __extends(StoreProductGroupModel, _super);
    function StoreProductGroupModel(props) {
        var _this = _super.call(this, props) || this;
        if (props.products) {
            _this.products = props.products.map(function (item) { return new StoreProductModel(item); });
        }
        return _this;
    }
    Object.defineProperty(StoreProductGroupModel.prototype, "isGroup", {
        get: function () {
            return true;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreProductGroupModel.prototype, "hasTemplate", {
        get: function () {
            return !!this.products.find(function (item) { return item.hasTemplate; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreProductGroupModel.prototype, "hasActiveTicket", {
        get: function () {
            return !!this.products.find(function (item) { return item.hasActiveTicket; });
        },
        enumerable: false,
        configurable: true
    });
    return StoreProductGroupModel;
}(BaseModel));
export { StoreProductGroupModel };
