var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { QUERY_CACHE } from 'renderer/queries';
import { usePostMutation, useGetQueryItems, usePutMutation, useRemoveMutation } from './BaseQuery';
var getMutationConfig = function (storeId) {
    return {
        onSuccess: function () {
            // TODO: 필터에 맞춰서 optimise
            QUERY_CACHE.invalidateQueries(['customerRelation', storeId]);
            QUERY_CACHE.invalidateQueries(['customerDetail']);
        }
    };
};
export var useCustomerRelations = function (_a, config) {
    var storeId = _a.storeId, customerId = _a.customerId;
    var queryKey = ['customerRelation', storeId, customerId];
    return useGetQueryItems(queryKey, __assign(__assign({}, config), { staleTime: 60000, url: "/stores/".concat(storeId, "/customers/").concat(customerId, "/relations") }));
};
export var useCreateCustomerRelations = function (storeId, config) {
    return usePostMutation(__assign(__assign(__assign({}, getMutationConfig(storeId)), config), { url: "/stores/".concat(storeId, "/customers/relations") }));
};
export var useUpdateCustomerRelations = function (storeId, relationId, config) {
    return usePutMutation(__assign(__assign(__assign({}, getMutationConfig(storeId)), config), { url: "/stores/".concat(storeId, "/customers/relations/").concat(relationId) }));
};
export var useDeleteCustomerRelations = function (storeId, relationId, config) {
    return useRemoveMutation(__assign(__assign(__assign({}, getMutationConfig(storeId)), config), { url: "/stores/".concat(storeId, "/customers/relations/").concat(relationId) }));
};
