import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { KO } from './ko';
// the translations
// (tip move them in a JSON file and import them)
var resources = {
    ko: {
        translation: KO
    }
};
// TODO: locale 적용
export var VALIDATE_MESSAGES = {
    default: 'Validation error on field ${label}',
    required: '${label}을(를) 입력해주세요.',
    enum: '${label}은(는) 다음 중 하나여야합니다. [${enum}]',
    whitespace: '${label}은 공란일 수 없습니다.',
    date: {
        format: '${label}에 올바른 날짜를 입력해주세요.',
        parse: '${label}에 올바른 날짜를 입력해주세요.',
        invalid: '${label}에 올바른 날짜를 입력해주세요.'
    },
    types: {
        string: '${label}은(는) 문자열이 입력되야합니다.',
        method: '${label}은(는) method가 입력되야합니다.',
        array: '${label}은(는) 배열이 입력되야합니다.',
        object: '${label}은(는) 객체가 입력되야합니다.',
        number: '${label}은(는) 숫자가 입력되야합니다.',
        date: '${label}은(는) 날짜가 입력되야합니다.',
        boolean: '${label}은(는) true,false가 입력되야합니다.',
        integer: '${label}은(는) 숫자가 입력되야합니다.',
        float: '${label}은(는) 숫자가 입력되야합니다.',
        regexp: '${label}은(는) 정규식이 입력되야합니다.',
        email: '${label}은(는) 이메일 입력되야합니다.',
        url: '${label}은(는) 주소가 입력되야합니다.',
        hex: '${label}은(는) 색상값이 입력되야합니다.'
    },
    string: {
        len: '${label}는 ${len}가 같아야합니다.',
        min: '${label}는 ${min}이상이어야 합니다.',
        max: '${label}는 ${max}이하여야 합니다.',
        range: '${label}는 ${min}이상 ${max}이하여야합니다.'
    },
    number: {
        len: '${label}의 크기는 ${len}가 같아야합니다.',
        min: '${label}의 크기는 ${min}이상이어야 합니다.',
        max: '${label}의 크기는 ${max}이하여야 합니다.',
        range: '${label}의 크기는 ${min}이상 ${max}이하여야합니다.'
    },
    array: {
        len: '${label}의 길이는 반드시 ${len}여야 합니다.',
        min: '${label}의 길이는 ${min}이상이어야 합니다.',
        max: '${label}의 길이는 ${max}이하여야 합니다.',
        range: '${label}의 길이는 ${min}이상 ${max}이하여야합니다.'
    },
    pattern: {
        mismatch: '${label}의 형식이 올바르지 않습니다.'
    }
};
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
    resources: resources,
    lng: 'ko',
    keySeparator: false,
    interpolation: {
        escapeValue: false // react already safes from xss
    }
});
export default i18n;
