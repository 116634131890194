var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation, useQuery } from 'react-query';
import { RestClient } from 'renderer/utils';
import { StoreSupplierModel } from 'renderer/models';
import { QUERY_CACHE } from 'renderer/queries';
import notification from 'antd/lib/notification';
var mutationConfig = {
    onSuccess: function () {
        QUERY_CACHE.invalidateQueries(['storeSuppliers']);
    },
    onError: function (error) {
        notification.error({ message: error.message });
    }
};
export var useGetStoreSuppliers = function (storeId, config) {
    return useQuery(['storeSuppliers', storeId], function () {
        return RestClient.getInstance()
            .get("/stores/".concat(storeId, "/suppliers"))
            .then(function (result) {
            var _a;
            return ((_a = result.data) === null || _a === void 0 ? void 0 : _a.map(function (item) { return new StoreSupplierModel(item); })) || [];
        })
            .catch(function (err) {
            console.log('rerrr netword', err);
        });
    }, __assign(__assign({}, config), { staleTime: 60000 }));
};
export var useCreateStoreSupplierMutation = function (config) {
    return useMutation(function (payload) {
        return RestClient.getInstance()
            .post("/stores/".concat(payload.storeId || 1, "/suppliers"), payload)
            .then(function (result) { return new StoreSupplierModel(result.data); });
    }, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateStoreSupplierMutation = function (config) {
    return useMutation(function (payload) {
        console.log('payload', payload);
        return RestClient.getInstance()
            .put("/stores/".concat(payload.storeId, "/suppliers/").concat(payload.id), payload)
            .then(function (result) { return new StoreSupplierModel(result.data); });
    }, __assign(__assign({}, mutationConfig), config));
};
export var useDeleteStoreSupplierMutation = function (config) {
    return useMutation(function (payload) {
        console.log('payload', payload);
        return RestClient.getInstance()
            .remove("/stores/".concat(payload.storeId, "/suppliers/").concat(payload.id))
            .then(function (result) { return new StoreSupplierModel(result.data); });
    }, __assign(__assign({}, mutationConfig), config));
};
