var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMutation, useQuery } from 'react-query';
import { ManagerModel, MANAGER_ROLE, MANAGER_PERMISSION_KEYS, MANAGER_PERMISSION_PRIORITY } from 'renderer/models';
import { RestClient, WebviewInterface, SessionStorage, TagManager } from 'renderer/utils';
import { QUERY_CACHE } from 'renderer/queries';
import notification from 'antd/lib/notification';
import { SESSION_STORAGE_KEY_IS_SUPERADMIN_LOGIN, PATH_SIGNOUT } from 'renderer/constants';
export var checkLoginId = function (loginId) {
    return RestClient.getInstance()
        .get("/manager/check", { params: { loginId: loginId } })
        .then(function (result) { return result.data; });
};
var getAllManagersByStoreId = function (options) {
    var storeId = options.queryKey[1];
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/managers"))
        .then(function (result) { var _a; return ((_a = result.data) === null || _a === void 0 ? void 0 : _a.map(function (item) { return new ManagerModel(item); })) || []; });
};
var getMeByStoreIdAndToken = function (options) {
    var storeId = options.queryKey[1];
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/managers/me"))
        .then(function (result) { return new ManagerModel(result.data); });
};
var createManager = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/managers"), payload)
        .then(function (result) { return new ManagerModel(result.data); });
};
var checkManager = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/managers/check"), payload)
        .then(function (result) { return result.data; });
};
var createOnlyManager = function (payload) {
    return RestClient.getInstance()
        .post("/manager", payload)
        .then(function (result) {
        var _a;
        return {
            manager: new ManagerModel((_a = result.data) === null || _a === void 0 ? void 0 : _a.manager),
            accessToken: result.data.accessToken,
            refreshToken: result.data.refreshToken
        };
    });
};
var updateManager = function (payload) {
    return RestClient.getInstance()
        .put("/stores/".concat(payload.storeId, "/managers"), payload)
        .then(function (result) { return new ManagerModel(result.data); });
};
var updateManagerPriority = function (payload) {
    return RestClient.getInstance()
        .put("/stores/".concat(payload.storeId, "/managers/priority"), payload.managers)
        .then(function (result) { return result.data.map(function (item) { return new ManagerModel(result.data); }); });
};
var deleteManager = function (params) {
    return RestClient.getInstance()
        .remove("/stores/".concat(params.storeId, "/managers/").concat(params.id))
        .then(function (result) { return new ManagerModel(result.data); });
};
export var useManagers = function (storeId, config) {
    return useQuery(['managers', storeId], getAllManagersByStoreId, __assign(__assign({}, config), { staleTime: 120000 }));
};
export var useMe = function (storeId, config) {
    return useQuery(['managers', storeId, 'me'], getMeByStoreIdAndToken, __assign(__assign({}, config), { staleTime: 120000 }));
    // return useQuery(['managers', storeId, 'me'], getMeByStoreIdAndToken, config);
};
export var getLoginManagerInCache = function () {
    var _a;
    var storeId = (_a = QUERY_CACHE.getQueryData(['store'])) === null || _a === void 0 ? void 0 : _a.id;
    return QUERY_CACHE.getQueryData(['managers', storeId, 'me']);
};
export var getManagersInCache = function () {
    var _a;
    var storeId = (_a = QUERY_CACHE.getQueryData(['store'])) === null || _a === void 0 ? void 0 : _a.id;
    return QUERY_CACHE.getQueryData(['managers', storeId]);
};
export var getWorkingManagersInCache = function () {
    var store = QUERY_CACHE.getQueryData(['store']);
    var storeId = store === null || store === void 0 ? void 0 : store.id;
    var managers = QUERY_CACHE.getQueryData(['managers', storeId]);
    if (store) {
        return (managers === null || managers === void 0 ? void 0 : managers.filter(function (manager) { return manager.isWorking; })) || [];
    }
    return [];
};
export var getAvailableManagersInCache = function () {
    var store = QUERY_CACHE.getQueryData(['store']);
    if (store) {
        return getWorkingManagersInCache().filter(function (manager) { return manager.isWorking && (manager.role === MANAGER_ROLE.STORE_ADMIN || store.hasSubscription); });
    }
};
export var getManagerByIdInCache = function (managerId) {
    return (getManagersInCache() || []).find(function (item) { return Number(item.id) === Number(managerId); });
};
export var getManagerDropdownOptions = function (type) {
    if (type === void 0) { type = 'available'; }
    return (getWorkingManagersInCache() || []).map(function (item) {
        var _a;
        return ({
            label: (_a = item.profile) === null || _a === void 0 ? void 0 : _a.name,
            value: item.id,
            disabled: type === 'working'
                ? false
                : !getAvailableManagersInCache()
                    .map(function (item) { return item.id; })
                    .includes(item.id)
        });
    });
};
var mutationConfig = {
    onSuccess: function (data) {
        // TODO: 필터에 맞춰서 optimise
        QUERY_CACHE.invalidateQueries(['managers']);
        return data;
    },
    onError: function (error) {
        notification.error({ message: error.message || '직원 정보 처리 중 에러 발생' });
    }
};
export var useCreateManager = function (config) {
    return useMutation(createManager, __assign(__assign({}, mutationConfig), config));
};
export var useCheckManager = function (config) {
    return useMutation(checkManager, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateManager = function (config) {
    return useMutation(updateManager, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateManagerPriority = function (config) {
    return useMutation(updateManagerPriority, __assign(__assign({}, mutationConfig), config));
};
export var useDeleteManager = function (config) {
    return useMutation(deleteManager, __assign(__assign({}, mutationConfig), config));
};
/**
 * Without store Id
 */
export var useSignin = function (config) {
    return useMutation(function (payload) {
        var autoLogin = payload.autoLogin, rest = __rest(payload, ["autoLogin"]);
        return RestClient.getInstance()
            .post('/manager/signin', rest)
            .then(function (response) {
            var _a, _b;
            TagManager.Instance.dataLayer({
                dataLayer: {
                    userId: (_a = response.data.manager) === null || _a === void 0 ? void 0 : _a.id
                }
            });
            return {
                manager: new ManagerModel(response.data.manager),
                accessToken: response.data.accessToken,
                refreshToken: (_b = response.data) === null || _b === void 0 ? void 0 : _b.refreshToken
            };
        });
    }, __assign(__assign({}, mutationConfig), config));
};
export var getMeWithoutStoreId = function () {
    return RestClient.getInstance()
        .get('/manager/me')
        .then(function (response) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (((_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.manager) === null || _b === void 0 ? void 0 : _b.meta) === null || _c === void 0 ? void 0 : _c.status) === ManagerModel.MANAGER_STATUS.RETIREMENT) {
            alert('퇴사상태의 직원입니다.');
            location.href = PATH_SIGNOUT;
            return;
        }
        var isMonitorFromSuperManager = SessionStorage.get(SESSION_STORAGE_KEY_IS_SUPERADMIN_LOGIN);
        if (!isMonitorFromSuperManager) {
            if ((_e = (_d = response.data) === null || _d === void 0 ? void 0 : _d.manager) === null || _e === void 0 ? void 0 : _e.storeId) {
                TagManager.Instance.dataLayer({
                    dataLayer: {
                        userId: (_f = response.data.manager) === null || _f === void 0 ? void 0 : _f.id
                    }
                });
                (_g = WebviewInterface.getInstance()) === null || _g === void 0 ? void 0 : _g.requestSyncContracts({
                    storeId: (_j = (_h = response.data) === null || _h === void 0 ? void 0 : _h.manager) === null || _j === void 0 ? void 0 : _j.storeId,
                    token: (_k = response.data) === null || _k === void 0 ? void 0 : _k.accessToken
                });
            }
        }
        return {
            manager: new ManagerModel(response.data.manager),
            accessToken: response.data.accessToken,
            refreshToken: response.data.refreshToken
        };
    });
};
export var useCreateOnlyManager = function (config) {
    return useMutation(function (payload) { return createOnlyManager(payload); }, __assign(__assign({}, mutationConfig), config));
};
export var useGetMe = function (config) {
    return useQuery(['me'], getMeWithoutStoreId, __assign(__assign({}, (config || {})), { refetchInterval: 1000 * 60 * 10 }));
};
export var getMeWithoutStoreIdInCache = function (config) {
    return QUERY_CACHE.getQueryData(['me']);
};
export var maskPhoneNumberByPermission = function (phone) {
    var loginManager = getLoginManagerInCache();
    var permissions = loginManager === null || loginManager === void 0 ? void 0 : loginManager.getPermission(MANAGER_PERMISSION_KEYS.CUSTOMER_PHONE);
    return (permissions === null || permissions === void 0 ? void 0 : permissions.read) === MANAGER_PERMISSION_PRIORITY.ALL ? phone : maskPhoneNumber(phone);
};
function maskPhoneNumber(phoneNumber) {
    var maskedNumber = phoneNumber.substr(0, 4) + '****' + phoneNumber.substr(phoneNumber.length - 5);
    return maskedNumber;
}
