var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// eslint-disable-next-line no-unused-vars
import { BaseModel } from './BaseModel';
import * as dayjs from 'dayjs';
import { ScheduleModel } from './ScheduleModel';
import { createUUID, WEEK_DAYS } from 'renderer/utils';
import { COLORS } from 'renderer/styles';
export var MANAGER_ROLE;
(function (MANAGER_ROLE) {
    MANAGER_ROLE[MANAGER_ROLE["BLOCKDEAL_SUPER_ADMIN"] = 1] = "BLOCKDEAL_SUPER_ADMIN";
    MANAGER_ROLE[MANAGER_ROLE["STORE_ADMIN"] = 10] = "STORE_ADMIN";
    MANAGER_ROLE[MANAGER_ROLE["STORE_EMPLOYEE"] = 11] = "STORE_EMPLOYEE";
    MANAGER_ROLE[MANAGER_ROLE["FRANCHISE_ADMIN"] = 20] = "FRANCHISE_ADMIN";
})(MANAGER_ROLE || (MANAGER_ROLE = {}));
export var MANAGER_STATUS;
(function (MANAGER_STATUS) {
    MANAGER_STATUS["WORKING"] = "working";
    MANAGER_STATUS["RETIREMENT"] = "retirement";
    MANAGER_STATUS["LEAVE_OF_WORK"] = "leave";
})(MANAGER_STATUS || (MANAGER_STATUS = {}));
export var MANAGER_PERMISSION_ACTIONS;
(function (MANAGER_PERMISSION_ACTIONS) {
    MANAGER_PERMISSION_ACTIONS["CREATE"] = "create";
    MANAGER_PERMISSION_ACTIONS["READ"] = "read";
    MANAGER_PERMISSION_ACTIONS["UPDATE"] = "update";
    MANAGER_PERMISSION_ACTIONS["DELETE"] = "delete";
    // READ_DURATION = 'readDuration',
    // UPDATE_DURATION = 'updateDuration',
    // DELETE_DURATION = 'deleteDuration'
    // READ_ALL = 'readAll',
    // READ_MINE = 'readMine',
    // CREATE_ALL = 'createAll',
    // CREATE_MINE = 'createMine',
    // UPDATE_ALL = 'updateAll',
    // UPDATE_MINE = 'updateMine',
    // DELETE_ALL = 'deleteAll',
    // DELEATE_MINE = 'deleteMine'
})(MANAGER_PERMISSION_ACTIONS || (MANAGER_PERMISSION_ACTIONS = {}));
export var MANAGER_PERMISSION_ACTIONS_DURATION;
(function (MANAGER_PERMISSION_ACTIONS_DURATION) {
    MANAGER_PERMISSION_ACTIONS_DURATION["READ_DURATION"] = "readDuration";
    MANAGER_PERMISSION_ACTIONS_DURATION["UPDATE_DURATION"] = "updateDuration";
    MANAGER_PERMISSION_ACTIONS_DURATION["DELETE_DURATION"] = "deleteDuration";
    // READ_ALL = 'readAll',
    // READ_MINE = 'readMine',
    // CREATE_ALL = 'createAll',
    // CREATE_MINE = 'createMine',
    // UPDATE_ALL = 'updateAll',
    // UPDATE_MINE = 'updateMine',
    // DELETE_ALL = 'deleteAll',
    // DELEATE_MINE = 'deleteMine'
})(MANAGER_PERMISSION_ACTIONS_DURATION || (MANAGER_PERMISSION_ACTIONS_DURATION = {}));
export var MANAGER_PERMISSION_PRIORITY;
(function (MANAGER_PERMISSION_PRIORITY) {
    MANAGER_PERMISSION_PRIORITY[MANAGER_PERMISSION_PRIORITY["ALL"] = 0] = "ALL";
    MANAGER_PERMISSION_PRIORITY[MANAGER_PERMISSION_PRIORITY["MINE"] = 1] = "MINE";
    MANAGER_PERMISSION_PRIORITY[MANAGER_PERMISSION_PRIORITY["NOTHING"] = 2] = "NOTHING";
})(MANAGER_PERMISSION_PRIORITY || (MANAGER_PERMISSION_PRIORITY = {}));
export var DEFAULT_MANAGER_PERMISSION_DURATIONS = [
    { hasDuration: false },
    { hasDuration: true, unit: 'day', value: 2 }
];
export var MANAGER_PERMISSION_KEYS;
(function (MANAGER_PERMISSION_KEYS) {
    MANAGER_PERMISSION_KEYS["EMPLOYEE_MANAGEMENT"] = "employeeManagement";
    MANAGER_PERMISSION_KEYS["CUSTOMER_LIST"] = "customerList";
    MANAGER_PERMISSION_KEYS["CUSTOMER_INFO"] = "customerInfo";
    MANAGER_PERMISSION_KEYS["CUSTOMER_PHONE"] = "customerPhone";
    MANAGER_PERMISSION_KEYS["STORE_MANAGEMENT"] = "storeManagement";
    MANAGER_PERMISSION_KEYS["PRODUCT_LIST"] = "productList";
    MANAGER_PERMISSION_KEYS["TICKET_TEMPLATE_LIST"] = "ticketTemplateList";
    MANAGER_PERMISSION_KEYS["SALES_LIST"] = "salesList";
    MANAGER_PERMISSION_KEYS["SALES_REPORT"] = "salesReport";
    MANAGER_PERMISSION_KEYS["SALES_INCENTIVE"] = "salesIncentive";
    MANAGER_PERMISSION_KEYS["SALES_GOALS"] = "salesGoals";
    MANAGER_PERMISSION_KEYS["SALES_GOALS_SETTING"] = "salesGoalsSetting";
    MANAGER_PERMISSION_KEYS["STATITCS_USING"] = "statisticsUsing";
    MANAGER_PERMISSION_KEYS["STATITCS_SALES"] = "statisticsSales";
})(MANAGER_PERMISSION_KEYS || (MANAGER_PERMISSION_KEYS = {}));
export var MANAGER_PERMISSION_GROUPS = [
    { title: '상점관리', key: 'store' },
    { title: '고객', key: 'customer' },
    { title: '직원관리', key: 'employee' },
    { title: '매출조회', key: 'sales' },
    { title: '상품관리', key: 'product' },
    { title: '선불액∙정기권 관리', key: 'ticketTemplate' },
    { title: '통계조회', key: 'statistics' }
];
export var MANAGER_PERMISSION_OBJECT = {
    storeManagement: {
        title: '상점관리',
        allowActions: {
            read: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.NOTHING],
            update: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.NOTHING]
        }
    },
    customerList: {
        title: '고객목록',
        allowActions: {
            read: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.MINE, MANAGER_PERMISSION_PRIORITY.NOTHING]
        }
    },
    customerInfo: {
        title: '고객정보',
        allowActions: {
            update: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.MINE, MANAGER_PERMISSION_PRIORITY.NOTHING]
        }
    },
    customerPhone: {
        title: '고객연락처',
        allowActions: {
            read: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.NOTHING]
        }
    },
    salesList: {
        title: '매출목록',
        allowActions: {
            // 생성 드로우를 막는다.
            create: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.NOTHING],
            read: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.MINE, MANAGER_PERMISSION_PRIORITY.NOTHING],
            readDuration: DEFAULT_MANAGER_PERMISSION_DURATIONS,
            // 생성 드로우를 막는다.
            update: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.NOTHING],
            updateDuration: DEFAULT_MANAGER_PERMISSION_DURATIONS,
            // API 호출하기 직전에 막는다.
            delete: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.NOTHING],
            deleteDuration: DEFAULT_MANAGER_PERMISSION_DURATIONS
        }
    },
    employeeManagement: {
        title: '직원관리',
        allowActions: {
            create: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.NOTHING],
            read: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.NOTHING],
            update: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.NOTHING],
            delete: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.NOTHING]
        }
    },
    productList: {
        title: '상품 리스트',
        allowActions: {
            create: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.NOTHING],
            update: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.NOTHING],
            delete: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.NOTHING]
        }
    },
    ticketTemplateList: {
        title: '정기권 리스트',
        allowActions: {
            create: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.NOTHING],
            update: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.NOTHING],
            delete: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.NOTHING]
        }
    },
    salesReport: {
        title: '결산 조회',
        allowActions: {
            read: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.MINE, MANAGER_PERMISSION_PRIORITY.NOTHING]
        }
    },
    salesIncentive: {
        title: '직원별 인센티브 설정',
        allowActions: {
            update: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.NOTHING]
        }
    },
    salesGoals: {
        title: '목표 매출 조회',
        allowActions: {
            read: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.MINE, MANAGER_PERMISSION_PRIORITY.NOTHING]
        }
    },
    salesGoalsSetting: {
        title: '직원별 목표매출 설정',
        allowActions: {
            update: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.NOTHING]
        }
    },
    statisticsUsing: {
        title: '이용통계',
        allowActions: {
            read: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.MINE, MANAGER_PERMISSION_PRIORITY.NOTHING]
        }
    },
    statisticsSales: {
        title: '매출통계',
        allowActions: {
            read: [MANAGER_PERMISSION_PRIORITY.ALL, MANAGER_PERMISSION_PRIORITY.MINE, MANAGER_PERMISSION_PRIORITY.NOTHING]
        }
    }
};
var ManagerModel = /** @class */ (function (_super) {
    __extends(ManagerModel, _super);
    function ManagerModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ManagerModel.prototype, "color", {
        get: function () {
            var _a;
            return ((_a = this.profile) === null || _a === void 0 ? void 0 : _a.color) || COLORS.staff1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ManagerModel.prototype, "isWorking", {
        get: function () {
            var _a;
            return ((_a = this.meta) === null || _a === void 0 ? void 0 : _a.status) !== MANAGER_STATUS.RETIREMENT;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ManagerModel.prototype, "isAdmin", {
        get: function () {
            return this.role === MANAGER_ROLE.STORE_ADMIN;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ManagerModel.prototype, "isSuperAdmin", {
        get: function () {
            return this.role === MANAGER_ROLE.BLOCKDEAL_SUPER_ADMIN;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ManagerModel.prototype, "isFranchiseAdmin", {
        get: function () {
            return this.role === MANAGER_ROLE.FRANCHISE_ADMIN;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ManagerModel.prototype, "displayName", {
        get: function () {
            var _a;
            return ((_a = this.profile) === null || _a === void 0 ? void 0 : _a.name) || '이름없음';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ManagerModel.prototype, "firstCharInName", {
        get: function () {
            return this.displayName[0];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ManagerModel.prototype, "operatingHour", {
        get: function () {
            var _a;
            return (_a = this.profile) === null || _a === void 0 ? void 0 : _a.operatingHour;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ManagerModel.prototype, "statusString", {
        get: function () {
            var _a;
            switch ((_a = this.meta) === null || _a === void 0 ? void 0 : _a.status) {
                case MANAGER_STATUS.RETIREMENT:
                    return '퇴사';
                case MANAGER_STATUS.WORKING:
                default:
                    return '재직 중';
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ManagerModel.prototype, "notification", {
        get: function () {
            var _a;
            return (((_a = this.meta) === null || _a === void 0 ? void 0 : _a.notification) || {
                active: false,
                reservation: {
                    target: (this === null || this === void 0 ? void 0 : this.role) === ManagerModel.MANAGER_ROLE.STORE_ADMIN ? 'all' : this.id
                }
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ManagerModel.prototype, "picture", {
        get: function () {
            var _a, _b;
            return ((_a = this.profile) === null || _a === void 0 ? void 0 : _a.pictures) && ((_b = this.profile) === null || _b === void 0 ? void 0 : _b.pictures[0]);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ManagerModel.prototype, "permissions", {
        get: function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31;
            return {
                employeeManagement: ((_b = (_a = this.meta) === null || _a === void 0 ? void 0 : _a.permissions) === null || _b === void 0 ? void 0 : _b.employeeManagement)
                    ? (_d = (_c = this.meta) === null || _c === void 0 ? void 0 : _c.permissions) === null || _d === void 0 ? void 0 : _d.employeeManagement
                    : this.isAdmin
                        ? {
                            create: MANAGER_PERMISSION_PRIORITY.ALL,
                            read: MANAGER_PERMISSION_PRIORITY.ALL,
                            update: MANAGER_PERMISSION_PRIORITY.ALL,
                            delete: MANAGER_PERMISSION_PRIORITY.ALL
                        }
                        : {
                            create: MANAGER_PERMISSION_PRIORITY.NOTHING,
                            read: MANAGER_PERMISSION_PRIORITY.NOTHING,
                            update: MANAGER_PERMISSION_PRIORITY.NOTHING,
                            delete: MANAGER_PERMISSION_PRIORITY.NOTHING
                        },
                customerList: ((_f = (_e = this.meta) === null || _e === void 0 ? void 0 : _e.permissions) === null || _f === void 0 ? void 0 : _f.customerList)
                    ? (_h = (_g = this.meta) === null || _g === void 0 ? void 0 : _g.permissions) === null || _h === void 0 ? void 0 : _h.customerList
                    : {
                        read: MANAGER_PERMISSION_PRIORITY.ALL
                    },
                customerInfo: ((_k = (_j = this.meta) === null || _j === void 0 ? void 0 : _j.permissions) === null || _k === void 0 ? void 0 : _k.customerInfo)
                    ? (_m = (_l = this.meta) === null || _l === void 0 ? void 0 : _l.permissions) === null || _m === void 0 ? void 0 : _m.customerInfo
                    : {
                        update: MANAGER_PERMISSION_PRIORITY.ALL
                    },
                customerPhone: ((_p = (_o = this.meta) === null || _o === void 0 ? void 0 : _o.permissions) === null || _p === void 0 ? void 0 : _p.customerPhone)
                    ? (_r = (_q = this.meta) === null || _q === void 0 ? void 0 : _q.permissions) === null || _r === void 0 ? void 0 : _r.customerPhone
                    : {
                        read: MANAGER_PERMISSION_PRIORITY.ALL
                    },
                salesList: ((_t = (_s = this.meta) === null || _s === void 0 ? void 0 : _s.permissions) === null || _t === void 0 ? void 0 : _t.salesList)
                    ? (_v = (_u = this.meta) === null || _u === void 0 ? void 0 : _u.permissions) === null || _v === void 0 ? void 0 : _v.salesList
                    : this.isAdmin
                        ? {
                            create: MANAGER_PERMISSION_PRIORITY.ALL,
                            read: MANAGER_PERMISSION_PRIORITY.ALL,
                            readDuration: DEFAULT_MANAGER_PERMISSION_DURATIONS[0],
                            update: MANAGER_PERMISSION_PRIORITY.ALL,
                            updateDuration: DEFAULT_MANAGER_PERMISSION_DURATIONS[0],
                            delete: MANAGER_PERMISSION_PRIORITY.ALL,
                            deleteDuration: DEFAULT_MANAGER_PERMISSION_DURATIONS[0]
                        }
                        : {
                            create: MANAGER_PERMISSION_PRIORITY.ALL,
                            read: MANAGER_PERMISSION_PRIORITY.MINE,
                            readDuration: DEFAULT_MANAGER_PERMISSION_DURATIONS[0],
                            update: MANAGER_PERMISSION_PRIORITY.NOTHING,
                            updateDuration: DEFAULT_MANAGER_PERMISSION_DURATIONS[0],
                            delete: MANAGER_PERMISSION_PRIORITY.NOTHING,
                            deleteDuration: DEFAULT_MANAGER_PERMISSION_DURATIONS[0]
                        },
                storeManagement: ((_x = (_w = this.meta) === null || _w === void 0 ? void 0 : _w.permissions) === null || _x === void 0 ? void 0 : _x.storeManagement)
                    ? (_z = (_y = this.meta) === null || _y === void 0 ? void 0 : _y.permissions) === null || _z === void 0 ? void 0 : _z.storeManagement
                    : this.isAdmin
                        ? {
                            read: MANAGER_PERMISSION_PRIORITY.ALL,
                            update: MANAGER_PERMISSION_PRIORITY.ALL
                        }
                        : {
                            read: MANAGER_PERMISSION_PRIORITY.NOTHING,
                            update: MANAGER_PERMISSION_PRIORITY.NOTHING
                        },
                productList: ((_1 = (_0 = this.meta) === null || _0 === void 0 ? void 0 : _0.permissions) === null || _1 === void 0 ? void 0 : _1.productList)
                    ? (_3 = (_2 = this.meta) === null || _2 === void 0 ? void 0 : _2.permissions) === null || _3 === void 0 ? void 0 : _3.productList
                    : this.isAdmin
                        ? {
                            create: MANAGER_PERMISSION_PRIORITY.ALL,
                            update: MANAGER_PERMISSION_PRIORITY.ALL,
                            delete: MANAGER_PERMISSION_PRIORITY.ALL
                        }
                        : {
                            create: MANAGER_PERMISSION_PRIORITY.NOTHING,
                            update: MANAGER_PERMISSION_PRIORITY.NOTHING,
                            delete: MANAGER_PERMISSION_PRIORITY.NOTHING
                        },
                ticketTemplateList: ((_5 = (_4 = this.meta) === null || _4 === void 0 ? void 0 : _4.permissions) === null || _5 === void 0 ? void 0 : _5.ticketTemplateList)
                    ? (_7 = (_6 = this.meta) === null || _6 === void 0 ? void 0 : _6.permissions) === null || _7 === void 0 ? void 0 : _7.ticketTemplateList
                    : this.isAdmin
                        ? {
                            create: MANAGER_PERMISSION_PRIORITY.ALL,
                            update: MANAGER_PERMISSION_PRIORITY.ALL,
                            delete: MANAGER_PERMISSION_PRIORITY.ALL
                        }
                        : {
                            create: MANAGER_PERMISSION_PRIORITY.NOTHING,
                            update: MANAGER_PERMISSION_PRIORITY.NOTHING,
                            delete: MANAGER_PERMISSION_PRIORITY.NOTHING
                        },
                salesReport: ((_9 = (_8 = this.meta) === null || _8 === void 0 ? void 0 : _8.permissions) === null || _9 === void 0 ? void 0 : _9.salesReport)
                    ? (_11 = (_10 = this.meta) === null || _10 === void 0 ? void 0 : _10.permissions) === null || _11 === void 0 ? void 0 : _11.salesReport
                    : this.isAdmin
                        ? {
                            read: MANAGER_PERMISSION_PRIORITY.ALL
                        }
                        : {
                            read: MANAGER_PERMISSION_PRIORITY.NOTHING
                        },
                salesIncentive: ((_13 = (_12 = this.meta) === null || _12 === void 0 ? void 0 : _12.permissions) === null || _13 === void 0 ? void 0 : _13.salesIncentive)
                    ? (_15 = (_14 = this.meta) === null || _14 === void 0 ? void 0 : _14.permissions) === null || _15 === void 0 ? void 0 : _15.salesIncentive
                    : this.isAdmin
                        ? {
                            update: MANAGER_PERMISSION_PRIORITY.ALL
                        }
                        : {
                            update: MANAGER_PERMISSION_PRIORITY.NOTHING
                        },
                salesGoals: ((_17 = (_16 = this.meta) === null || _16 === void 0 ? void 0 : _16.permissions) === null || _17 === void 0 ? void 0 : _17.salesGoals)
                    ? (_19 = (_18 = this.meta) === null || _18 === void 0 ? void 0 : _18.permissions) === null || _19 === void 0 ? void 0 : _19.salesGoals
                    : this.isAdmin
                        ? {
                            read: MANAGER_PERMISSION_PRIORITY.ALL
                        }
                        : {
                            read: MANAGER_PERMISSION_PRIORITY.NOTHING
                        },
                salesGoalsSetting: ((_21 = (_20 = this.meta) === null || _20 === void 0 ? void 0 : _20.permissions) === null || _21 === void 0 ? void 0 : _21.salesGoalsSetting)
                    ? (_23 = (_22 = this.meta) === null || _22 === void 0 ? void 0 : _22.permissions) === null || _23 === void 0 ? void 0 : _23.salesGoalsSetting
                    : this.isAdmin
                        ? {
                            update: MANAGER_PERMISSION_PRIORITY.ALL
                        }
                        : {
                            update: MANAGER_PERMISSION_PRIORITY.NOTHING
                        },
                statisticsUsing: ((_25 = (_24 = this.meta) === null || _24 === void 0 ? void 0 : _24.permissions) === null || _25 === void 0 ? void 0 : _25.statisticsUsing)
                    ? (_27 = (_26 = this.meta) === null || _26 === void 0 ? void 0 : _26.permissions) === null || _27 === void 0 ? void 0 : _27.statisticsUsing
                    : this.isAdmin
                        ? {
                            read: MANAGER_PERMISSION_PRIORITY.ALL
                        }
                        : {
                            read: MANAGER_PERMISSION_PRIORITY.MINE
                        },
                statisticsSales: ((_29 = (_28 = this.meta) === null || _28 === void 0 ? void 0 : _28.permissions) === null || _29 === void 0 ? void 0 : _29.statisticsSales)
                    ? (_31 = (_30 = this.meta) === null || _30 === void 0 ? void 0 : _30.permissions) === null || _31 === void 0 ? void 0 : _31.statisticsSales
                    : this.isAdmin
                        ? {
                            read: MANAGER_PERMISSION_PRIORITY.ALL
                        }
                        : {
                            read: MANAGER_PERMISSION_PRIORITY.MINE
                        }
            };
        },
        enumerable: false,
        configurable: true
    });
    ManagerModel.prototype.hasPermission = function (data) {
        var action = data.action, key = data.key, priority = data.priority;
        var permission = this.getPermissionPriority({ action: action, key: key });
        return permission <= priority;
    };
    ManagerModel.prototype.getPermissionPriority = function (data) {
        var action = data.action, key = data.key;
        return this.getPermission(key)[action];
    };
    ManagerModel.prototype.getPermissionDuration = function (data) {
        var action = data.action, key = data.key;
        return this.getPermission(key)[action];
    };
    ManagerModel.prototype.getPermissionDurationDate = function (data) {
        var action = data.action, key = data.key;
        var duration = this.getPermission(key)[action];
        return (duration === null || duration === void 0 ? void 0 : duration.hasDuration)
            ? dayjs().subtract(duration === null || duration === void 0 ? void 0 : duration.value, duration === null || duration === void 0 ? void 0 : duration.unit).add(1, 'day').startOf('day')
            : null;
    };
    ManagerModel.prototype.getPermission = function (key) {
        return this.permissions[key];
    };
    ManagerModel.prototype.makeFixedScheduleFromOperatingHour = function (targetDay, store) {
        var _a, _b, _c, _d;
        var day = WEEK_DAYS[targetDay.day()];
        var operatingHour = this === null || this === void 0 ? void 0 : this.operatingHour;
        var storeOeratingHour = store === null || store === void 0 ? void 0 : store.operatingHour;
        if (operatingHour) {
            var commonScheduleData = {
                type: ScheduleModel.RESERVATION_TYPE.SCHEDULE,
                managerId: this.id,
                manager: this,
                storeId: this.storeId,
                name: '고정 휴무'
            };
            var data = operatingHour[day];
            var storeOperatingData = (storeOeratingHour && storeOeratingHour[day]) || {};
            if (!storeOperatingData.isActive) {
                return;
            }
            var _e = ((_a = storeOperatingData === null || storeOperatingData === void 0 ? void 0 : storeOperatingData.start) === null || _a === void 0 ? void 0 : _a.split(':')) || [], startHour = _e[0], startMinutes = _e[1];
            var _f = ((_b = storeOperatingData === null || storeOperatingData === void 0 ? void 0 : storeOperatingData.end) === null || _b === void 0 ? void 0 : _b.split(':')) || [], endHour = _f[0], endMinutes = _f[1];
            if (!(data === null || data === void 0 ? void 0 : data.isActive)) {
                return [
                    new ScheduleModel(__assign(__assign({}, commonScheduleData), { id: createUUID(), dateOption: {
                            startedAt: targetDay.startOf('day').add(startHour, 'hour').add(startMinutes, 'minute').toDate(),
                            endedAt: targetDay.startOf('day').add(endHour, 'hour').add(endMinutes, 'minute').toDate(),
                            isAllDay: true
                        } }))
                ];
            }
            else if (data.isActive) {
                var _g = ((_c = data.start) === null || _c === void 0 ? void 0 : _c.split(':')) || [], employeeStartHour = _g[0], employeeStartMinutes = _g[1];
                var _h = ((_d = data.end) === null || _d === void 0 ? void 0 : _d.split(':')) || [], employeeEndHour = _h[0], employeeEndMinutes = _h[1];
                var storeStartTime = targetDay.startOf('day').add(startHour, 'hour').add(startMinutes, 'minute');
                var storeEndTime = targetDay.startOf('day').add(endHour, 'hour').add(endMinutes, 'minute');
                var employeeStartTime = targetDay
                    .startOf('day')
                    .add(employeeStartHour, 'hour')
                    .add(employeeStartMinutes, 'minute');
                var employeeEndTime = targetDay.startOf('day').add(employeeEndHour, 'hour').add(employeeEndMinutes, 'minute');
                // const startReservation =
                var startDiff = employeeStartTime.diff(storeStartTime, 'minute');
                var endDiff = storeEndTime.diff(employeeEndTime, 'minute');
                var result = [];
                if (startDiff > 0) {
                    result.push(new ScheduleModel(__assign(__assign({}, commonScheduleData), { id: createUUID(), dateOption: {
                            startedAt: storeStartTime.toDate(),
                            endedAt: storeStartTime.add(startDiff, 'minute').toDate(),
                            isAllDay: false
                        } })));
                }
                if (endDiff > 0) {
                    result.push(new ScheduleModel(__assign(__assign({}, commonScheduleData), { id: createUUID(), dateOption: {
                            startedAt: storeEndTime.subtract(endDiff, 'minute').toDate(),
                            endedAt: storeEndTime.toDate(),
                            isAllDay: false
                        } })));
                }
                return result;
            }
        }
        else {
            return;
        }
    };
    ManagerModel.MANAGER_STATUS = MANAGER_STATUS;
    ManagerModel.MANAGER_PERMISSION_KEYS = MANAGER_PERMISSION_KEYS;
    ManagerModel.MANAGER_ROLE = MANAGER_ROLE;
    return ManagerModel;
}(BaseModel));
export { ManagerModel };
