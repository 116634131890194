var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ManagerModel } from './ManagerModel';
import { BaseModel } from './BaseModel';
import { floor, get } from 'renderer/utils';
import dayjs from 'dayjs';
import { WEEK_DAYS } from 'renderer/utils';
import { StoreSubscriptionModel } from './StoreSubscriptionModel';
import { SALES_METHODS_KEYS, EXTENDED_SALES_MEHOD_KOREAN } from './SalesHistoryModel';
import { StorePointModel } from './StorePointModel';
import { CustomerGroupModel } from './CustomerGroupModel';
import { FranchiseModel } from './FranchiseModel';
export var HOLYDAY_POLICY;
(function (HOLYDAY_POLICY) {
    HOLYDAY_POLICY["NONE"] = "none";
    HOLYDAY_POLICY["LIKE_WEEKDAY"] = "like_weekday";
    HOLYDAY_POLICY["LIKE_SAT"] = "like_sat";
    HOLYDAY_POLICY["LIKE_SUN"] = "like_sun";
})(HOLYDAY_POLICY || (HOLYDAY_POLICY = {}));
export var CUSTOMER_PROFILE_POLICY;
(function (CUSTOMER_PROFILE_POLICY) {
    CUSTOMER_PROFILE_POLICY["SIMPLE"] = "simple";
    CUSTOMER_PROFILE_POLICY["DETAIL"] = "detail";
})(CUSTOMER_PROFILE_POLICY || (CUSTOMER_PROFILE_POLICY = {}));
var StoreModel = /** @class */ (function (_super) {
    __extends(StoreModel, _super);
    function StoreModel(props) {
        var _this = _super.call(this, props) || this;
        _this.franchise = props.franchise && new FranchiseModel(props.franchise);
        _this.subscriptions = props.subscriptions && props.subscriptions.map(function (item) { return new StoreSubscriptionModel(item); });
        _this.storePoint = props.storePoint && new StorePointModel(props.storePoint);
        _this.managers = props.managers && props.managers.map(function (item) { return new ManagerModel(item); });
        _this.customerGroups = props.customerGroups && props.customerGroups.map(function (item) { return new CustomerGroupModel(item); });
        return _this;
    }
    StoreModel.prototype.calculateGatheredPoint = function (paidPrice) {
        var pointRate = this.meta && this.meta.pointRate;
        return pointRate ? floor((paidPrice * (100 + (pointRate - 100))) / 100) : 0;
    };
    StoreModel.prototype.getAddressString = function (full) {
        var address = get(this.address, 'address');
        address = typeof address === 'string' ? address : get(address, 'address' || '');
        var detail = get(this.address, 'detailAddress');
        return full ? "".concat(address, " ").concat(detail) : "".concat(address);
    };
    Object.defineProperty(StoreModel.prototype, "operationPreference", {
        // TODO: 서버에 기본값으로 설정F
        get: function () {
            var _a;
            return ((_a = this.preference) === null || _a === void 0 ? void 0 : _a.operation)
                ? __assign({ saleDisplay: 'all', defaultStatus: 'confirm', depositTimeLimit: 120, defaultDeposit: 0, timeStepInMobile: 30, pendingCustomersSegmentFields: [], simpleScheduleCreate: 'active', depositConfirmPolicy: {
                        completed: true,
                        over: false,
                        under: false
                    } }, this.preference.operation) : {
                operatingHour: {
                    monday: { isActive: true, start: '09:00', end: '18:00' },
                    tuesday: { isActive: true, start: '09:00', end: '18:00' },
                    wednesday: { isActive: true, start: '09:00', end: '18:00' },
                    thursday: { isActive: true, start: '09:00', end: '18:00' },
                    friday: { isActive: true, start: '09:00', end: '18:00' },
                    saturday: { isActive: true, start: '09:00', end: '18:00' },
                    sunday: { isActive: false, start: '09:00', end: '18:00' }
                },
                defaultStatus: 'confirm',
                depositTimeLimit: 120,
                defaultDeposit: 0,
                saleDisplay: 'all',
                timeStep: 10 | 30,
                pendingCustomersSegmentFields: [],
                showUnassigned: false,
                breakTime: {
                    isActive: false,
                    start: '12:00',
                    end: '13:00'
                },
                simpleScheduleCreate: 'active',
                timeStepInMobile: 30,
                isAutoConfirm: false,
                depositConfirmPolicy: {
                    completed: true,
                    over: false,
                    under: false
                }
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "reservationSMS", {
        // TODO: 서버에서 기본값으로 설정
        get: function () {
            var _a;
            return (((_a = this.preference) === null || _a === void 0 ? void 0 : _a.reservationSMS) || {
                remind: {
                    isActive: false,
                    beforeSend: [],
                    messageTemplateBody: "{{\uACE0\uAC1D\uBA85}}\uB2D8 \uC608\uC57D\uC740 {{\uC608\uC57D\uC77C\uC2DC}}\uC785\uB2C8\uB2E4. \uB2A6\uC9C0\uC54A\uAC8C\uBC29\uBB38\uBD80\uD0C1\uB4DC\uB9BD\uB2C8\uB2E4.\n-{{\uC0C1\uC810\uBA85}}-"
                },
                confirm: {
                    isActive: false,
                    messageTemplateBody: "{{\uACE0\uAC1D\uBA85}}\uB2D8 {{\uC608\uC57D\uC77C\uC2DC}} \uC608\uC57D\uC774 \uD655\uC815\uB418\uC5C8\uC2B5\uB2C8\uB2E4. \n-{{\uC0C1\uC810\uBA85}}-"
                },
                customerCancel: {
                    isActive: false,
                    messageTemplateBody: "{{\uACE0\uAC1D\uBA85}}\uB2D8 {{\uC608\uC57D\uC77C\uC2DC}} \uC608\uC57D\uC774 \uCDE8\uC18C\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n-{{\uC0C1\uC810\uBA85}}-"
                },
                storeCancel: {
                    isActive: false,
                    messageTemplateBody: "{{\uACE0\uAC1D\uBA85}}\uB2D8 {{\uC608\uC57D\uC77C\uC2DC}} \uC608\uC57D\uC774 \uCDE8\uC18C\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n-{{\uC0C1\uC810\uBA85}}-"
                },
                noshow: {
                    isActive: false,
                    messageTemplateBody: "{{\uACE0\uAC1D\uBA85}}\uB2D8 \uC608\uC57D\uD558\uC2E0 \uC2DC\uAC04\uC5D0 \uBC29\uBB38\uD558\uC9C0 \uC54A\uC544 \uCDE8\uC18C\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n-{{\uC0C1\uC810\uBA85}}-"
                },
                reject: {
                    isActive: false,
                    messageTemplateBody: "\uC8C4\uC1A1\uD569\uB2C8\uB2E4. {{\uACE0\uAC1D\uBA85}}\uB2D8 \uC608\uC57D\uB300\uAE30\uAC74\uC774 \uAC70\uC808\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n-{{\uC0C1\uC810\uBA85}}-"
                }
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "scheduleBlockSettings", {
        get: function () {
            var _a;
            return (((_a = this.preference) === null || _a === void 0 ? void 0 : _a.scheduleBlockSettings) || [
                { type: 'scheduleProduct', active: true },
                { type: 'description', active: true },
                { type: 'segment', active: true }
            ]);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "activeScheduleBlockSettings", {
        get: function () {
            var _a;
            return (_a = this.scheduleBlockSettings) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.active; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "timeStep", {
        get: function () {
            return this.operationPreference.timeStep;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "operatingHour", {
        // TODO: 서버에 기본값으로 설정F
        // TODO:  삭제
        get: function () {
            return (this.operationPreference.operatingHour || {
                monday: { isActive: true, start: '09:00', end: '18:00' },
                tuesday: { isActive: true, start: '09:00', end: '18:00' },
                wednesday: { isActive: true, start: '09:00', end: '18:00' },
                thursday: { isActive: true, start: '09:00', end: '18:00' },
                friday: { isActive: true, start: '09:00', end: '18:00' },
                saturday: { isActive: true, start: '09:00', end: '18:00' },
                sunday: { isActive: false, start: '09:00', end: '18:00' }
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "availableReviewRequest", {
        get: function () {
            var _a;
            return !!((_a = this.kakaoSendInfo) === null || _a === void 0 ? void 0 : _a.profileKey);
        },
        enumerable: false,
        configurable: true
    });
    StoreModel.prototype.isStoreOperatingHour = function (date, exclusiveString) {
        if (exclusiveString === void 0) { exclusiveString = '[)'; }
        date = dayjs(date);
        var dayNumber = date.day();
        var day = WEEK_DAYS[dayNumber];
        var operatingHour = this.operatingHour;
        var dayOperatingHour = operatingHour[day];
        var isBreaktime = this.isBreakTime(date, exclusiveString);
        if (isBreaktime) {
            return false;
        }
        if (!dayOperatingHour) {
            return true;
        }
        var start = dayOperatingHour.start, end = dayOperatingHour.end, isActive = dayOperatingHour.isActive;
        if (!isActive) {
            return false;
        }
        var operateTime = getStartTimeAndEndTime(date, start, end);
        return dayjs(date).isBetween(operateTime.startTime, operateTime.endTime, 'minute', exclusiveString);
    };
    StoreModel.prototype.isBreakTime = function (date, exclusiveString) {
        if (exclusiveString === void 0) { exclusiveString = '[)'; }
        var fixedBreakTime = this.operationPreference.breakTime;
        if (!fixedBreakTime) {
            return false;
        }
        if (fixedBreakTime && fixedBreakTime.start && fixedBreakTime.end) {
            var breakTimes = getStartTimeAndEndTime(date, fixedBreakTime.start, fixedBreakTime.end);
            var isInBreakTimes = dayjs(date).isBetween(breakTimes.startTime, breakTimes.endTime, 'minute', exclusiveString);
            if (isInBreakTimes && fixedBreakTime.isActive) {
                return true;
            }
        }
        return false;
    };
    Object.defineProperty(StoreModel.prototype, "filters", {
        get: function () {
            var _a;
            return ((_a = this.preference) === null || _a === void 0 ? void 0 : _a.customerFilters) || [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "incentives", {
        get: function () {
            var _a, _b;
            return ((_a = this.preference) === null || _a === void 0 ? void 0 : _a.incentives)
                ? __assign({ enabled: true }, (_b = this.preference) === null || _b === void 0 ? void 0 : _b.incentives) : {
                enabled: false,
                all: {
                    service: {
                        customerPoint: 0,
                        prepaidPoint: 0,
                        deductionPriceByTicket: 0,
                        transfer: 0,
                        card: 0,
                        cash: 0,
                        unpaid: 0,
                        naverPay: 0,
                        etc1: 0,
                        etc2: 0,
                        etc3: 0,
                        etc4: 0,
                        etc5: 0,
                        discount: 0
                    },
                    product: {
                        customerPoint: 0,
                        prepaidPoint: 0,
                        deductionPriceByTicket: 0,
                        transfer: 0,
                        card: 0,
                        cash: 0,
                        unpaid: 0,
                        naverPay: 0,
                        etc1: 0,
                        etc2: 0,
                        etc3: 0,
                        etc4: 0,
                        etc5: 0,
                        discount: 0
                    },
                    ticket: {
                        customerPoint: 0,
                        prepaidPoint: 0,
                        deductionPriceByTicket: 0,
                        transfer: 0,
                        card: 0,
                        cash: 0,
                        unpaid: 0,
                        naverPay: 0,
                        etc1: 0,
                        etc2: 0,
                        etc3: 0,
                        etc4: 0,
                        etc5: 0,
                        discount: 0
                    }
                }
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "subscription", {
        get: function () {
            return this.subscriptions && this.subscriptions.find(function (item) { return item.ourProductId === 2; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "subplanSubscriptions", {
        get: function () {
            return (this.subscriptions && this.subscriptions.filter(function (item) { return item.ourProductId !== 2; })) || [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "hasSubscription", {
        get: function () {
            return (!!this.subscription &&
                (dayjs().isBefore(this.subscription.expiredAt, 'day') || dayjs().isSame(this.subscription.expiredAt, 'day')));
        },
        enumerable: false,
        configurable: true
    });
    StoreModel.prototype.hasSubplan = function (type) {
        if (!this.hasSubscription) {
            return false;
        }
        return !!this.subplanSubscriptions.find(function (item) { return item.ourProductId === (type === 'multilnk' ? 4 : type === 'deposit' ? 5 : 6); });
    };
    StoreModel.prototype.isAutoSMSByKey = function (key) {
        var _a;
        var smsSetting = (_a = this.preference) === null || _a === void 0 ? void 0 : _a.reservationSMS;
        return smsSetting && smsSetting[key] && smsSetting[key].isActive;
    };
    Object.defineProperty(StoreModel.prototype, "messageSendInfo", {
        get: function () {
            var _a, _b, _c;
            var provider = (_b = (_a = this.smsService) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.provider;
            if (!provider) {
                return;
            }
            var info = (_c = this.smsService) === null || _c === void 0 ? void 0 : _c.message[provider];
            var senderNumber = info === null || info === void 0 ? void 0 : info.senderNumber;
            if (!senderNumber) {
                return;
            }
            return {
                senderNumber: senderNumber,
                provider: provider
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "kakaoSendInfo", {
        get: function () {
            var _a, _b, _c;
            var provider = (_b = (_a = this.smsService) === null || _a === void 0 ? void 0 : _a.kakao) === null || _b === void 0 ? void 0 : _b.provider;
            if (!provider) {
                return;
            }
            var info = (_c = this.smsService) === null || _c === void 0 ? void 0 : _c.kakao[provider];
            return {
                plusFriendID: info.plusFriendID,
                searchName: info.searchName,
                profileKey: info.profileKey,
                provider: provider
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "paymentMethods", {
        get: function () {
            var defaultObject = {};
            SALES_METHODS_KEYS.map(function (key) {
                defaultObject[key] = {
                    name: EXTENDED_SALES_MEHOD_KOREAN[key],
                    active: true
                };
            });
            return __assign(__assign({}, defaultObject), this.paymentMethodsSetting);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "paymentMethodsOnlyETC", {
        get: function () {
            var methods = this.paymentMethods;
            return Object.keys(methods)
                .map(function (key) {
                var _a;
                if (key.includes('etc') && ((_a = methods[key]) === null || _a === void 0 ? void 0 : _a.active)) {
                    return { key: key, name: methods[key].name };
                }
                return;
            })
                .filter(Boolean);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "isOfficialNaver", {
        get: function () {
            var _a;
            return !!((_a = this.preference) === null || _a === void 0 ? void 0 : _a.smartplace);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "isAvailableNaverSmartplace", {
        get: function () {
            var _a, _b, _c, _d, _e, _f, _g;
            if (this.isOfficialNaver) {
                return true;
            }
            var smartPlaceData = (_a = this.preference) === null || _a === void 0 ? void 0 : _a.naverSmartPlace;
            var placeId = (_c = (_b = smartPlaceData === null || smartPlaceData === void 0 ? void 0 : smartPlaceData.storeInfo) === null || _b === void 0 ? void 0 : _b.smartplaceBusiness) === null || _c === void 0 ? void 0 : _c.id;
            var storeName = (_e = (_d = smartPlaceData === null || smartPlaceData === void 0 ? void 0 : smartPlaceData.storeInfo) === null || _d === void 0 ? void 0 : _d.smartplaceBusiness) === null || _e === void 0 ? void 0 : _e.name;
            var bookingBusinessId = (_g = (_f = smartPlaceData === null || smartPlaceData === void 0 ? void 0 : smartPlaceData.storeInfo) === null || _f === void 0 ? void 0 : _f.bookingBusinesses[0]) === null || _g === void 0 ? void 0 : _g.bookingBusinessId;
            return placeId && storeName && bookingBusinessId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "stylistFromNaver", {
        get: function () {
            var _a, _b;
            if (!this.isAvailableNaverSmartplace) {
                return [];
            }
            return ((_b = (_a = this.preference) === null || _a === void 0 ? void 0 : _a.naverSmartPlace) === null || _b === void 0 ? void 0 : _b.bizItems) || [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "optionsFromNaver", {
        get: function () {
            var _a, _b;
            if (!this.isAvailableNaverSmartplace) {
                return [];
            }
            return ((_b = (_a = this.preference) === null || _a === void 0 ? void 0 : _a.naverSmartPlace) === null || _b === void 0 ? void 0 : _b.options) || [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "categoriesFromNaver", {
        get: function () {
            var _a, _b;
            if (!this.isAvailableNaverSmartplace) {
                return [];
            }
            return ((_b = (_a = this.preference) === null || _a === void 0 ? void 0 : _a.naverSmartPlace) === null || _b === void 0 ? void 0 : _b.optionCategories) || [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "managerConnectInfo", {
        get: function () {
            var _a, _b, _c, _d, _e;
            if (this.isOfficialNaver) {
                return (_b = (_a = this.preference) === null || _a === void 0 ? void 0 : _a.smartplace) === null || _b === void 0 ? void 0 : _b.bizItems;
            }
            var stylists = this.stylistFromNaver || [];
            var prevConnectInfo = ((_e = (_d = (_c = this.preference) === null || _c === void 0 ? void 0 : _c.naverSmartPlace) === null || _d === void 0 ? void 0 : _d.connectInfo) === null || _e === void 0 ? void 0 : _e.managers) || {};
            return stylists.map(function (stylist) {
                var managerId = prevConnectInfo[stylist.bizItemId];
                if (managerId) {
                    return {
                        managerId: managerId,
                        bizItemId: stylist.bizItemId,
                        bizItemName: stylist.name
                    };
                }
                else {
                    return {
                        bizItemId: stylist.bizItemId,
                        bizItemName: stylist.name
                    };
                }
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "productConnectInfo", {
        get: function () {
            var _this = this;
            var _a, _b, _c, _d, _e;
            if (this.isOfficialNaver) {
                return (_b = (_a = this.preference) === null || _a === void 0 ? void 0 : _a.smartplace) === null || _b === void 0 ? void 0 : _b.options;
            }
            var optionsFromNaver = this.optionsFromNaver || [];
            var prevConnectInfo = ((_e = (_d = (_c = this.preference) === null || _c === void 0 ? void 0 : _c.naverSmartPlace) === null || _d === void 0 ? void 0 : _d.connectInfo) === null || _e === void 0 ? void 0 : _e.products) || {};
            return optionsFromNaver
                .map(function (option) {
                var storeProductId = prevConnectInfo[option.optionId];
                var category = _this.categoriesFromNaver.find(function (category) { var _a; return (_a = category.options) === null || _a === void 0 ? void 0 : _a.find(function (optionInCategory) { return optionInCategory.optionId === option.optionId; }); });
                if (storeProductId) {
                    return {
                        storeProductId: storeProductId,
                        optionId: option.optionId,
                        optionName: option.name,
                        categoryId: category === null || category === void 0 ? void 0 : category.categoryId,
                        categoryName: (category === null || category === void 0 ? void 0 : category.name) || '카테고리 미지정'
                    };
                }
                else {
                    return {
                        optionId: option.optionId,
                        optionName: option.name,
                        categoryId: category === null || category === void 0 ? void 0 : category.categoryId,
                        categoryName: (category === null || category === void 0 ? void 0 : category.name) || '카테고리 미지정'
                    };
                }
            })
                .filter(Boolean);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "paymentMethodsSetting", {
        get: function () {
            var _a;
            return (((_a = this.preference) === null || _a === void 0 ? void 0 : _a.paymentMethods) || {
                etc1: {
                    name: '기타1',
                    active: true
                },
                etc2: {
                    name: '기타2',
                    active: true
                },
                etc3: {
                    name: '기타3',
                    active: false
                },
                etc4: {
                    name: '기타4',
                    active: false
                },
                etc5: {
                    name: '기타5',
                    active: false
                }
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "customFields", {
        get: function () {
            var _a, _b;
            var defaultField = [
                {
                    key: 'name',
                    label: '이름',
                    required: true,
                    type: 'text',
                    editable: false,
                    deletable: false,
                    isDefault: true,
                    priority: 1
                },
                {
                    key: 'phone',
                    label: '연락처',
                    required: false,
                    type: 'text',
                    editable: false,
                    deletable: false,
                    isDefault: true,
                    priority: 2
                },
                {
                    key: 'gender',
                    label: '성별',
                    required: false,
                    type: 'select',
                    options: [
                        { label: '여', value: 'female' },
                        { label: '남', value: 'male' }
                    ],
                    editable: false,
                    deletable: false,
                    isDefault: true,
                    priority: 3
                },
                {
                    key: 'birth',
                    label: '생일',
                    required: false,
                    type: 'date',
                    editable: false,
                    deletable: false,
                    isDefault: true,
                    priority: 4
                },
                {
                    key: 'address',
                    label: '주소',
                    required: false,
                    type: 'address',
                    editable: false,
                    deletable: false,
                    isDefault: true,
                    priority: 5
                },
                {
                    key: 'data.email',
                    label: '이메일',
                    required: false,
                    type: 'text',
                    editable: false,
                    deletable: false,
                    isDefault: true,
                    priority: 6
                },
                {
                    key: 'managerId',
                    label: '담당자',
                    required: true,
                    type: 'select',
                    options: 'manager',
                    editable: false,
                    deletable: false,
                    isDefault: true,
                    priority: 7
                },
                {
                    key: 'customerGroupId',
                    label: '등급',
                    required: false,
                    type: 'select',
                    options: 'customerGroup',
                    editable: false,
                    deletable: false,
                    isDefault: true,
                    priority: 8
                },
                {
                    key: 'customerTags',
                    label: '태그',
                    required: true,
                    type: 'selectMultiple',
                    options: 'customerTags',
                    editable: false,
                    deletable: false,
                    isDefault: true,
                    priority: 9
                },
                {
                    key: 'memo',
                    label: '메모',
                    required: true,
                    type: 'textarea',
                    editable: false,
                    deletable: false,
                    isDefault: true,
                    priority: 10
                },
                {
                    key: 'customerRelations',
                    label: '고객 연결',
                    required: false,
                    type: 'customerRelations',
                    editable: false,
                    deletable: false,
                    isDefault: true,
                    priority: 11
                },
                {
                    key: 'customerNumber',
                    label: '고객 연결',
                    required: false,
                    type: 'number',
                    editable: false,
                    deletable: false,
                    isDefault: true,
                    priority: 12
                }
            ];
            if (this.hasSubplan('customField')) {
                var fields_1 = ((_b = (_a = this.preference) === null || _a === void 0 ? void 0 : _a.customFields) === null || _b === void 0 ? void 0 : _b.customers) || defaultField;
                var result_1 = __spreadArray([], fields_1, true);
                defaultField
                    .filter(function (item) { return !item.deletable && !item.editable; })
                    .map(function (item) {
                    var has = fields_1.find(function (field) { return field.key === item.key; });
                    if (!has) {
                        result_1.push(item);
                    }
                });
                return {
                    customers: result_1
                };
            }
            else {
                return {
                    customers: defaultField
                };
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreModel.prototype, "naverIsHour", {
        get: function () {
            var _a, _b;
            return ((_b = (_a = this.preference) === null || _a === void 0 ? void 0 : _a.smartplace) === null || _b === void 0 ? void 0 : _b.bookingTimeUnitCode) === 'RT02';
        },
        enumerable: false,
        configurable: true
    });
    StoreModel.prototype.getDefaultStartTime = function (start) {
        var _a;
        var storeTimeUnit = ((_a = this.operationPreference) === null || _a === void 0 ? void 0 : _a.timeStep) || 10;
        var now = dayjs("".concat(dayjs(start).format('YYYY-MM-DD')))
            .startOf('day')
            .add(dayjs().hour(), 'hour')
            .add(dayjs().minute(), 'minute');
        var startMinute = now.minute();
        var startedAt;
        if (storeTimeUnit === 10) {
            startedAt = now.startOf('hour').add((floor(startMinute / 10) + 1) * 10, 'minute');
        }
        else {
            startedAt = now.startOf('hour').add((floor(startMinute / 30) + 1) * 30, 'minute');
        }
        return startedAt;
    };
    StoreModel.CUSTOMER_PROFILE_POLICY = CUSTOMER_PROFILE_POLICY;
    return StoreModel;
}(BaseModel));
export { StoreModel };
var getStartTimeAndEndTime = function (date, start, end) {
    var _a = start === null || start === void 0 ? void 0 : start.split(':'), startHour = _a[0], startMinutes = _a[1];
    var _b = end === null || end === void 0 ? void 0 : end.split(':'), endHour = _b[0], endMinutes = _b[1];
    var isNextDay = end === '00:00';
    var startTime = dayjs(date)
        .startOf('day')
        .add(Number(startHour), 'hour')
        .add(Number(startMinutes), 'minute')
        .startOf('minute');
    var endTime = dayjs(date)
        .startOf('day')
        .add(isNextDay ? 1 : 0, 'day')
        .add(Number(endHour), 'hour')
        .add(Number(endMinutes), 'minute')
        .startOf('minute');
    return { startTime: startTime, endTime: endTime };
};
