var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from './BaseModel';
var INVOICE_STATUS;
(function (INVOICE_STATUS) {
    /** 미결제, 사용자의 결제 혹은 관리자의 승인을 기다리는 상태 (오픈) */
    INVOICE_STATUS["OPENED"] = "OPENED";
    /** 미결제, 조정 상태 (조정) */
    INVOICE_STATUS["IN_ADJUST"] = "in_adjust";
    /** 미결제, 자동 결제가 예약된 상태 - 매일 결제 진행됨 (확정) */
    INVOICE_STATUS["SCHEDULED"] = "scheduled";
    /** 결제 진행 중 */
    INVOICE_STATUS["IN_PROGRESS"] = "in_progress";
    /** 결제 실패 중 */
    INVOICE_STATUS["IN_HOLD"] = "in_hold";
    /** 결제가 완료된 상태 */
    INVOICE_STATUS["PAID"] = "PAID";
    /** 결제는 안되었지만 완료 완료 */
    INVOICE_STATUS["COMPLETED"] = "completed";
    /** 취소된 상태 */
    INVOICE_STATUS["CANCELLED"] = "cancelled";
    /** in_hold 상태에서 5번 이상 결제 실패 */
    INVOICE_STATUS["FAILED"] = "failed";
})(INVOICE_STATUS || (INVOICE_STATUS = {}));
var FranchiseInvoiceModel = /** @class */ (function (_super) {
    __extends(FranchiseInvoiceModel, _super);
    function FranchiseInvoiceModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(FranchiseInvoiceModel.prototype, "invoiceString", {
        get: function () {
            switch (this.status) {
                case INVOICE_STATUS.OPENED:
                    return '발행';
                case INVOICE_STATUS.PAID:
                    return '완료';
                default:
                    return this.status || '-';
            }
        },
        enumerable: false,
        configurable: true
    });
    FranchiseInvoiceModel.INVOICE_STATUS = INVOICE_STATUS;
    return FranchiseInvoiceModel;
}(BaseModel));
export { FranchiseInvoiceModel };
