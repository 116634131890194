var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createUUID } from './uuid';
var WebviewInstance = window.ReactNativeWebView;
var WebviewInterface = /** @class */ (function () {
    function WebviewInterface() {
        this.waitList = new Map();
    }
    WebviewInterface.getInstance = function () {
        if (!WebviewInterface.instance) {
            WebviewInterface.instance = new WebviewInterface();
            var listener = function (ev) {
                if (ev.data) {
                    var data = ev.data;
                    try {
                        data = JSON.parse(ev.data);
                    }
                    catch (error) { }
                    if (data.type === 'callback' && data.functionId) {
                        WebviewInterface.getInstance().processCallback(data);
                    }
                }
            };
            // FIXME: 이거 이렇게 두번걸어도 문제 없나요?? https://stackoverflow.com/questions/41160221/react-native-webview-postmessage-does-not-work
            document.addEventListener('message', listener);
            window.addEventListener('message', listener);
        }
        return WebviewInterface.instance;
    };
    WebviewInterface.prototype.notifyCompleteLoad = function () {
        return this.callFunction({ type: 'COMPLETE_LOAD' }, false);
    };
    WebviewInterface.prototype.requestPushPermission = function (payload) {
        return this.callFunction({ type: 'REQ_NOTIFICATION_PERMISSION', payload: payload }, false);
    };
    WebviewInterface.prototype.requestPopup = function (payload) {
        return this.callFunction({ type: 'SHOW_POPUP', payload: payload }, false);
    };
    WebviewInterface.prototype.requestChangeStage = function (payload) {
        return this.callFunction({ type: 'CHANGE_STAGE', payload: payload }, false);
    };
    WebviewInterface.prototype.requestSyncContracts = function (payload) {
        return this.callFunction({ type: 'SYNC_CONTRACTS', payload: payload }, false);
    };
    WebviewInterface.prototype.requestSignout = function (payload) {
        return this.callFunction({ type: 'SIGN_OUT', payload: payload }, false);
    };
    WebviewInterface.prototype.requestTest = function (payload) {
        return this.callFunction({ type: 'TEST', payload: payload }, true);
    };
    WebviewInterface.prototype.requestPlatform = function () {
        return this.callFunction({ type: 'GET_PLATFORM' }, true);
    };
    WebviewInterface.prototype.requestIAP = function () {
        return this.callFunction({ type: 'REQUEST_IAP' }, true);
    };
    WebviewInterface.prototype.requestCompleteIAP = function (payload) {
        return this.callFunction({ type: 'COMPLETE_IAP', payload: payload }, false);
    };
    WebviewInterface.prototype.requestRegisterContracts = function (payload) {
        return this.callFunction({ type: 'REGISTER_CONTRACTS', payload: payload }, true);
    };
    Object.defineProperty(WebviewInterface.prototype, "isRN", {
        get: function () {
            return !!WebviewInstance;
        },
        enumerable: false,
        configurable: true
    });
    WebviewInterface.prototype.processCallback = function (data) {
        var wait = this.getWait(data.functionId);
        if (wait) {
            if (data.result) {
                wait.resolve(data.result);
            }
            else if (data.error) {
                wait.reject(data.error);
            }
            this.deleteWait(data.functionId);
        }
    };
    WebviewInterface.prototype.getWait = function (uuid) {
        return this.waitList.get(uuid);
    };
    WebviewInterface.prototype.deleteWait = function (uuid) {
        this.waitList.delete(uuid);
    };
    WebviewInterface.prototype.callFunction = function (functionData, needCallback) {
        var _this = this;
        if (!WebviewInterface.getInstance().isRN) {
            return;
        }
        var functionId = needCallback && createUUID();
        WebviewInstance.postMessage(JSON.stringify(__assign(__assign({}, functionData), { functionId: functionId })));
        return needCallback
            ? new Promise(function (resolve, reject) {
                _this.waitList.set(functionId, { resolve: resolve, reject: reject });
            })
            : undefined;
    };
    return WebviewInterface;
}());
export { WebviewInterface };
