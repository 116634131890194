var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from './BaseModel';
var CustomerGroupModel = /** @class */ (function (_super) {
    __extends(CustomerGroupModel, _super);
    function CustomerGroupModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomerGroupModel.prototype.getPointRateString = function (store) {
        var pointRate = this.pointRate;
        if (!store || !store.paymentMethods) {
            return "\uD604\uAE08 ".concat(pointRate.cash || 0, "% / \uACC4\uC88C\uC774\uCCB4 ").concat(pointRate.transfer || 0, "% / \uCE74\uB4DC ").concat(pointRate.card || 0, "% / \uBBF8\uC218\uAE08 ").concat(pointRate.unpaid || 0, "%");
        }
        return Object.keys(store.paymentMethods)
            .map(function (key) {
            var data = store.paymentMethods[key];
            if (data && data.name && data.active) {
                return "".concat(data.name, " ").concat(pointRate[key] || 0, "%");
            }
            return;
        })
            .filter(Boolean)
            .join(' / ');
    };
    return CustomerGroupModel;
}(BaseModel));
export { CustomerGroupModel };
