import memoize from 'lodash-es/memoize';
import * as Instance from 'react-gtm-module';
import { NODE_ENV } from 'renderer/constants';
export var initTagManager = memoize(function () {
    if (NODE_ENV !== 'production') {
        return;
    }
    Instance.initialize({
        gtmId: 'GTM-T5PR2PX'
    });
});
export { Instance };
