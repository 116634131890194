var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMutation } from 'react-query';
import { RestClient, usePager } from 'renderer/utils';
import { StoreStockHistoryModel, StoreStockPurchaseModel } from 'renderer/models';
import { QUERY_CACHE } from 'renderer/queries';
import notification from 'antd/lib/notification';
var mutationConfig = {
    onSuccess: function () {
        QUERY_CACHE.invalidateQueries('storeStockHistory');
        QUERY_CACHE.invalidateQueries(['productGroups']);
        QUERY_CACHE.invalidateQueries(['products']);
        QUERY_CACHE.invalidateQueries(['ticketTemplates']);
    },
    onError: function (error) {
        notification.error({ message: error.message });
    }
};
export var useGetStoreStockPurchases = function (query, config) {
    return usePager('storeStockHistory', query, function (item) {
        var _a = item.queryKey[1], storeId = _a.storeId, params = __rest(_a, ["storeId"]);
        return RestClient.getInstance()
            .get("/stores/".concat(query.storeId, "/stocks/history"), { params: params })
            .then(function (result) { return result.data; });
    }, StoreStockHistoryModel, __assign({}, config));
};
export var useCreateStoreHistoryMutation = function (config) {
    return useMutation(function (payload) {
        return RestClient.getInstance()
            .post("/stores/".concat(payload.storeId, "/stocks/history"), payload)
            .then(function (result) { return new StoreStockPurchaseModel(result.data); });
    }, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateStoreHistoryMutation = function (config) {
    return useMutation(function (payload) {
        console.log('payload', payload);
        return RestClient.getInstance()
            .put("/stores/".concat(payload.storeId, "/stocks/history/").concat(payload.id), payload)
            .then(function (result) { return new StoreStockHistoryModel(result.data); });
    }, __assign(__assign({}, mutationConfig), config));
};
export var useDeleteStoreHistoryMutation = function (config) {
    return useMutation(function (payload) {
        console.log('payload', payload);
        return RestClient.getInstance()
            .remove("/stores/".concat(payload.storeId, "/stocks/history/").concat(payload.id))
            .then(function (result) { return new StoreStockHistoryModel(result.data); });
    }, __assign(__assign({}, mutationConfig), config));
};
