var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable no-unused-vars */
import { BaseModel, TicketModel, TICKET_TYPE } from 'renderer/models';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_FORMAT_INCLUDE_TIME } from 'renderer/constants';
import { FormattedNumber } from 'renderer/components/Intl';
import { KRWFomatToString } from 'renderer/components';
export var TICKET_USED_TYPE;
(function (TICKET_USED_TYPE) {
    TICKET_USED_TYPE["USED"] = "used";
    TICKET_USED_TYPE["ASSIGNMENT"] = "assignment";
    TICKET_USED_TYPE["MODIFIED"] = "modified";
    TICKET_USED_TYPE["DELETED"] = "deleted";
    TICKET_USED_TYPE["CREATED"] = "created";
    TICKET_USED_TYPE["ETC"] = "etc";
    // 선물에 의해 생성됨
    TICKET_USED_TYPE["CREATED_BY_GIFT"] = "created_by_gift";
    // 시스템에 의해 사용완료됨
    TICKET_USED_TYPE["COMPLETED"] = "completed";
    // 시스템에 의해 사용만료 됨
    TICKET_USED_TYPE["EXPIRED"] = "expired";
})(TICKET_USED_TYPE || (TICKET_USED_TYPE = {}));
var TicketHistoryModel = /** @class */ (function (_super) {
    __extends(TicketHistoryModel, _super);
    function TicketHistoryModel(props) {
        var _this = _super.call(this, props) || this;
        if (props.ticket) {
            _this.ticket = new TicketModel(props.ticket);
        }
        return _this;
    }
    Object.defineProperty(TicketHistoryModel.prototype, "description", {
        get: function () {
            var _a, _b;
            return ((_a = this.data) === null || _a === void 0 ? void 0 : _a.shareDescription) || ((_b = this.data) === null || _b === void 0 ? void 0 : _b.description) || '-';
        },
        enumerable: false,
        configurable: true
    });
    TicketHistoryModel.prototype.getTypeToString = function () {
        switch (this.type) {
            case TICKET_USED_TYPE.USED:
                return '사용';
            case TICKET_USED_TYPE.CREATED:
                return '생성';
            case TICKET_USED_TYPE.ASSIGNMENT:
                return '양도';
            case TICKET_USED_TYPE.DELETED:
                return '삭제';
            case TICKET_USED_TYPE.MODIFIED:
                return '수정';
            case TICKET_USED_TYPE.CREATED_BY_GIFT:
                return '선물받음';
            case TICKET_USED_TYPE.COMPLETED:
                return '사용완료';
            case TICKET_USED_TYPE.EXPIRED:
                return '기간만료';
            default:
                return '기타';
        }
    };
    Object.defineProperty(TicketHistoryModel.prototype, "typeToString", {
        get: function () {
            switch (this.type) {
                case TICKET_USED_TYPE.USED:
                    // case TICKET_USED_TYPE.DELETED:
                    // case TICKET_USED_TYPE.CREATED_BY_GIFT:
                    return '사용';
                case TICKET_USED_TYPE.CREATED:
                    // case TICKET_USED_TYPE.ASSIGNMENT:
                    return '적립';
                case TICKET_USED_TYPE.MODIFIED:
                    return '수정';
                // case TICKET_USED_TYPE.COMPLETED:
                //   return '사용완료';
                // case TICKET_USED_TYPE.EXPIRED:
                //   return '기간만료';
                default:
                    return '기타';
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TicketHistoryModel.prototype, "isPlus", {
        get: function () {
            var _a;
            return ([TICKET_USED_TYPE.CREATED].includes(this.type) ||
                (this.type === TICKET_USED_TYPE.MODIFIED && ((_a = this.data) === null || _a === void 0 ? void 0 : _a.change) > 0));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TicketHistoryModel.prototype, "changeString", {
        get: function () {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            switch (this.type) {
                case TICKET_USED_TYPE.USED:
                    return this.data && ((_a = this.data) === null || _a === void 0 ? void 0 : _a.deduction) && FormattedNumber({ value: -((_b = this.data) === null || _b === void 0 ? void 0 : _b.deduction) });
                case TICKET_USED_TYPE.CREATED:
                    return (this.data && ((_c = this.data) === null || _c === void 0 ? void 0 : _c.remain) && "+".concat(FormattedNumber({ value: (_d = this.data) === null || _d === void 0 ? void 0 : _d.remain }))) || '-';
                case TICKET_USED_TYPE.MODIFIED:
                    return this.data && ((_e = this.data) === null || _e === void 0 ? void 0 : _e.change)
                        ? ((_f = this.data) === null || _f === void 0 ? void 0 : _f.change) >= 0
                            ? "+".concat(FormattedNumber({ value: (_g = this.data) === null || _g === void 0 ? void 0 : _g.change }))
                            : FormattedNumber({ value: (_h = this.data) === null || _h === void 0 ? void 0 : _h.change })
                        : '0회';
                default:
                    return '기타';
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TicketHistoryModel.prototype, "isShared", {
        get: function () {
            var _a;
            return (_a = this.data) === null || _a === void 0 ? void 0 : _a.isShared;
        },
        enumerable: false,
        configurable: true
    });
    TicketHistoryModel.prototype.getTicketHistoryDataToString = function (ticket, includeLabel) {
        var data = this.data;
        var ticketData = ticket === null || ticket === void 0 ? void 0 : ticket.data;
        if (!data || this.type !== TICKET_USED_TYPE.USED || !ticket || !ticketData) {
            return '-';
        }
        switch (ticket.type) {
            case TICKET_TYPE.TERM:
                return "".concat(dayjs(ticketData.startedAt).format(DATE_FORMAT), "~").concat(dayjs(ticketData.expiredAt).format(DATE_FORMAT));
            case TICKET_TYPE.COUNT:
                return includeLabel
                    ? "\uC794\uC5EC \uD69F\uC218 : ".concat(FormattedNumber({ value: data.remain }), "\uD68C / \uCD1D \uD69F\uC218 : ").concat(FormattedNumber({
                        value: ticketData.total
                    }), "\uD68C")
                    : "".concat(FormattedNumber({ value: data.remain }), "\uD68C / ").concat(FormattedNumber({ value: ticketData.total }), "\uD68C");
            case TICKET_TYPE.CHARGING_MONEY:
                return includeLabel
                    ? "\uC794\uC5EC \uAE08\uC561 : ".concat(KRWFomatToString({ value: data.remain }), " / \uCD1D \uAE08\uC561 : ").concat(KRWFomatToString({
                        value: ticketData.total
                    }))
                    : "".concat(KRWFomatToString({ value: data.remain }), " / ").concat(KRWFomatToString({ value: ticketData.total }));
        }
    };
    Object.defineProperty(TicketHistoryModel.prototype, "deductionWithUnit", {
        get: function () {
            var _a;
            var ticket = this.ticket;
            var deduction = (_a = this.data) === null || _a === void 0 ? void 0 : _a.deduction;
            if (!deduction) {
                return '-';
            }
            switch (ticket.type) {
                case TICKET_TYPE.CHARGING_MONEY:
                    return "".concat(KRWFomatToString({ value: deduction }));
                case TICKET_TYPE.COUNT:
                    return "".concat(deduction, "\uD68C");
                case TICKET_TYPE.TERM:
                    return "-";
                case TICKET_TYPE.TIME:
                    return "".concat(deduction, "\uBD84");
            }
        },
        enumerable: false,
        configurable: true
    });
    TicketHistoryModel.prototype.convertTicketTypeAndTimeToString = function () {
        return "[".concat(this.getTypeToString(), "-").concat(dayjs(this.createdAt).format(DATE_FORMAT_INCLUDE_TIME), "]");
    };
    return TicketHistoryModel;
}(BaseModel));
export { TicketHistoryModel };
